import React from 'react'
import moment, { Moment } from 'moment'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import {
	FastFilterMonthEum,
	FastFilterMonthLabelEum,
} from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/fast-filter-month/inner/FastFilterMonthEum'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'

interface IProps {
	onFilterSelection: (dates: IDateRangeFilter) => void
}

/**
 */
export const FastFilterMonthICP = (props: IProps): JSX.Element => {
	function onFilterClick(filterType: FastFilterMonthEum): void {
		const today = moment()

		let startDate: Moment
		let endDate: Moment

		switch (filterType) {
			case FastFilterMonthEum.LAST_3_MONTHS:
				startDate = today.clone().subtract(2, 'month')
				endDate = today.clone()
				break

			case FastFilterMonthEum.LAST_6_MONTHS:
				startDate = today.clone().subtract(5, 'month')
				endDate = today.clone()
				break

			case FastFilterMonthEum.LAST_12_MONTHS:
				startDate = today.clone().subtract(11, 'month')
				endDate = today.clone()
				break

			case FastFilterMonthEum.THIS_YEAR:
				startDate = today.clone().set({ month: 0 })
				endDate = today.clone().set({ month: 12 })
				break
		}

		const dateFilter: IDateRangeFilter = {
			beginDate: startDate.startOf('month').toDate(),
			endDate: endDate.endOf('month').toDate(),
		}
		props.onFilterSelection(dateFilter)
	}

	return (
		<div style={{ paddingBottom: 5, paddingTop: 10 }}>
			<ButtonGroupCP mode={'separeted'}>
				{Object.values(FastFilterMonthEum).map((fastFilter) => (
					<ButtonCP onClick={() => onFilterClick(fastFilter)} size={'small'}>
						{FastFilterMonthLabelEum[fastFilter]}
					</ButtonCP>
				))}
			</ButtonGroupCP>
		</div>
	)
}
