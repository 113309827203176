import * as _ from 'lodash'
import { TransientValueTrimmerConfigTP } from 'submodules/nerit-framework-ui/common/transient-value-trimmer/types/TransientValueTrimmerConfigTP'
import { TransientValueTrimmerArrayInputConfigTP } from 'submodules/nerit-framework-ui/common/transient-value-trimmer/types/TransientValueTrimmerArrayInputConfigTP'
import { TransientValueTrimmerActiveDebugTP } from 'submodules/nerit-framework-ui/common/transient-value-trimmer/types/TransientValueTrimmerActiveDebugTP'

/**
 * UTILITARIO
 * Eliminador de valor transitorio:
 * Permite a captura do valor 'permanente' obtido apos 01 intervalo determinado evitando alteracoes transitorias indesejadas;
 *
 * - Modo 'normal': Captura-se o ultimo valor detectado que permanecer estavel pelo intervalo configurado;
 * - Modo 'invertido': captura-se 1o valor detectado que sera retornado apos intervalo configurado;
 *
 * TODO: Criar opcao de construtor com assnatura simplificada
 */
export class TransientValueTrimmer<InputTP, OutputTP = InputTP> {
	// eslint-disable-line @typescript-eslint/naming-convention

	private _value: InputTP
	private _timeoutID?: number

	private readonly _onFinish: (definitiveValue: OutputTP) => void
	private readonly _delay: number
	private readonly _isReversed: boolean

	private readonly _shouldDebugTransient: boolean
	private readonly _shouldDebugPermanent: boolean
	private readonly _debugNameTxt: string
	private readonly _arrayInputConfig?: TransientValueTrimmerArrayInputConfigTP

	constructor(config: TransientValueTrimmerConfigTP<OutputTP>) {
		this._delay = config.delay
		this._onFinish = config.onFinish
		this._isReversed = !!config.isReversed
		this._arrayInputConfig = config.arrayInputConfig

		const debugType = config.debugType ?? 'disabled'

		this._debugNameTxt = !!config.debugName ? `::${config.debugName}` : ''
		this._shouldDebugTransient = ['transient', 'full'].includes(debugType)
		this._shouldDebugPermanent = ['permanent', 'full'].includes(debugType)
	}

	onChange(transientValue: InputTP): void {
		if (this._isReversed) this._onChangeReversed(transientValue)
		else this._onChangeNotReversed(transientValue)
	}

	private _onChangeReversed(transientValue: InputTP): void {
		if (this._shouldDebugTransient && this._valueHasChanged(transientValue)) this._debugTransient(transientValue)

		if (!!this._timeoutID) return

		this._value = transientValue
		this._setTrimmerTimeout()
	}

	private _onChangeNotReversed(transientValue: InputTP): void {
		if (!this._valueHasChanged(transientValue)) return

		if (this._timeoutID) clearTimeout(this._timeoutID)

		this._value = transientValue

		if (this._shouldDebugTransient) this._debugTransient(transientValue)

		this._setTrimmerTimeout()
	}

	private _valueHasChanged(newTempValue: InputTP): boolean {
		// Avalia entrada de valor simples
		if (!this._value) return true

		if (!this._arrayInputConfig) return !_.isEqual(newTempValue, this._value)

		// Avalia entrada de valores multiplos (array)
		if (!(newTempValue instanceof Array)) {
			throw {
				message: 'FALHA - TransientValueTrimmer._valueHasChanged: Valor de entrada invalido!',
				value: newTempValue,
			}
		}

		if (!this._arrayInputConfig.valuesToListen) return !_.isEqual(this._value, newTempValue)

		for (const indexToEvaluate of this._arrayInputConfig.valuesToListen) {
			if (!_.isEqual(this._value[indexToEvaluate], newTempValue[indexToEvaluate])) return true
		}

		return false
	}

	private _setTrimmerTimeout(): void {
		this._timeoutID = window.setTimeout(() => {
			if (this._shouldDebugPermanent) this._debugPermanent()

			this._onFinish(this._getPermanentValue())
			this._timeoutID = undefined
		}, this._delay)
	}

	private _getPermanentValue(): OutputTP {
		if (!this._arrayInputConfig || !this._arrayInputConfig.valuesToUse) return this._value as unknown as OutputTP

		if (this._arrayInputConfig.valuesToUse.length === 1) return this._value[this._arrayInputConfig.valuesToUse[0]]

		return _.get(this, '_value', []).filter((value: any, index: number) => this._arrayInputConfig!.valuesToUse!.includes(index)) as OutputTP
	}

	private _debugTransient(transientValue: InputTP): void {
		const baseLogMsg = this._getDebugChangeMsg('transient')
		console.log(`${baseLogMsg} = `, transientValue, `timeoutId: ${this._timeoutID ?? ''}`) // eslint-disable-line no-console
	}

	private _debugPermanent(): void {
		const baseLogMsg = this._getDebugChangeMsg('permanent')
		console.log(`${baseLogMsg} | stored value: `, this._value, ' | output: ', this._getPermanentValue()) // eslint-disable-line no-console
	}

	private _getDebugChangeMsg(type: TransientValueTrimmerActiveDebugTP): string {
		const reversedSign = this._isReversed ? '(reversed)' : ''
		return `DEBUG | (${type}) ${reversedSign} TransientValueTrimmer${this._debugNameTxt}`
	}
}
