import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { FiltersDataAnalisysCP } from 'modules/locations/components/filters-data-analisys/FiltersDataAnalisysCP'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { TableDataAnalisysCP } from 'modules/locations/components/table-data-analysis/TableDataAnalisysCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { DataAnalysisFiltersFormModel } from 'modules/locations/components/filters-data-analisys/inner/DataAnalysisFiltersFormModel'
import { SelectSamplingsCP } from 'modules/samplings/components/select-samplings/SelectSamplingsCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useLocation } from 'react-router'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { DataAnalysisSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/request/DataAnalysisSearchRequestDTO'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

/**
 */
export function ScreenDataAnalisys(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<DataAnalysisFiltersFormModel>(new DataAnalysisFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reloadData, setReloadData] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>()

    useEffect(init, [])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: DataAnalysisSearchRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new DataAnalysisFiltersFormModel({
                locationCodes: queryFilters?.locationCodes,
                fieldRegulationCode: queryFilters?.regulationCode,
                fieldCodes: queryFilters?.fieldCodes,
                dateRange: queryFilters?.dateRange,
                samplingCode: queryFilters?.samplingCode,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReloadData(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function doSearch(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        setReloadData(TableUtils.getReloadNumber())
    }

    return (
        <LayoutCP
            header={
                <HeaderCP
                    title={'Análise de Dados'}
                >
                    <SelectSamplingsCP
                        formStateManager={formStateManager}
                        fieldName={'samplingCode'}
                        width={300}
                    />
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <FiltersDataAnalisysCP
                        formStateManager={formStateManager}
                        onFilter={doSearch}
                        isLoading={isLoading}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!reloadData}>
                        <TableDataAnalisysCP
                            formStateManager={formStateManager}
                            reloadData={reloadData}
                            onLoading={setIsLoading}
                        />
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
