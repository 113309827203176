import { Table } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TablePaginationTP } from 'submodules/nerit-framework-ui/common/components/table/types/TablePaginationTP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { TableSortTP } from 'submodules/nerit-framework-ui/common/components/table/types/TableSortTP'
import { OrderingEnum } from 'submodules/nerit-framework-utils/utils/enums/OrderingEnum'
import { ISearchOrderingRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchOrderingRequestDTO'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps<RowTP> {
	loading?: boolean
	data?: RowTP[]
	totalRecords?: number
	onChangePaginationOrSorter?: (currentPage: number, sorter?: TableSortTP) => void
	appearance?: {
		bordered?: boolean
		showHeader?: boolean
		emptyText?: string
		hideEmptyLogo?: boolean
		title?: string
	}
	pagination: {
		hasPagination: boolean
		pageSize?: number
		current?: number
	}
	columns?: Array<ITableColumn<RowTP>>
	footer?: (rows: RowTP[]) => JSX.Element
	row?: {
		onClick?: (row: RowTP) => void
		onSelect?: {
			rowKey: keyof RowTP
			selectedCodes: number[]
			onSelectRow: (codes: number[]) => void
		}
		setClass?: (record: RowTP) => string
	}
	expand?: {
		expandedRowRender?: (record: RowTP, index: number, indent: number, expanded: boolean) => React.ReactNode
		onExpand?: (expanded: boolean, record: RowTP) => void
	}
	sorter?: {
		initialSorter?: ISearchOrderingRequestDTO
		onChangeSorter?: (sorter?: ISearchOrderingRequestDTO) => void
	}
}

/**
 * Tabela padrao buscada na api.
 */
export function TableInnerICP<RowTP = any>(props: ICPProps<RowTP>): JSX.Element {
	const emptyText = props.appearance?.emptyText ?? 'Nenhum dado encontrado'

	const [sorter, setSorter] = useState<TableSortTP>()
	const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination(props.pagination.pageSize))

	useEffect(onChangeTotalRecords, [props.totalRecords])
	useEffect(() => setPagination({ ...pagination, current: props.pagination.current ?? 1 }), [props.pagination.current])

	/**
	 * Atualiza a paginacao quando query eh rodada no pai
	 */
	function onChangeTotalRecords(): void {
		setPagination({ ...pagination, total: props.totalRecords ?? 0 })
	}

	/**
	 * Ao mudar paginacao ou ordenacao.
	 */
	function onChangePaginationOrSorter(paginationConfig?: PaginationConfig, sortConfig?: SorterResult<RowTP>): void {
		if (!props.onChangePaginationOrSorter) return

		// Order By
		let changedOrderBy = false
		let newSorter: TableSortTP | undefined
		if (!!sortConfig?.columnKey && !!sortConfig?.order) {
			newSorter = {
				column: sortConfig?.columnKey,
				order: sortConfig?.order === 'descend' ? OrderingEnum.DESC : OrderingEnum.ASC,
			}

			if (newSorter?.order !== sorter?.order || newSorter.column !== sorter?.column) changedOrderBy = true

			setSorter(newSorter)
		}

		// Paginacao
		let newPagination: TablePaginationTP | undefined
		if (!!paginationConfig) {
			newPagination = {
				total: paginationConfig.total!,
				current: changedOrderBy ? 1 : paginationConfig.current!,
				pageSize: paginationConfig.pageSize!,
				showTotal: paginationConfig.showTotal,
			}
			setPagination(newPagination)
			props.onChangePaginationOrSorter(newPagination.current, newSorter)
		}
	}

	return (
		<Table<RowTP>
			rowSelection={
				props.row?.onSelect && {
					selectedRowKeys: props.row?.onSelect?.selectedCodes,
					onChange: (codes) => props.row?.onSelect?.onSelectRow((codes as any).map((codeStr: any) => +codeStr)),
				}
			}
			rowKey={(row, index) =>
				!!props.row?.onSelect
					? row[props.row?.onSelect.rowKey as string]
					: `table_${DateUtils.getFormatted(new Date(), DateFormatEnum.US_WITHOUT_TIME)}_${index}`
			}
			dataSource={props.data}
			columns={props.columns?.filter((column) => !column.hide)}
			pagination={props.pagination?.hasPagination && { position: 'bottom', size: 'small', ...pagination }}
			onChange={(pagination, filters, sorter) => onChangePaginationOrSorter(pagination, sorter)}
			rowClassName={props.row?.setClass}
			size={'small'}
			locale={{
				emptyText: props.appearance?.hideEmptyLogo ? emptyText : <EmptyCP description={emptyText} />,
			}}
			loading={props.loading}
			title={
				!!props.appearance?.title
					? () => (
							<TitleCP textSize={'normal'} underLine={true}>
								{props.appearance!.title!}
							</TitleCP>
					  )
					: undefined
			}
			showHeader={props.appearance?.showHeader !== false}
			onRow={(row) => ({
				onClick: () => props.row?.onClick?.(row),
			})}
			expandedRowRender={props.expand?.expandedRowRender}
			onExpand={props.expand?.onExpand}
			bordered={props.appearance?.bordered}
			footer={props.footer}
		/>
	)
}
