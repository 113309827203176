import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MapFiltersFormModel } from 'modules/map/components/filters-map/inner/MapFiltersFormModel'
import { SelectSamplingsCP } from 'modules/samplings/components/select-samplings/SelectSamplingsCP'
import { WrapperFiltersCP } from 'submodules/nerit-framework-ui/features/common/components/filters/wrapper-filters/WrapperFiltersCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'

interface ICPProps {
    formStateManager: IFormStateManager<MapFiltersFormModel>
    onFilter: () => void
    isSearching?: boolean
}

export function FilterMapCP(props: ICPProps): JSX.Element {

    return (
        <WrapperFiltersCP
            appearance={{ marginBottom: '0px' }}
            filters={
                <RowCP>
                    <ColumnCP size={5}>
                        <SelectSamplingsCP
                            formStateManager={props.formStateManager}
                            fieldName={'samplingCode'}
                            isRequired={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={5}>
                        <SelectFieldCP
                            formStateManager={props.formStateManager}
                            fieldName={'fieldCode'}
                            onChange={(option: any) => {
                                if (!!option)
                                    props.formStateManager.changeFieldValue('fieldName', option.label)
                            }}
                            isRequired={true}
                            returnFullOption={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={4}>
                        <DateRangePickerCP
                            showBorder={true}
                            marginTop={23}
                            fastFilter={'all'}
                            formStateManager={props.formStateManager}
                            fieldName={'dateRange'}
                        />
                    </ColumnCP>
                    <ColumnCP size={5}>
                        <SelectFieldRegulationCP
                            fieldRegulationCode={props.formStateManager.getFieldValue('fieldRegulationCode')}
                            onChange={(code) => props.formStateManager.changeFieldValue('fieldRegulationCode', code)}
                        />
                    </ColumnCP>
                    <ColumnCP size={5}>
                        <InputCP
                            formStateManager={props.formStateManager}
                            fieldName={'tolerance'}
                            label={'Tolerância'}
                            type={'number'}
                            min={0}
                            max={100}
                        />
                    </ColumnCP>
                </RowCP>
            }
            filterButton={
                <ButtonCP
                    onClick={() => props.onFilter()}
                    loading={props.isSearching}
                    icon={'search'}
                    type={'primary'}
                />
            }
        />
    )
}
