import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { NatureEnum, NatureLabelEnum } from 'submodules/nerit-aquatec-sdk/common/enums/NatureEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
    nature?: NatureEnum
    isRequired?: boolean
    onChange?: (ntr: NatureEnum) => void
    width?: number
    fieldName?: string
    formStateManager?: IFormStateManager<any>
}

export function SelectNatureCP(props: ICPProps): JSX.Element {

    return (
        <SelectCP
            label={'Natureza'}
            required={!!props.isRequired}
            options={Object.values(NatureEnum).map((nature) => ({
                value: nature,
                label: NatureLabelEnum[nature]
            }))}
            onChange={props.onChange}
            width={props.width}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}

