import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class DataAnalysisFiltersFormModel extends FormModel {

    @IsRequired()
    locationCodes?: number[]

    @IsRequired()
    fieldRegulationCode?: number

    @IsOptional()
    samplingCode?: number

    @IsOptional()
    fieldCodes?: number[]

    @IsOptional()
    dateRange?: IDateRangeFilter

    @IsOptional()
    forceOutsideRegulation?: boolean

    constructor(initialData?: Partial<DataAnalysisFiltersFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
