import React, { useEffect } from 'react'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { LoginFormModel } from 'app/components/form-login/form-model/LoginFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { AuthActions } from 'app/utils/AuthActions'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { AuthResponseDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { UserLoginRequestDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/requests/UserLoginRequestDTO'
import { AuthRequests } from 'submodules/nerit-aquatec-sdk/services/auth/AuthRequests'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'

interface ICPProps {
    formStateManager: IFormStateManager<LoginFormModel>
    onResetPassword: () => void
}

/**
 */
export function FormLoginDataICP(props: ICPProps): JSX.Element {

    const loginRequest = useRequest<AuthResponseDTO>()
    useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

    /**
     */
    async function doLogin(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(props.formStateManager))
            return

        const formValues = props.formStateManager.getFormValues()!
        const dto: UserLoginRequestDTO = {
            username: formValues.username,
            password: formValues.password,
        }
        loginRequest.runRequest(AuthRequests.login(dto))
    }

    /**
     */
    function onLoginRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(loginRequest, 'Usuário ou Senha inválidos'))
            return

        const auth = loginRequest.responseData!
        AuthActions.setLoggedUser(auth.user, auth.token)
        NeritFrameworkRoutingHelper.historyPush(PrivateRouter.HOME)
    }

    return (
        <>
            <InputCP
                label={'Usuário'}
                fieldName={'username'}
                formStateManager={props.formStateManager}
                required={true}
                icon={<IconICP iconName={'user'}/>}
            />

            <InputCP
                label={'Senha'}
                fieldName={'password'}
                type={'password'}
                formStateManager={props.formStateManager}
                required={true}
                icon={<IconICP iconName={'lock'}/>}
            />

            <FlexCP
                justifyContent={'flex-end'}
                margin={{ top: 20 }}
            >
                <ButtonCP
                    type={'primary'}
                    onClick={doLogin}
                    loading={loginRequest.isAwaiting}
                >
                    Entrar
                </ButtonCP>
            </FlexCP>
        </>
    )
}
