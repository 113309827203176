import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

export type FastDateRangeFilterTP = 'today' | 'yesterday' | 'last_07_days' | 'last_15_days' | 'current_month' | 'last_month' | 'all'

interface IProps {
	onFilterSelection: (dates: IDateRangeFilter, filterType: FastDateRangeFilterTP) => void
	currentFilter?: FastDateRangeFilterTP
}

/**
 * COMPONENTE Area com botoes de filtros avancados para range de datas no 'date picker'.
 */
export const FastFilterICP = (props: IProps): JSX.Element => {
	const [isSelectedToday, setIsSelectedToday] = useState<boolean>(false)
	const [isSelectedYesterday, setIsSelectedYesterday] = useState<boolean>(false)
	const [isSelectedLast7Days, setIsSelectedLast7Days] = useState<boolean>(false)
	const [isSelectedLast15Days, setIsSelectedLast15Days] = useState<boolean>(false)
	const [isSelectedCurrentMonth, setIsSelectedCurrentMonth] = useState<boolean>(false)
	const [isSelectedLastMonth, setIsSelectedLastMonth] = useState<boolean>(false)

	useEffect(onCurrentFilterChange)
	useEffect(onCurrentFilterChange, [props.currentFilter])

	function onCurrentFilterChange(): void {
		let isToday = false
		let isYesterday = false
		let isLast7Days = false
		let isLast15Days = false
		let isCurrentMonth = false
		let isLastMonth = false

		switch (props.currentFilter) {
			case 'today':
				isToday = true
				break

			case 'yesterday':
				isYesterday = true
				break

			case 'last_07_days':
				isLast7Days = true
				break

			case 'last_15_days':
				isLast15Days = true
				break

			case 'current_month':
				isCurrentMonth = true
				break

			case 'last_month':
				isLastMonth = true
				break

			case 'all':
				isToday = true
				isYesterday = true
				isLast7Days = true
				isLast15Days = true
				isCurrentMonth = true
				isLastMonth = true
				break

			default:
				break
		}

		updateState(isToday, isYesterday, isLast7Days, isLast15Days, isLastMonth, isCurrentMonth)
	}

	function updateState(
		isToday: boolean,
		isYesterday: boolean,
		isLast7Days: boolean,
		isLast15Days: boolean,
		isLastMonth: boolean,
		isCurrentMonth: boolean,
	): void {
		setIsSelectedToday(isToday)
		setIsSelectedYesterday(isYesterday)
		setIsSelectedLast7Days(isLast7Days)
		setIsSelectedLast15Days(isLast15Days)
		setIsSelectedCurrentMonth(isCurrentMonth)
		setIsSelectedLastMonth(isLastMonth)
	}

	function onFilterClick(filterType: FastDateRangeFilterTP): void {
		let isToday = false
		let isYesterday = false
		let isLast7Days = false
		let isLast15Days = false
		let isLastMonth = false
		let isCurrentMonth = false

		const firstDayOfCurrentMonth = moment().date(1)
		const today = moment()

		let startDate: Moment
		let endDate: Moment

		switch (filterType) {
			case 'yesterday':
				startDate = today.clone().subtract(1, 'day')
				endDate = startDate.clone()
				isYesterday = true
				break

			case 'last_07_days':
				startDate = today.clone().subtract(7, 'days')
				endDate = today.clone()
				isLast7Days = true
				break

			case 'last_15_days':
				startDate = today.clone().subtract(15, 'days')
				endDate = today.clone()
				isLast15Days = true
				break

			case 'last_month':
				startDate = firstDayOfCurrentMonth.clone().subtract(1, 'month')
				endDate = firstDayOfCurrentMonth.clone().subtract(1, 'day')
				isLastMonth = true
				break

			case 'current_month':
				startDate = firstDayOfCurrentMonth.clone()
				endDate = today.clone()
				isCurrentMonth = true
				break

			default:
			case 'today':
				startDate = today.clone()
				endDate = startDate.clone()
				isToday = true
				break
		}

		updateState(isToday, isYesterday, isLast7Days, isLast15Days, isLastMonth, isCurrentMonth)

		const dateFilter: IDateRangeFilter = {
			beginDate: startDate.toDate(),
			endDate: endDate.toDate(),
		}
		props.onFilterSelection(dateFilter, filterType)
	}

	return (
		<div style={{ paddingBottom: 5, paddingTop: 10 }}>
			<ButtonsContainerSCP>
				<ButtonCP type={!isSelectedToday ? 'ghost' : 'primary'} onClick={() => onFilterClick('today')}>
					Hoje
				</ButtonCP>

				<ButtonCP type={!isSelectedYesterday ? 'ghost' : 'primary'} onClick={() => onFilterClick('yesterday')}>
					Ontem
				</ButtonCP>

				<ButtonCP type={!isSelectedLast7Days ? 'ghost' : 'primary'} onClick={() => onFilterClick('last_07_days')}>
					Últimos 7 dias
				</ButtonCP>

				<ButtonCP type={!isSelectedLast15Days ? 'ghost' : 'primary'} onClick={() => onFilterClick('last_15_days')}>
					Últimos 15 dias
				</ButtonCP>

				<ButtonCP type={!isSelectedCurrentMonth ? 'ghost' : 'primary'} onClick={() => onFilterClick('current_month')}>
					Este Mês
				</ButtonCP>

				<ButtonCP type={!isSelectedLastMonth ? 'ghost' : 'primary'} onClick={() => onFilterClick('last_month')}>
					Mês Passado
				</ButtonCP>
			</ButtonsContainerSCP>
		</div>
	)
}

const ButtonsContainerSCP = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	min-width: 500px;

	.ant-btn-primary {
		margin: 0 5px 5px 0 !important;
		padding: 0 9px !important;
		font-size: 12px !important;

		&:hover,
		&:active,
		&:focus {
			background-color: rgba(109, 14, 153, 0.8) !important; // Hex: #6D0E99CC
		}
	}
`
