import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida se é uma string
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_STRING })
class IsStringConstraint implements ValidatorConstraintInterface {
	validate(value: string, args: ValidationArguments): boolean {
		const validator = new Validator()
		return validator.isString(value)
	}

	defaultMessage(args: ValidationArguments): string {
		return 'Valor deve ser do tipo texto!'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsString(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsStringConstraint,
		})
	}
}
