import * as React from 'react'
import profileImgDefault from 'submodules/nerit-framework-ui/common/assets/profile.png'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { UpdateAppVersionCP } from 'submodules/nerit-framework-ui/common/components/system/update-app-version/UpdateAppVersionCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IProfileMenuItemICPProps {
	profileImage?: string
	profileImageIcon?: React.ReactNode
	profileOptions: Array<{ text: string; action: () => void; hidden?: boolean }>
	optionTitle: string
	isTopMenu?: boolean
}

/**
 * Foto e menu de profile no final do menu.
 */
export function ProfileMenuItemCP(props: IProfileMenuItemICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<FlexCP>
			{props.isTopMenu && !screenSize.smd && (
				<TextCP
					text={`Bem-vindo(a), ${StringUtils.getFirstName(props.optionTitle)}`}
					wrap={false}
					marginRight={10}
					color={ThemeFrameworkCommon.browserDefaultColorDark}
				/>
			)}
			<PopOverCP
				trigger={'click'}
				placement={'rightTop'}
				title={props.optionTitle}
				content={
					<OptionsWrapperSCP>
						{props.profileOptions
							.filter((opt) => !opt.hidden)
							.map((opt) => (
								<OptionSCP onClick={opt.action} key={opt.text}>
									{opt.text}
								</OptionSCP>
							))}

						<UpdateAppVersionCP />
					</OptionsWrapperSCP>
				}
			>
				<ProfilePictureWrapperSCP isTopMenu={props.isTopMenu}>
					<StyledProfileIconSCP profileImage={props.profileImage!} />

					{props.profileImageIcon}
				</ProfilePictureWrapperSCP>
			</PopOverCP>
		</FlexCP>
	)
}

const OptionSCP = styled.p`
	cursor: pointer;
	font-weight: 700;
`

const OptionsWrapperSCP = styled.div`
	box-sizing: border-box;
`

const ProfilePictureWrapperSCP = styled.div<{ isTopMenu?: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: ${(props) => (props.isTopMenu ? '' : '20px')};
	position: relative;
`

const StyledProfileIconSCP = styled.div<{ profileImage: string }>`
	background-image: url(${(props) => (props.profileImage ? props.profileImage : profileImgDefault)});
	height: 40px;
	background-size: cover;
	width: 40px;
	border-radius: 100%;
	border: 3px solid ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	cursor: pointer;
`
