import React, { useEffect, useState } from 'react'
import {
	ScreenSubMenuItemTP,
	ScreenSubMenuTP,
} from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { CascaderOptionTP } from 'submodules/nerit-framework-ui/common/components/cascader/inner/CascaderOptionTP'
import { CascaderValueTP } from 'submodules/nerit-framework-ui/common/components/cascader/inner/CascaderValueTP'
import { CascaderCP } from 'submodules/nerit-framework-ui/common/components/cascader/CascaderCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IAdminSubmenuMobileICPProps<ViewTP> {
	onSelectMenuOption: (option: ViewTP) => void
	currentMenuOption: ViewTP
	submenuConfigs: Array<ScreenSubMenuTP<ViewTP>>
}

/**
 * COMPONENTE submenu para dispositivos moveis
 */
export function ScreenSubmenuMobileICP<ViewTP>(props: IAdminSubmenuMobileICPProps<ViewTP>): JSX.Element {
	const [cascaderOptions, setCascaderOptions] = useState<CascaderOptionTP[]>([])
	useEffect(transformToCascader, [])

	function transformToCascader(): void {
		const _cascaderOptions: CascaderOptionTP[] = []

		props.submenuConfigs.forEach((value) => {
			_cascaderOptions.push({
				value: value.key,
				label: value.title,
				children: transformToCascaderItem(value.items),
			})
		})

		setCascaderOptions(_cascaderOptions)
	}

	function transformToCascaderItem(items: Array<ScreenSubMenuItemTP<ViewTP>>): CascaderOptionTP[] {
		return items.map(
			(item): CascaderOptionTP => ({
				label: item.name,
				value: item.view as any,
				isLeaf: true,
			}),
		)
	}

	function onChangeMenu(value: CascaderValueTP): void {
		if (value.length === 0) return

		props.onSelectMenuOption(value[value.length - 1] as any)
	}

	function getValue(): CascaderValueTP {
		let rootValue: ScreenSubMenuTP<ViewTP>
		props.submenuConfigs.forEach((value) => {
			const wasFoundItem = value.items.some((item) => item.view === props.currentMenuOption)
			if (wasFoundItem) rootValue = value
		})

		return [rootValue!.key, props.currentMenuOption as any]
	}

	return (
		<WrapperSCP>
			<CascaderCP options={cascaderOptions} value={getValue()} onChange={onChangeMenu} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	width: 100%;

	.ant-cascader-picker {
		border-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		width: 100%;
	}
`
