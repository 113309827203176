export enum MimeTypeEnum {
	PDF = 'application/pdf',
	ZIP = 'application/zip',
	JSON = 'application/json',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	XLS = 'application/vnd.ms-excel',
	CSV_ALTERNATIVE_1 = 'application/csv',
	CSV_MICROSOFT = 'application/vnd.ms-excel',

	ICO = 'image/vnd.microsoft.icon',
	PNG = 'image/png',
	JPEG = 'image/jpeg',
	JPEG_2000 = 'image/jp2',
	GIF = 'image/gif',
	BMP = 'image/bmp',
	TIFF = 'image/tiff',
	SVG = 'image/svg+xml',

	TEXT = 'text/plain',
	CSV_STANDARD = 'text/csv',
	CSV_ALTERNATIVE_2 = 'text/x-csv',
	CSV_ALTERNATIVE_3 = 'text/x-comma-separated-values',
	CSV_ALTERNATIVE_4 = 'text/comma-separated-values',
}
