import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { DataAnalysisSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/request/DataAnalysisSearchRequestDTO'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SearchMarkersRequestDTO } from 'submodules/nerit-aquatec-sdk/services/map/dtos/requests/SearchMarkersRequestDTO'
import { HistoricChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/HistoricChartRequestDTO'
import { RoutingHelper } from 'config/RoutingHelper'
import { StatisticChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/StatisticChartRequestDTO'
import { GeographicChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/GeographicChartRequestDTO'
import { FieldCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/FieldCorrelationChartRequestDTO'
import { MatrixCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/MatrixCorrelationChartRequestDTO'
import { LocationCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/LocationCorrelationChartRequestDTO'
import { ToxicityIndexChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/ToxicityIndexChartRequestDTO'
import { IQAChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IQAChartRequestDTO'

/**
 * Relacao de Links publicos
 *
 * Acessar o dev-test passando ?screenLinks = true
 */
export function ScreenContentDevTestScreenLinks(): JSX.Element {

    const mapDto: SearchMarkersRequestDTO = { fieldCode: 8, samplingCode: 100 }
    const dataAnalysisDto: DataAnalysisSearchRequestDTO = {
        "fieldCodes": [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
            11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
            71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
            81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
            91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
            101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
            121, 122, 123, 124, 125, 126, 127, 128, 129, 130,
            131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
            141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
            151, 152, 153, 154, 155, 156, 157, 158, 159, 160,
            161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
            171, 172, 173, 174, 175, 176, 177, 178, 179, 180,
            181, 182, 183, 184, 185, 186, 187, 188, 189, 190,
            191, 192, 193, 194, 195, 196, 197, 198, 199, 200
        ],
        "regulationCode": 110,
        "locationCodes": [100, 101]
    }

    const historicalChartDto: HistoricChartRequestDTO = { fieldCodes: [8], locationCode: 100, regulationCode: 110 }
    const geographicChartDto: GeographicChartRequestDTO = { fieldCode: 8, locationCodes: [100, 101], regulationCode: 110 }
    const statisticChartDto: StatisticChartRequestDTO = { fieldCode: 8, locationCode: 100, regulationCode: 110 }
    const fieldCorrelationChartDto: FieldCorrelationChartRequestDTO = { fieldCodes: [8, 151], locationCodes: [100], }
    const locationCorrelationChartRequestDTO: LocationCorrelationChartRequestDTO = { fieldCode: 8, locationCodes: [100, 101], }
    const matrixCorrelationChartRequestDTO: MatrixCorrelationChartRequestDTO = { fieldCodes: [8, 459, 281], locationCodes: [1107], }
    const toxicityIndexChartRequestDTO: ToxicityIndexChartRequestDTO = { fieldCodes: [142], locationCodes: [100], regulationCode: 108 }
    const iqaChartRequestDTO: IQAChartRequestDTO = { locationCodes: [100] }

	return (
		<ContentCP overflowVertical={true}>
			<WrapperSCP>

				<div>
					<ButtonCP
						onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.DATA_ANALISYS}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(dataAnalysisDto)}`)}
					>
						Analise de Dados
					</ButtonCP>
					<ButtonCP
						onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.MAP}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(mapDto)}`)}
					>
						Mapa
					</ButtonCP>
				</div>

				<div>
                    <ButtonCP
                        onClick={() => RoutingHelper.goToChartHistorical(historicalChartDto)}
                    >
                        Série Histórica
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.CHART_STATISTIC}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(statisticChartDto)}`)}
                    >
                        Estatística
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.GEOGRAPHIC}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(geographicChartDto)}`)}
                    >
                        Geográfico
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.CORRELATION_FIELDS}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(fieldCorrelationChartDto)}`)}
                    >
                        Correlação de Parametros
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.CORRELATION_MATRIX}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(matrixCorrelationChartRequestDTO)}`)}
                    >
                        Matriz de Correlação
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.CORRELATION_LOCATIONS}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(locationCorrelationChartRequestDTO)}`)}
                    >
                        Correlação de Pontos
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.WATER_CONTAMINATION}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(toxicityIndexChartRequestDTO)}`)}
                    >
                        Contaminação da Água
                    </ButtonCP>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(`${PrivateRouter.WATER_QUALITY}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(iqaChartRequestDTO)}`)}
                    >
                        Qualidade da Água
                    </ButtonCP>
				</div>
			</WrapperSCP>
		</ContentCP>
	)
}

const WrapperSCP = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  button {
    width: 400px;
    margin-top: 20px;
    
    span {
      width: 100%;
    }
  }
`
