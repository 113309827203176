import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonModalRegulationCP } from 'modules/fields/components/buttons/button-modal-regulation/ButtonModalRegulationCP'
import { TableRegulationsCP } from 'modules/fields/components/tables/table-regulation/TableRegulationsCP'

export function ScreenRegulation(): JSX.Element {

    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Normas'}>
                    <ButtonGroupCP mode={'separeted'}>
                        <ButtonModalRegulationCP
                            onSave={() => setShouldLoadData(TableUtils.getReloadNumber())}
                        />
                    </ButtonGroupCP>
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <TableRegulationsCP
                        shouldLoadData={shouldLoadData}
                    />
                </>
            }
        />
    )
}
