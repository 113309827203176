import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { IsValidReqReturnConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IsInvalidReqReturnConfigTP'
import { RequestTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestTP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

type _RequestAssistParamsTP = {
	request: RequestTP<any>
	config?: IsValidReqReturnConfigTP
}

/**
 * Reune metodos auxiliares uteis para lidar com requisicoes http realizadas utilizando as funcoes do modulo 'request helpers'.
 */
export class RequestUtils {
	private static _requestIdsCounter = 0

	private constructor() {}

	/** Exibe mensagem de notificacao de falha em requisicao determinada via procedimento generico. */
	static showDefaultErrorNotification(error: unknown, defaultMsg: string, notificationTitle = 'Ops!'): void {
		const apiErrorMsg = (error as IApiReturn)?.data?.message ?? (error as any)?.message
		const errorTitle = typeof apiErrorMsg === 'string' ? apiErrorMsg : notificationTitle

		const apiErrors = (error as IApiReturn)?.data?.errors
		let errorMsg = defaultMsg
		if (typeof apiErrors === 'string') errorMsg = apiErrors
		else if (Array.isArray(apiErrors)) errorMsg = (apiErrors as string[]).join(', ')

		NeritFrameworkProjectConfig.notifyApi('error', errorTitle, errorMsg)
	}

	/** Determina se execucao de 01 requisicao foi concluida. */
	static isRequestConcluded(request: RequestTP<any>): boolean {
		return request.wasTried && !request.isAwaiting
	}

	/** Avalia 01 requisicao & determina se foi bem sucedida. */
	static isRequestSuccess(request: RequestTP<any>, isVoidRequest = false): boolean {
		return this.isRequestConcluded(request) && request.isSuccess && (isVoidRequest || !!request.responseData)
	}

	/** Avalia 01 requisicao & determina se houve erro durante a execucao (cancelamento nao eh considerado erro). */
	static isRequestError(request: RequestTP<any>, isVoidRequest?: boolean): boolean {
		if (!this.isRequestConcluded(request)) return false
		return !this.isRequestSuccess(request, isVoidRequest) && !request.isCancelled
	}

	static handleError(param1: RequestTP<any> | IsValidReqReturnConfigTP | _RequestAssistParamsTP, defaultMsg?: string): void {
		const isAssistParams = !this._isConfigParam(param1) && !!(param1 as _RequestAssistParamsTP)?.request
		const params = isAssistParams ? (param1 as _RequestAssistParamsTP) : this._getRequestAssistParams(param1)

		const _errorMsg = params.config?.errorMsg ?? defaultMsg
		const failureLogMsg = this._getFailureLogMsg(params.config) ?? _errorMsg
		if (!!failureLogMsg) console.error(failureLogMsg, params.request.responseData, params.request.error)

		if (params.request.responseStatus === HttpStatusEnum.UNAUTHORIZED) {
			if (!!params.config?.onUnauthorized) return params.config.onUnauthorized()

			return NeritFrameworkProjectConfig.onUnauthorized()
		}

		if (!!_errorMsg && this._shouldReportFailure(params.config, params.request.responseStatus))
			RequestUtils.showDefaultErrorNotification(params.request.error, _errorMsg)
	}

	static getNewRequestId(): string {
		this._requestIdsCounter++
		const idNumberString = this._requestIdsCounter < 1000 ? `000${this._requestIdsCounter}`.slice(-3) : this._requestIdsCounter
		return `request-${idNumberString}`
	}

	static resetRequestCount(): void {
		this._requestIdsCounter = 0
	}

	/**
	 * Formata dados do DTO enviado como parametro.
	 * Por enquanto trata apenas a DATA, transformando a data com timezone para o formato simples string de dia/hora
	 */
	static paramTransformer(data?: any): any {
		if (data === undefined) return

		// Encontrou um atributo do DTO no formato DATA
		if (data instanceof Date) return DateUtils.formatDate(data, DateFormatEnum.US_WITH_TIME_H_M_S)

		if (typeof data === 'string') return data.trim()

		// Se for ARRAY, para cada item do array chama recursivamente o metodo
		if (Array.isArray(data)) return data.map(RequestUtils.paramTransformer)

		// Se for um objeto, para cada atributo do DTO chama recursivamente para alterar o valor para data, caso encontre
		if (typeof data === 'object' && data !== null)
			return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, RequestUtils.paramTransformer(value)]))

		return data
	}

	/**
	 * Valida o retorno de uma requisicao para os casos mais comuns.
	 *
	 * Falhas do tipo 401 (nao autorizado), 403 (acesso proibido) & 404 (nao encontrada), por padrao,
	 * nao emitem notificacao de falha porque:
	 *
	 * - 401: Preve logout automatico de usuario (que deve ser tratado em outro lugar);
	 * - 403: Preve redirecionamento de tela (que deve ser tratado em outro lugar);
	 * - 404: Nem sempre representa erro de execucao;
	 */
	static isValidRequestReturn(
		param1: RequestTP<any> | IsValidReqReturnConfigTP,
		errorMsg?: string,
		successMessage?: string,
		isVoidRequest = false,
	): boolean {
		const params = this._getRequestAssistParams(param1)

		if (this.isRequestSuccess(params.request, isVoidRequest)) {
			if (!!successMessage) NeritFrameworkProjectConfig.notifyApi('success', 'Pronto!', successMessage)

			return true
		}

		if (this.isRequestError(params.request, isVoidRequest)) this.handleError(params, errorMsg)

		return false
	}

	/**
	 * Determina se o metodo de tratamento generico de retorno de requisicoes deve emitir notificacoes
	 * em caso de falha.
	 */
	private static _shouldReportFailure(config?: IsValidReqReturnConfigTP, status?: HttpStatusEnum): boolean {
		if (config?.shouldReportFailure === false) return false

		switch (status) {
			case HttpStatusEnum.UNAUTHORIZED:
				return !!config?.mustReport401
			case HttpStatusEnum.FORBIDDEN:
				return !!config?.mustReport403
			case HttpStatusEnum.NOT_FOUND:
				return !config || config?.mustReport404 === true
			default:
				return true
		}
	}

	private static _getFailureLogMsg(config?: IsValidReqReturnConfigTP): string | void {
		if (!!config?.failureLogMsg) return config.failureLogMsg

		if (!config?.component && !config?.componentMethod) return

		return `FALHA - ${config.component?.name ?? 'component'}.${config.componentMethod?.name ?? 'component'}`
	}

	private static _getRequestAssistParams(param: RequestTP<any> | IsValidReqReturnConfigTP): _RequestAssistParamsTP {
		const config: OrUndefinedTP<IsValidReqReturnConfigTP> = SystemUtils.nvl(
			!!(param as IsValidReqReturnConfigTP)?.request,
			param as IsValidReqReturnConfigTP,
		)

		const request = (config?.request ?? param) as RequestTP<any>
		return { config, request }
	}

	private static _isConfigParam(param: any): boolean {
		if (typeof param !== 'object' || !(param as IsValidReqReturnConfigTP)?.request) return false

		const configKeys: Array<keyof IsValidReqReturnConfigTP> = [
			'request',
			'errorMsg',
			'isVoidRequest',
			'failureLogMsg',
			'shouldReportFailure',
			'mustReport404',
			'mustReport403',
			'mustReport401',
			'component',
			'componentMethod',
		]

		for (const paramKey of Object.keys(param)) {
			if (!(configKeys as string[]).includes(paramKey)) return false
		}

		return true
	}
}
