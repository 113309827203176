import React, { useEffect, useState } from 'react'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { IGenericChartSeriesAndData } from 'modules/reports/components/tables/table-generic-chart/inner/IGenericChartSeriesAndData'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import * as _ from 'lodash'
import { ApexChartUtils } from 'modules/reports/components/charts/card-chart/inner/ApexChartUtils'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'

interface ICPProps {
    data: GenericChartResponseDTO
    export?: {
        fileName: string
        exportRequestConfig: () => RequestConfigTP<'blob'>
    }
}

/**
 */
export function TableGenericChartCP(props: ICPProps): JSX.Element {

    const [data, setData] = useState<IGenericChartSeriesAndData[]>([])
    useEffect(init, [props.data])

    /**
     */
    function init(): void {

        const formattedData: IGenericChartSeriesAndData[] = []
        props.data.series.forEach((serie) => {
            serie.dataArray.forEach((serieData) => {

                formattedData.push({
                    serieName: serie.xAxisLabel,
                    serieUnit: serie.yAxisLabel,
                    x: serieData.x,
                    y: serieData.y,
                    z: serieData.z
                })

            })
        })
        setData(_.sortBy(formattedData, 'x'))
    }

    return (
        <>
            {
                !!props.export &&
                <WrapperTableBulkActionsCP>
                    <ButtonExportFromApiCP
                        requestConfig={props.export.exportRequestConfig}
                        fileName={props.export.fileName}
                    />
                </WrapperTableBulkActionsCP>
            }

            <TableCP<IGenericChartSeriesAndData>
                wrappedOnCard={true}
                data={data}
                rowClassName={(record) => ApexChartUtils.isAboveOrBelowLimit(props.data, +record.y) ? 'row-nerit-error' : ''}
                columns={[
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.formatDate(record.x, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Parâmetro',
                        render: (text, record) => record.serieName
                    },
                    {
                        title: 'Valor',
                        render: (text, record) => Number(record.y).toFixed(3)
                    },
                ]}
            />
        </>
    )
}
