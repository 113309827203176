import IMask, { MaskedNumber } from 'imask'
import moment from 'moment'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

/**
 * Classe para auxiliar a inserção de máscaras em campos de formulário.
 */
export class MaskUtils {
	static applyMask2(maskType: InputMaskTypeEnum, value?: string | number, min?: Date, max?: Date): string | undefined {
		if (!value) return undefined
		return MaskUtils.applyMask(value, maskType, min, max)
	}

	static applyMask(value: string | number, maskType: InputMaskTypeEnum, min?: Date, max?: Date): string {
		if (!value || value === '') return `${value}`

		const _value = `${value}`.length && `${value}`.replace(/\D/g, '')
		const valueToMask = _value.toString()

		switch (maskType) {
			case InputMaskTypeEnum.MONEY:
				const moneyVal = IMask.pipe(
					value.toString().replace('.', ''),
					new MaskedNumber({
						mask: Number,
						scale: 2, // digits after point, 0 for integers
						signed: false, // disallow negative
						thousandsSeparator: '.', // any single char
						padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
						normalizeZeros: false, // appends or removes zeros at ends
						radix: ',', // fractional delimiter
						mapToRadix: ['.'], // symbols to process as radix
					}),
				)
				return moneyVal

			case InputMaskTypeEnum.CPFCNPJ:
				if (valueToMask.length > 11) return IMask.createMask({ mask: '00.000.000/0000-00' }).resolve(valueToMask)
				return IMask.createMask({ mask: '000.000.000-00' }).resolve(valueToMask)

			case InputMaskTypeEnum.DATE:
				const momentFormat = 'DD/MM/YYYY'
				return IMask.createMask({
					mask: Date,
					min,
					max,
					pattern: momentFormat,
					format(date: Date) {
						return moment(date).format(momentFormat)
					},
					parse(str: string) {
						return moment(str, momentFormat)
					},
					blocks: {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						YYYY: {
							mask: IMask.MaskedRange,
							from: 1901,
							to: 9999,
						},
						// eslint-disable-next-line @typescript-eslint/naming-convention
						MM: {
							mask: IMask.MaskedRange,
							from: 1,
							to: 12,
						},
						// eslint-disable-next-line @typescript-eslint/naming-convention
						DD: {
							mask: IMask.MaskedRange,
							from: 1,
							to: 31,
						},
					},
				}).resolve(valueToMask)

			case InputMaskTypeEnum.DATE_MM_YY:
				const momentFormatMmYy = 'MM/YYY' // Tive que colocar um Y a mais
				return IMask.createMask({
					mask: Date,
					min,
					max,
					pattern: momentFormatMmYy,
					format(date: Date) {
						return moment(date).format(momentFormat)
					},
					parse(str: string) {
						return moment(str, momentFormat)
					},
					blocks: {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						YY: {
							mask: IMask.MaskedRange,
							from: 23,
							to: 50,
						},
						// eslint-disable-next-line @typescript-eslint/naming-convention
						MM: {
							mask: IMask.MaskedRange,
							from: 1,
							to: 12,
						},
					},
				}).resolve(valueToMask)

			case InputMaskTypeEnum.CPF:
				return IMask.createMask({ mask: '000.000.000-00' }).resolve(valueToMask)

			case InputMaskTypeEnum.PHONE:
				if (valueToMask[2] === '9') return IMask.createMask({ mask: '(00) 00000-0000' }).resolve(valueToMask)
				return IMask.createMask({ mask: '(00) 0000-0000' }).resolve(valueToMask)

			case InputMaskTypeEnum.CEP:
				return IMask.createMask({ mask: '00000-000' }).resolve(valueToMask)

			case InputMaskTypeEnum.CNPJ:
				return IMask.createMask({ mask: '00.000.000/0000-00' }).resolve(valueToMask)

			case InputMaskTypeEnum.HOUR:
				return IMask.createMask({ mask: '00:00' }).resolve(valueToMask)

			case InputMaskTypeEnum.NUMERIC:
				return IMask.createMask({
					mask: Number,
					scale: 7, // digits after point, 0 for integers
					signed: false, // disallow negative
					thousandsSeparator: '', // any single char
					padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
					normalizeZeros: false, // appends or removes zeros at ends
					radix: ',', // fractional delimiter
					mapToRadix: ['.'], // symbols to process as radix

					// additional number interval options (e.g.)
					min: -10000,
					max: 10000,
				}).resolve(valueToMask)

			case InputMaskTypeEnum.STATE:
				return IMask.createMask({ mask: 'aa' }).resolve(valueToMask)

			default:
				return `${value}`
		}
	}

	static removeMask(value?: string): string | undefined {
		if (!value) return undefined

		const result = value.replace(/\D/g, '')
		return result === '' ? undefined : result
	}

	static applyNumberMask(value?: number, decimals?: number): string {
		if (!value) return ''

		return IMask.createMask({
			mask: Number,
			scale: decimals,
			signed: true,
			thousandsSeparator: '.',
			radix: ',',
		}).resolve(value.toString())
	}

	// aplicar em valores estático
	static applyMoneyMask(value?: string | number): string {
		const _value = (+(value ?? 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
		return _value
	}

	static applyCpfOrCnpjMask(value?: string): string {
		if (!value) return ''

		const CNPJ_LENGTH = 14

		const valUnmask = MaskUtils.removeMask(value)!
		return MaskUtils.applyMask(value, valUnmask.length === CNPJ_LENGTH ? InputMaskTypeEnum.CNPJ : InputMaskTypeEnum.CPF)
	}
}
