import React from 'react'
import { Redirect } from 'react-router-dom'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
	path: string
}

/**
 */
export function RedirectCP(props: ICPProps): JSX.Element {
	return <Redirect to={NeritFrameworkRoutingHelper.mountPathWithCustomerDomain(props.path)} />
}
