import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { FieldCorrelationChartFiltersFormModel } from 'modules/reports/components/filters/filter-field-correlation-chart/inner/FieldCorrelationChartFiltersFormModel'
import { FieldCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/FieldCorrelationChartRequestDTO'
import { FilterFieldCorrelationChartCP } from 'modules/reports/components/filters/filter-field-correlation-chart/FilterFieldCorrelationChartCP'
import { ChartFieldCorrelationCP } from 'modules/reports/components/charts/chart-field-correlation/ChartFieldCorrelationCP'
import { FieldCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/FieldCorrelationChartResponseDTO'
import { TableFieldCorrelationChartCP } from 'modules/reports/components/tables/table-field-correlation-chart/TableFieldCorrelationChartCP'

/**
 */
export function ScreenContentChartFieldCorrelation(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<FieldCorrelationChartFiltersFormModel>(new FieldCorrelationChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<FieldCorrelationChartResponseDTO>()

    const [fieldCorrelationChartRequestDTO, setFieldCorrelationChartRequestDTO] = useState<FieldCorrelationChartRequestDTO>()
    const getChartRequest = useRequest<FieldCorrelationChartResponseDTO>()
    useEffect(onGetChartRequestChange, [getChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: FieldCorrelationChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new FieldCorrelationChartFiltersFormModel({
                fieldCodes: queryFilters?.fieldCodes,
                locationCodes: queryFilters?.locationCodes,
                dateRange: queryFilters?.dateRange,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        if (formValues.fieldCodes.length < 2) {
            NotificationHelper.error('Ops', 'Selecione ao menos 2 parâmetros')
            return
        }

        if (formValues.fieldCodes.length > 3) {
            NotificationHelper.error('Ops', 'Selecione no máximo 3 parâmetros')
            return
        }

        const filters: FieldCorrelationChartRequestDTO = {
            fieldCodes: formValues.fieldCodes,
            dateRange: formValues.dateRange,
            locationCodes: formValues.locationCodes,
        }
        getChartRequest.runRequest(ChartsRequests.getFieldCorrelationChart(filters))
        setLoadedData(undefined)
        setFieldCorrelationChartRequestDTO(filters)
    }

    /**
     */
    function onGetChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Correlação de Parâmetros'}/>
            }
            content={
                <>
                    <FilterFieldCorrelationChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!fieldCorrelationChartRequestDTO &&
                                <>
                                    <ChartFieldCorrelationCP data={loadedData}/>
                                    <TableFieldCorrelationChartCP
                                        filters={fieldCorrelationChartRequestDTO}
                                        data={loadedData}
                                    />
                                </>
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
