import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'
import { NatureEnum } from 'submodules/nerit-aquatec-sdk/common/enums/NatureEnum'

export class FieldRegulationFormModel extends FormModel {

    @IsRequired()
    fieldCode: number

    @IsRequired()
    regulationCode: number

    @IsRequired()
    nature: NatureEnum

    @IsOptional()
    minPermitted: number

    @IsOptional()
    maxPermitted: number

    constructor(initialData?: Partial<FieldRegulationFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
