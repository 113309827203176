import React, { useEffect, useState } from 'react'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import * as _ from 'lodash'
import { ApexChartUtils } from 'modules/reports/components/charts/card-chart/inner/ApexChartUtils'
import { IGeographicChartData } from 'modules/reports/components/tables/table-geographic-chart/inner/IGeographicChartData'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { GeographicChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/GeographicChartRequestDTO'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'

interface ICPProps {
    data: GenericChartResponseDTO
    filters: GeographicChartRequestDTO
}

/**
 */
export function TableGeographicChartCP(props: ICPProps): JSX.Element {

    const [data, setData] = useState<IGeographicChartData[]>([])
    useEffect(init, [props.data])

    /**
     */
    function init(): void {

        const formattedData: IGeographicChartData[] = []
        props.data.series.forEach((serie) => {
            serie.dataArray.forEach((serieData) => {

                formattedData.push({
                    location: serieData.x,
                    date: serieData.date,
                    parameter: serie.yAxisLabel,
                    value: serieData.y,
                })

            })
        })
        setData(_.sortBy(formattedData, 'date'))
    }

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getGeographicChartExport(props.filters)}
                    fileName={'geografico'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<IGeographicChartData>
                wrappedOnCard={true}
                data={data}
                rowClassName={(record) => ApexChartUtils.isAboveOrBelowLimit(props.data, +record.value) ? 'row-nerit-error' : ''}
                columns={[
                    {
                        title: 'Ponto',
                        render: (text, record) => record.location
                    },
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.transformDateStrFormat(DateFormatEnum.US_WITHOUT_TIME, DateFormatEnum.BR_WITHOUT_TIME, record.date)
                    },
                    {
                        title: 'Parâmetro',
                        render: (text, record) => record.parameter
                    },
                    {
                        title: 'Valor',
                        render: (text, record) => record.value
                    },
                ]}
            />
        </>
    )
}
