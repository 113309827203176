import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'submodules/nerit-aquatec-sdk/services/users/UserRequests'

interface IAddFieldClassificationModalCPProps {
    visible: boolean
    onCancel: () => void
    onSave: () => void
}

/**
 * Adicionar usuario em uma clinica.
 */
export function CreateUserCP(props: IAddFieldClassificationModalCPProps): JSX.Element {

    const doLinkRequest = useRequest<void>('none')

    const [name, setName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [role, setRole] = useState<string>()

    const createUser = useRequest<any>()

    function init(): void {

        if (name && email && role) {

            createUser.runRequest(UserRequests.saveUser({
                name,
                email,
                role,
            }))
        }

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={doLinkRequest.isAwaiting}
            noFooter={true}
            onCancel={props.onCancel}
            title={'Novo usuário'}
            width={600}
        >

            <InputCP
                label={'Nome'}
                value={name}
                onChange={(gp) => setName(gp)}
            />

            <InputCP
                label={'E-mail'}
                value={email}
                onChange={(gp) => setEmail(gp)}
            />

            <SelectCP
                label={'Permissão'}
                options={[
                    'Administrador',
                    'Cliente'
                ]}
                onChange={(ntr) => {
                    setRole(ntr)
                }}
            />

            <ButtonCP
                marginTop={25}
                icon={'save'}
                type={'primary'}
                disabled={false}
                onClick={init}
            >
                Criar Usuário
            </ButtonCP>

        </ModalCP>
    )
}