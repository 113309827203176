import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

export type TooltipPlacementTP =
	| 'top'
	| 'left'
	| 'right'
	| 'bottom'
	| 'topLeft'
	| 'topRight'
	| 'bottomLeft'
	| 'bottomRight'
	| 'leftTop'
	| 'leftBottom'
	| 'rightTop'
	| 'rightBottom'

interface ITooltipCPProps {
	children: React.ReactNode
	text?: React.ReactNode
	placement?: TooltipPlacementTP
	showSpan?: boolean // O SPAN dentro do tooltip é necessário, pois tem um bug no ant que ele nao renderiza em alguns componentes. Quando nao aparecer passar true
}

/**
 * COMPONENTE tooltip
 */
export function TooltipCP(props: ITooltipCPProps): JSX.Element | null {
	const screenSize = useScreenSize()

	// Se for mobile nao renderiza o tooltip, só retorna o filho
	if (screenSize.smd || !props.text) return (props.children as JSX.Element) ?? null

	return (
		<Tooltip title={props.text} placement={props.placement}>
			{!!props.showSpan ? <span>{props.children}</span> : props.children}
		</Tooltip>
	)
}
