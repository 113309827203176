
/**
 */
export class WaterContaminationFilterUtils {

    static DEFAULT_FIELD_CODES: number[] = [
        5, //	Nitrato
        127, //	Nitrito
        142, //	Arsênio total
        143, //	Bário total
        145, //	Cádmio total
        146, //	Cianeto livre
        150, //	Cromo total
        158, //	Mercúrio total
        167, //	Zinco total
        169, //	Chumbo total
        170, //	Cobre dissolvido
        197, //	Fenóis totais (substâncias que reagem com 4-aminoantipirina)
        264, //	Nitrogênio amoniacal
    ]

}
