export enum FastFilterMonthEum {
	'LAST_3_MONTHS' = 'LAST_3_MONTHS',
	'LAST_6_MONTHS' = 'LAST_6_MONTHS',
	'LAST_12_MONTHS' = 'LAST_12_MONTHS',
	'THIS_YEAR' = 'THIS_YEAR',
}

export enum FastFilterMonthLabelEum {
	'LAST_3_MONTHS' = 'Último trimestre',
	'LAST_6_MONTHS' = 'Último semestre',
	'LAST_12_MONTHS' = 'Últimos 12 meses',
	'THIS_YEAR' = 'Este ano',
}
