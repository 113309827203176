import React from 'react'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { Switch } from 'antd'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ISwitchFormCPProps extends IFormItemCommonProps {
	disabled?: boolean
	labelAbove?: boolean
	checkedLabels?: {
		checked: string
		unchecked: string
	}
	loading?: boolean
}

/**
 * Componente de sim ou nao.
 */
export function SwitchCP(props: ISwitchFormCPProps): JSX.Element {
	/**
	 */
	function onChange(checked: boolean): void {
		if (!!props.onChange) {
			props.onChange(checked)
			return
		}

		if (!!props.formStateManager && !!props.fieldName) props.formStateManager.changeFieldValue(props.fieldName, checked)
	}

	return (
		<FormItemICP
			onChange={props.onChange}
			fieldName={props.fieldName as any}
			formStateManager={props.formStateManager}
			required={props.required}
			width={props.width}
			onFormSubmit={props.onFormSubmit}
			value={props.value}
			hint={props.hint}
			marginTop={props.marginTop}
			marginRight={props.marginRight}
			marginLeft={props.marginLeft}
			marginBottom={props.marginBottom}
		>
			<FlexCP alignItems={'center'} flexDirection={props.labelAbove ? 'column' : undefined}>
				<TextCP text={props.label as string} marginRight={10} style={{ color: ThemeFrameworkCommon.browserDefaultColorDark }} />
				<SwitchWrapperSCP>
					<Switch
						checked={props.value ?? (!!props.fieldName ? props.formStateManager?.getFieldValue(props.fieldName) : undefined)}
						onChange={onChange}
						checkedChildren={props.checkedLabels?.checked ?? 'Sim'}
						unCheckedChildren={props.checkedLabels?.unchecked ?? 'Não'}
						disabled={props.disabled}
						loading={props.loading}
					/>
				</SwitchWrapperSCP>
			</FlexCP>
		</FormItemICP>
	)
}

const SwitchWrapperSCP = styled.div`
	.ant-switch {
		background-color: ${ThemeProject.gray500};
	}
	.ant-switch-checked {
		background-color: ${ThemeProject.switchActiveBackground};
	}
`
