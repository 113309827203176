import { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import createEncryptor from 'redux-persist-transform-encrypt'
import { IReduxState } from 'app/redux/interfaces/IReduxState'
import { AppConfig } from 'config/AppConfig'

/**
 * Encapsula configuracoes para persistencia local de valores mantidos no redux com biblioteca 'redux-persist'.
 */
export class ReduxPersistorHelper {

    static readonly ACTION_REHYDRATE = 'persist/REHYDRATE'

    private static readonly PERSIST_WHITELIST: Array<keyof IReduxState> = ['auth', 'domainData']

    private static readonly PERSIST_CONFIG_DEFAULT: PersistConfig = {
        key: 'root',
        storage,
    }

    private constructor() {}

    /** Gera & retorna parametros de configuracao do 'persistor'. */
    static getPersistorConfig(): PersistConfig {

        const encryptor = createEncryptor({
            secretKey: AppConfig.getInstance().reduxEncryptKey,
            onError: error => {
                console.error('FALHA - ReduxPersistorHelper.getPersistorConfig.createEncryptor: ', error)
            }
        })

        return {
            ...this.PERSIST_CONFIG_DEFAULT,
            whitelist: this.PERSIST_WHITELIST,
            transforms: [encryptor]
        }
    }
}

