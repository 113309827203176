export enum ToxicityEnum {
    NO_CONTAMINATION = 'NO_CONTAMINATION',
    MEDIUM_CONTAMINATION = 'MEDIUM_CONTAMINATION',
    HIGH_CONTAMINATION = 'HIGH_CONTAMINATION',
}

export enum ToxicityLabelEnum {
	NO_CONTAMINATION = 'Sem Contaminação',
    MEDIUM_CONTAMINATION = 'Média Contaminação',
    HIGH_CONTAMINATION = 'Alta Contaminação',
}

