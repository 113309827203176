import React from 'react'
import { ImageLayoutImageSiderICP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/inner/ImageLayoutImageSiderICP'
import { ContentLayoutImageSiderICP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/inner/ContentLayoutImageSiderICP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import styled from 'styled-components'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'

interface ICPProps {
	sider: {
		width?: string
		imageUrl: string
	}
	content: {
		logoUrl: string
		logoWidth: number
	}
	children: JSX.Element | JSX.Element[]
}

/**
 * Tela para criacao de conta
 */
export function LayoutImageSiderCP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<LayoutWrapperSCP>
			<LayoutCP
				sider={screenSize.smd ? undefined : <ImageLayoutImageSiderICP width={props.sider.width} imageUrl={props.sider.imageUrl} />}
				mainLayout={!screenSize.smd}
				content={
					<>
						<TopBarHomEnvironmentCP positionFixed={true} />
						<ContentLayoutImageSiderICP logoWidth={props.content.logoWidth} logoUrl={props.content.logoUrl}>
							{props.children}
						</ContentLayoutImageSiderICP>
					</>
				}
			/>
		</LayoutWrapperSCP>
	)
}

const LayoutWrapperSCP = styled.div`
	.ant-layout {
		background: #fdfdfd;
		height: 100vh;
	}
	.ant-card {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
`
