import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { ChartHistoricalSeriesCP } from 'modules/reports/components/charts/chart-historical-series/ChartHistoricalSeriesCP'
import { HistoricChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/HistoricChartRequestDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MapFiltersFormModel } from 'modules/map/components/filters-map/inner/MapFiltersFormModel'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'

interface ICPProps {
    location: LocationResponseDTO
    onHide: () => void
    formStateManager: IFormStateManager<MapFiltersFormModel>
}

/**
 */
export function PopoverContentMapPinCP(props: ICPProps): JSX.Element {

    const [loadedData, setLoadedData] = useState<GenericChartResponseDTO>()
    const getHistoricalChartRequest = useRequest<GenericChartResponseDTO>()
    useEffect(onGetHistoricChartChange, [getHistoricalChartRequest.isAwaiting])

    useEffect(init, [])

    /**
     */
    function init(): void {

        const formValues = props.formStateManager.getFormValues()!

        const filters: HistoricChartRequestDTO = {
            fieldCodes: [formValues.fieldCode],
            regulationCode: formValues.fieldRegulationCode,
            dateRange: formValues.dateRange,
            locationCode: props.location.code,
        }
        getHistoricalChartRequest.runRequest(ChartsRequests.getHistoricChart(filters))
    }

    /**
     */
    function onGetHistoricChartChange(): void {

        if (!RequestUtils.isValidRequestReturn(getHistoricalChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getHistoricalChartRequest.responseData!)
    }

    return (
        <>
            <i>{props.location.description}</i>

            {
                !loadedData
                    ?
                    <LoadingCP/>
                    :
                    <ChartHistoricalSeriesCP
                        appearance={{
                            hideTitle: true,
                            width: '500px'
                        }}
                        data={loadedData}
                    />
            }

            <FlexCP justifyContent={'space-between'}>
                <ButtonCP
                    onClick={() => RoutingHelper.goToChartHistorical({ locationCode: props.location.code, fieldCodes: [props.formStateManager.getFieldValue('fieldCode')] })}
                    size={'small'}
                >
                    Ver série histórica
                </ButtonCP>
                <ButtonCP
                    onClick={props.onHide}
                    size={'small'}
                    type={'danger'}
                    marginLeft={10}
                >
                    Ocultar
                </ButtonCP>
            </FlexCP>
        </>
    )
}
