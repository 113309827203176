import React, { useEffect, useState } from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { MatrizCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/MatrizCorrelationChartResponseDTO'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { CorrelationTP } from 'submodules/nerit-aquatec-sdk/services/charts/types/CorrelationTP'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { MatrixCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/MatrixCorrelationChartRequestDTO'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'

interface ICPProps {
    data: MatrizCorrelationChartResponseDTO
    filters: MatrixCorrelationChartRequestDTO
}

/**
 */
export function TableMatrixCorrelationChartCP(props: ICPProps): JSX.Element {

    const [columns, setColumns] = useState<ITableColumn<CorrelationTP>[]>([])
    useEffect(init, [props.data])

    /**
     */
    function init(): void {

        // Monta colunas dinamicas da tabela
        const formattedColumns: ITableColumn<CorrelationTP>[] = []
        formattedColumns.push({
            render: (text, record) => record.parameterName
        })

        // Usar um Set para armazenar os parameterName únicos
        const uniqueParameterNamesSet = new Set<string>()
        props.data.correlations.forEach((item) => {
            item.values.forEach((value) => {
                uniqueParameterNamesSet.add(value.parameterName)
            })
        })

        // Itera nos parametros unicos para criar as colunas
        uniqueParameterNamesSet.forEach((parameterColumn) => {
            formattedColumns.push({
                title: parameterColumn,
                render: (text, record) => record.values.find((paramValue) => paramValue.parameterName === parameterColumn)?.correlation
            })
        })
        setColumns(formattedColumns)
    }

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getMatrixCorrelationChartExport(props.filters)}
                    fileName={'correlacao_de_parametros'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<CorrelationTP>
                wrappedOnCard={true}
                data={props.data.correlations}
                columns={columns}
            />
        </>
    )
}
