import * as React from 'react'
import styled from 'styled-components'

import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	show: boolean
	customLogo?: React.ReactNode
	appearance?: {
		top?: number
	}
}

/**
 * Componente de loading que ocupa espaco inteiro do container dentro do qual for inserido.
 */
export function LoadingOverlayCP(props: ICPProps): JSX.Element | null {
	if (!props.show) return null

	return <LoadingWrapperSCP top={props.appearance?.top ?? 0}>{!!props.customLogo ? props.customLogo : <LoadingIconCP size={35} />}</LoadingWrapperSCP>
}

const LoadingWrapperSCP = styled.div<{ top: number }>`
	position: absolute;
	top: ${(props) => props.top}px;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight}B2;
	z-index: 999999;
`
