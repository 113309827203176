import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { SchemaResponseDTO } from 'submodules/nerit-aquatec-sdk/services/sys-admin/dtos/response/SchemaResponseDTO'
import { AuthResponseDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { AuthUserResponseDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'

/**
 */
export const AppStateUtils = {

    getLoggedUser(): OrUndefinedTP<AuthUserResponseDTO> {
        return AppStateUtils.getAuthData()?.user
    },

    getAuthData(): OrUndefinedTP<AuthResponseDTO> {
        return (ReduxHelper.getInstance().store.getState().auth as AuthResponseDTO)
    },

    getDomainData(): OrUndefinedTP<SchemaResponseDTO> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

}
