import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { SamplingRequests } from 'submodules/nerit-aquatec-sdk/services/samplings/SamplingRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { SamplingResponseDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/responses/SamplingResponseDTO'
import { SamplingSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/requests/SamplingSaveRequestDTO'

interface ICPProps extends BasicModalCPProps {
    sampling?: SamplingResponseDTO
}

export function ModalSamplingCP(props: ICPProps): JSX.Element {

    const [name, setName] = useState<string>()
    const [dateRange, setDateRange] = useState<IDateRangeFilter | undefined>()

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        if (!props.visible || !props.sampling)
            return

        setDateRange({
            beginDate: props.sampling.beginDate as any,
            endDate: props.sampling.endDate as any
        })
        setName(props.sampling.name)
    }

    /**
     */
    function save(): void {

        if (!name || !dateRange || !dateRange.beginDate || !dateRange.endDate) {
            NotificationHelper.error('Ops', 'Preencha os campos obrigatórios')
            return
        }

        const dto: SamplingSaveRequestDTO = {
            name,
            beginDate: dateRange.beginDate,
            endDate: dateRange.endDate,
        }

        if (!!props.sampling)
            saveRequest.runRequest(SamplingRequests.update(props.sampling.code, dto))
        else
            saveRequest.runRequest(SamplingRequests.create(dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onCancel={props.onCancel}
            onOk={save}
            title={'Campanha'}
            width={400}
        >
            <InputCP
                label={'Nome'}
                value={name}
                onChange={setName}
                required={true}
            />

            <DateRangePickerCP
                showBorder={true}
                marginTop={23}
                fastFilter={'all'}
                value={dateRange}
                onChange={setDateRange}
            />
        </ModalCP>
    )
}
