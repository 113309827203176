import React, { ReactNode } from 'react'
import { Menu } from 'antd'
import { ObjectUtils } from 'submodules/nerit-framework-utils/utils/ObjectUtils'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { BarActiveMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/bar-active-menu-item/BarActiveMenuItemCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

type _AlignItemTP = 'right' | 'left' | 'center'

type _ScpPropsTP = {
	marginVertical?: number
	isSecondaryMenu?: boolean
}

const CLASS_ACTIVE_ANT = 'ant-menu-item-selected'

interface IMenuItemICPProps extends _ScpPropsTP {
	onClick: () => void
	title?: string
	isActive?: boolean
	tooltipTitle?: string
	align?: _AlignItemTP
	children: ReactNode
	disabled?: boolean
}

/**
 * COMPONENTE: Item de 01 menu.
 * OBSERVACAO: Este componente so funciona perfeitamente se for FILHO DIRETO de 01 componente de 'menu' ou 'submenu'
 */
export function MenuItemCP(props: IMenuItemICPProps): JSX.Element {
	function getAntProps(): {} {
		type _TypesToRemoveTP = IMenuItemICPProps

		const cpProps: Array<keyof _TypesToRemoveTP> = ['isActive', 'tooltipTitle', 'align', 'marginVertical']

		return ObjectUtils.getObjectsWithoutSomeProps<_TypesToRemoveTP>(props, cpProps)
	}

	function getClassName(): string {
		const isSelectable = (props as any).parentMenu?.props?.selectable ?? true
		return !!props.isActive && isSelectable ? CLASS_ACTIVE_ANT : ''
	}

	const justifyContent =
		props.align === 'left' ? 'flex-start' : props.align === 'right' ? 'flex-end' : props.align === 'center' ? 'center' : 'space-between'

	return (
		<TooltipCP placement={'right'} text={props.tooltipTitle} showSpan={true}>
			<MenuItemWrapperSCP marginVertical={props.marginVertical} justifyContent={justifyContent}>
				<Menu.Item className={getClassName()} onClick={props.onClick} title={props.title} disabled={props.disabled} {...getAntProps()}>
					{props.children}
				</Menu.Item>

				<BarActiveMenuItemCP isActive={props.isActive} isSecondaryMenu={props.isSecondaryMenu} />
			</MenuItemWrapperSCP>
		</TooltipCP>
	)
}

const MenuItemWrapperSCP = styled.div<_ScpPropsTP & { justifyContent: string }>`
	position: relative;
	margin: ${(props) => (!!props.marginVertical ? `${props.marginVertical}px 0` : 'unset')};

	.ant-menu-item-selected {
		background: none !important;
	}

	.ant-menu-item {
		display: flex;
		justify-content: ${(props) => props.justifyContent};
		align-items: center;
		color: ${(props) => (props.isSecondaryMenu ? ThemeFrameworkCommon.browserDefaultColorDark : ThemeProject.menuItemColor)};

		:hover {
			opacity: 0.5;
		}

		svg {
			margin: 0;
			color: ${(props) => (props.isSecondaryMenu ? ThemeFrameworkCommon.browserDefaultColorDark : ThemeProject.menuItemColor)};
		}
	}
`
