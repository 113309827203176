import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { FilterMatrixCorrelationChartCP } from 'modules/reports/components/filters/filter-matrix-correlation-chart/FilterMatrixCorrelationChartCP'
import { MatrixCorrelationChartFiltersFormModel } from 'modules/reports/components/filters/filter-matrix-correlation-chart/inner/MatrixCorrelationChartFiltersFormModel'
import { MatrixCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/MatrixCorrelationChartRequestDTO'
import { MatrizCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/MatrizCorrelationChartResponseDTO'
import { TableMatrixCorrelationChartCP } from 'modules/reports/components/tables/table-matrix-correlation-chart/TableMatrixCorrelationChartCP'

/**
 */
export function ScreenContentChartMatrixCorrelation(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<MatrixCorrelationChartFiltersFormModel>(new MatrixCorrelationChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<MatrizCorrelationChartResponseDTO>()

    const [matrixCorrelationChartRequestDTO, setMatrixCorrelationChartRequestDTO] = useState<MatrixCorrelationChartRequestDTO>()
    const getChartRequest = useRequest<MatrizCorrelationChartResponseDTO>()
    useEffect(onGetChartRequestChange, [getChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: MatrixCorrelationChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new MatrixCorrelationChartFiltersFormModel({
                fieldCodes: queryFilters?.fieldCodes,
                locationCodes: queryFilters?.locationCodes,
                dateRange: queryFilters?.dateRange,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const filters: MatrixCorrelationChartRequestDTO = {
            fieldCodes: formValues.fieldCodes,
            locationCodes: formValues.locationCodes,
            dateRange: formValues.dateRange,
        }
        getChartRequest.runRequest(ChartsRequests.getMatrixCorrelationChart(filters))
        setLoadedData(undefined)
        setMatrixCorrelationChartRequestDTO(matrixCorrelationChartRequestDTO)
    }

    /**
     */
    function onGetChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Matriz de Correlação'}/>
            }
            content={
                <>
                    <FilterMatrixCorrelationChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!matrixCorrelationChartRequestDTO &&
                                <>
                                    <TableMatrixCorrelationChartCP
                                        data={loadedData}
                                        filters={matrixCorrelationChartRequestDTO}
                                    />
                                </>
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
