import React from 'react'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { FormLoginCP } from 'app/components/form-login/FormLoginCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 */
export function ScreenLogin(): JSX.Element {

    return (
        <LayoutImageSiderCP
            sider={{ width: '60%', imageUrl: AppStateUtils.getDomainData()?.coverUrl ?? `${process.env.PUBLIC_URL}/images/login-cover.jpg` }}
            content={{ logoUrl: AppStateUtils.getDomainData()?.logoUrl ?? ThemeProject.logoLoginUrl, logoWidth: 100 }}
        >
            <ContentCP>
                <FormWrapperSCP>
                    <FormLoginCP/>
                </FormWrapperSCP>
            </ContentCP>
        </LayoutImageSiderCP>
    )
}

const FormWrapperSCP = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`
