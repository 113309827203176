import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { LocationEvaluationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/requests/LocationEvaluationRequestDTO'
import { LocationEvaluationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationEvaluationResponseDTO'
import { LocationNameResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationNameResponseDTO'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { LocationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/requests/LocationSaveRequestDTO'

export class LocationRequests {

    public static CONTROLLER_ROOT = 'locations'
    public static LIST_NAMES_EP = 'list-names'
    public static LIST_EP = 'list'
    public static LIST_NATURES_NAMES_EP = 'nature-list-names'
    public static LIST_REGULATION_NAMES_EP = 'regulation-list-names'
    public static POST_LOCATION_PLANING_EP = 'post-location-planing'
    public static CREATE_EP = 'create'
    public static UPDATE_EP = 'update/:code'
    public static DELETE_EP = 'delete/:code'
    public static GET_LOCATION_PLANING_EP = 'get-location-planing'
    public static GET_LOCATION_EVALUATION_EP = 'get-location-evaluation'

    static getNameList = (): RequestConfigTP<ISearchResult<LocationNameResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.LIST_NAMES_EP}`,
        method: HttpMethodEnum.GET,
    })

    static getList = (): RequestConfigTP<ISearchResult<LocationResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.LIST_EP}`,
        method: HttpMethodEnum.GET,
    })

    static delete = (code: number): RequestConfigTP<ISearchResult<LocationResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.DELETE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.DELETE,
    })

    static getNatureNameList = (): RequestConfigTP<ISearchResult<LocationNameResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.LIST_NATURES_NAMES_EP}`,
        method: HttpMethodEnum.GET,
    })

    static getRegulationNameList = (): RequestConfigTP<ISearchResult<LocationNameResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.LIST_REGULATION_NAMES_EP}`,
        method: HttpMethodEnum.GET,
    })

    static postLocationPlaning = (dto: any): RequestConfigTP<ISearchResult<LocationNameResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.POST_LOCATION_PLANING_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static create = (dto: LocationSaveRequestDTO): RequestConfigTP<void> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.CREATE_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static update = (code: number, dto: LocationSaveRequestDTO): RequestConfigTP<void> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.UPDATE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getLocationPlaning = (dto: any): RequestConfigTP<ISearchResult<LocationNameResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.GET_LOCATION_PLANING_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static getLocationEvaluation = (dto: LocationEvaluationRequestDTO): RequestConfigTP<LocationEvaluationResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${LocationRequests.CONTROLLER_ROOT}/${LocationRequests.GET_LOCATION_EVALUATION_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })
}
