import { IQAEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IQAEnum'

export const ThemeProjectCommon = {

    qualityWaterColors: {
        [IQAEnum.EXCELENT]: '#A2CFFA',
        [IQAEnum.GOOD]: '#BFF8BF',
        [IQAEnum.MEDIUM]: '#F6F9A3',
        [IQAEnum.BAD]: '#FBC287',
        [IQAEnum.VERY_BAD]: '#FC8786',
    },

}
