import React, { useEffect, useState } from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { ToxicityChartListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/ToxicityChartListItemResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import styled from 'styled-components'
import { ToxicityEnum, ToxicityLabelEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/ToxicityEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { SubtitleTableWrapperCP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/SubtitleTableWrapperCP'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ToxicityIndexChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/ToxicityIndexChartRequestDTO'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'

interface ICPProps {
    data: ToxicityChartListItemResponseDTO[]
    filters: ToxicityIndexChartRequestDTO
}

/**
 */
export function TableWaterContaminationChartCP(props: ICPProps): JSX.Element {

    const [columns, setColumns] = useState<ITableColumn<ToxicityChartListItemResponseDTO>[]>([])
    useEffect(init, [props.data])

    /**
     */
    function init(): void {

        // Monta colunas dinamicas da tabela
        const formattedColumns: ITableColumn<ToxicityChartListItemResponseDTO>[] = [
            {
                title: 'Ponto',
                render: (text, record) => record.locationName
            },
            {
                title: 'Data',
                render: (text, record) => DateUtils.formatDate(record.creationDate, DateFormatEnum.BR_WITHOUT_TIME)
            },
        ]

        // Usar um Set para armazenar os parameterName únicos
        const uniqueParameterNamesSet = new Set<string>()
        props.data.forEach((item) => {
            item.parameters.forEach((value) => {
                uniqueParameterNamesSet.add(value.parameter.name)
            })
        })

        // Itera nos parametros unicos para criar as colunas
        uniqueParameterNamesSet.forEach((parameterColumn) => {
            formattedColumns.push({
                title: parameterColumn,
                render: (text, record) => {
                    const parameterVal = record.parameters.find((paramValue) => paramValue.parameter.name === parameterColumn)

                    if (!parameterVal)
                        return <></>

                    return (
                        <IndexSCP className={parameterVal.toxicityIndex}>
                            {Number(parameterVal.value).toFixed(3)}
                        </IndexSCP>
                    )
                }
            })
        })
        setColumns(formattedColumns)
    }

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getToxicityChartExport(props.filters)}
                    fileName={'contaminacao_da_agua'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<ToxicityChartListItemResponseDTO>
                wrappedOnCard={true}
                data={props.data}
                columns={columns}
            />

            <SubtitleTableWrapperCP>
                <IndexSCP className={ToxicityEnum.NO_CONTAMINATION} style={{ marginRight: 10 }}>{ ToxicityLabelEnum.NO_CONTAMINATION }</IndexSCP>
                <IndexSCP className={ToxicityEnum.MEDIUM_CONTAMINATION} style={{ marginRight: 10 }} >{ ToxicityLabelEnum.MEDIUM_CONTAMINATION }</IndexSCP>
                <IndexSCP className={ToxicityEnum.HIGH_CONTAMINATION} >{ ToxicityLabelEnum.HIGH_CONTAMINATION }</IndexSCP>
            </SubtitleTableWrapperCP>
        </>
    )
}

const IndexSCP = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;

  &.${ToxicityEnum.NO_CONTAMINATION} {
    background-color: ${ThemeProject.success};
  }

  &.${ToxicityEnum.MEDIUM_CONTAMINATION} {
    background-color: ${ThemeProject.warning};
  }

  &.${ToxicityEnum.HIGH_CONTAMINATION} {
    background-color: ${ThemeProject.error};
  }
`
