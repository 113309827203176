import React, { useState } from 'react'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { LoginFormModel } from 'app/components/form-login/form-model/LoginFormModel'
import { FormLoginDataICP } from 'app/components/form-login/inner/FormLoginDataICP'

/**
 */
export function FormLoginCP(): JSX.Element {

    const [view, setView] = useState<'recoverPassword' | 'login'>('login')

    const [formModel] = useState<LoginFormModel>(new LoginFormModel())
    const formStateManager = useFormStateManager(formModel)

    return (
        <FormContainerSCP>
            <FormTitleSCP>Seja bem vindo, <b>Faça seu login para prosseguir</b></FormTitleSCP>
            {
                view === 'login' &&
                <>
                    <FormLoginDataICP
                        formStateManager={formStateManager}
                        onResetPassword={() => setView('recoverPassword')}
                    />
                </>
            }
        </FormContainerSCP>
    )
}

const FormContainerSCP = styled.div`
  width: 100%;
  max-width: 365px;
  height: 100%;
  margin-top: 30px;
`

const FormTitleSCP = styled.span`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  
  b {
    margin-left: 5px;
  }
`
