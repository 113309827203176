import React from 'react'
import { Result } from 'antd'
import { ResultStatusType } from 'antd/es/result'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	status?: ResultStatusType
	title?: string
	message?: React.ReactNode
	bottomContent?: JSX.Element
	icon?: JSX.Element
}

/**
 * Result do ANT para mostrar erros, etc..
 */
export function ScreenResultCP(props: ICPProps): JSX.Element {
	return (
		<Result icon={props.icon} status={props.status} title={<TitleSCP>{props.title}</TitleSCP>} subTitle={props.message} extra={props.bottomContent} />
	)
}

const TitleSCP = styled.p`
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
`
