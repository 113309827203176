import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableLocationsCP } from 'modules/locations/components/table-locations/TableLocationsCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonModalLocationCP } from 'modules/locations/components/buttons/button-modal-location/ButtonModalLocationCP'

export function ScreenLocations(): JSX.Element {

    const [loadData, setLoadData] = useState<number>(1)

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Pontos'}>
                    <ButtonModalLocationCP
                        onSave={() => setLoadData(TableUtils.getReloadNumber())}
                    />
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <TableLocationsCP
                    shouldLoadData={loadData}
                />
            }
        />
    )
}
