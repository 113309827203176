import React from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { TitleBrowserCP } from 'submodules/nerit-framework-ui/common/components/title-browser/TitleBrowserCP'
import { ChartsScreenUtils, SCREEN_CHARTS_MAIN_SUBMENU_KEY, ScreenChartsViewTP } from 'modules/reports/screens/screen-charts/inner/ChartsScreenUtils'
import { AppStateUtils } from 'app/redux/AppStateUtils'

/**
 */
export function ScreenCharts(): JSX.Element {

    return (
        <>
            <TitleBrowserCP pageName={'Gráficos'} />
            <LayoutSubmenuCP<ScreenChartsViewTP>
                menuTitle={'Gráficos'}
                loggedDomain={AppStateUtils.getDomainData()?.slug}
                defaultSubmenu={'historical'}
                defaultOpenKeys={[SCREEN_CHARTS_MAIN_SUBMENU_KEY]}
                submenuConfigs={ChartsScreenUtils.getSubMenuConfigs()}
            />
        </>
    )
}
