import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { ModalLocationCP } from 'modules/locations/components/modal-location/ModalLocationCP'

interface ICPProps {
    location?: LocationResponseDTO
    onSave: () => void
}

export function ButtonModalLocationCP(props: ICPProps): JSX.Element {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    return (
        <>
            <ButtonCP
                icon={!!props.location ? 'edit' : 'plus'}
                size={!!props.location ? 'small' : 'default'}
                onClick={() => setIsModalVisible(true)}
            >
                {!props.location && 'Novo ponto'}
            </ButtonCP>

            <ModalLocationCP
                location={props.location}
                onCancel={() => setIsModalVisible(false)}
                onSave={() => {
                    setIsModalVisible(false)
                    props.onSave()
                }}
                visible={isModalVisible}
            />
        </>
    )
}
