import * as _ from 'lodash'

/**
 * UTILS
 * Utilidades gerais para string
 */
export const StringUtils = {
	isEmpty(str?: string): boolean {
		if (!str) return true

		return str.trim() === ''
	},

	/** Altera & retorna 01 string na forma 'camelCase'. */
	toCamelCase(str: string): string {
		if (!/-/.exec(str)) return str

		return str.split('-').reduce((camelCaseStr: string, strToken: string) => {
			if (camelCaseStr) strToken = strToken.charAt(0).toUpperCase() + strToken.slice(1)
			return camelCaseStr + strToken
		}, '')
	},

	/** Retorna a propria String caso ela tenha conteudo. Caso contrario retorna string vazia. */
	stringValue(str: string): string {
		if (str && str.length > 0) return str
		return ''
	},

	stripNonNumericChars(str: string): string {
		return str.replace(/\D/g, '')
	},

	/** Transforma & retorna string da forma 'camelCase' na forma 'kebab-case'. */
	camelCaseToKebabCase(str: string): string {
		return str
			.replace(/([\da-z])([A-Z])/g, '$1-$2')
			.replace(/\s/g, '-')
			.toLowerCase()
	},

	/**
	 * Obtem a primeira e ultima iniciais de uma string
	 */
	getFirstAndLastInitials(str: string): string {
		if (!str) return ''

		const initials = str.match(/\b\w/g) ?? []
		return ((initials.shift() ?? '') + (initials.pop() ?? '')).toUpperCase()
	},

	/** Retorna string sem nenhum acento. */
	removeAccents(string: string): string {
		const replacingCharGroups = {
			áàâã: 'a',
			éèê: 'e',
			íìî: 'i',
			óòôõ: 'o',
			úùû: 'u',
			ç: 'c',
		}

		for (const isUpperCase of [false, true]) {
			for (const charGroup of Object.keys(replacingCharGroups)) {
				const charGroupForRegex = isUpperCase ? charGroup.toLocaleUpperCase() : charGroup
				const regex = new RegExp(`[${charGroupForRegex}]`, 'g')
				string = string.replace(regex, replacingCharGroups[charGroup as keyof typeof replacingCharGroups])
			}
		}

		return string
	},

	/** Retorna string sem nenhum caracter especial. */
	removeSpecialCharacters(str: string): string {
		return str.replace(/[^\w\s-_.]/gi, '')
	},

	/** Retorna string sem nenhum caracter especial. */
	removeNumbers(str: string): string {
		return str.replace(/\d/gi, '')
	},

	onlyNumbers(str: string): string {
		return str?.replace(/\D/gi, '')
	},

	isNumber(str: any): boolean {
		return !isNaN(str)
	},

	/** Retorna sem acento, caracteres especiais, letras maiusculas ou espacos. */
	getSlugStyleString(string: string, replaceSpaceWith = '-', maxLength?: number) {
		string = StringUtils.removeAccents(string.toLowerCase())
		string = StringUtils.removeSpecialCharacters(string)

		replaceSpaceWith = replaceSpaceWith || '-'
		string = string.replace(/\s/g, replaceSpaceWith)

		if (maxLength) {
			const regex = new RegExp(`^(.{${maxLength}})(.*)`, 'g')
			string = string.replace(regex, '$1')
		}

		return string
	},

	/**
	 * Retorna String nos padroes da Nerit para criacao de schemas no banco.
	 * Apenas lestras minusculas sao aceitas.
	 */
	getSchemaStyleString(str: string): string {
		str = StringUtils.getSlugStyleString(str.toLowerCase())
		str = StringUtils.removeNumbers(str)
		str = str.replace(/-/g, '')
		return str
	},

	/** Separar string em 2, pegando a primeira ocorrencia do separador. */
	splitOnce(str: string, separator: string): [string, string] {
		const index = str.indexOf(separator)
		return [str.slice(0, index), str.slice(index + 1)]
	},

	/**
	 * Retorna string envolta por 02 textos SE 01 condicao for verdadeira. Retorna a string sem
	 * nenhuma alteracao, caso controrio.
	 */
	wrapIfTrue(string: string, wrapperBefore: string, wrapperAfter: string, condition?: boolean): string {
		return condition ? `${wrapperBefore}${string}${wrapperAfter}` : string
	},

	limitString(string: string, size: number): string {
		return _.truncate(string, { length: size, omission: '...' })
	},

	/**
	 * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles estiverem
	 * no COMECO da string.
	 */
	stripInitialChars(string: string, chars: string): string {
		if (string === chars) return ''
		const regex = new RegExp(`^(${chars})(.+)`)
		return string.replace(regex, '$2')
	},

	/** Obtem o primeiro nome de um nome completo. */
	getFirstName(fullName: string): string {
		if (!fullName || fullName === '') return ''

		return fullName.split(' ')[0]
	},

	/**
	 * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles aparecerem
	 * duplicados no inicio da string.
	 */
	stripRepeatedBegin(string: string, chars: string): string {
		return string.replace(new RegExp(`^(${chars})(${chars}.*)`, 'g'), '$2')
	},

	/**
	 * Retorna 01 string retirando um determinado conjunto especifico de caracteres SE eles estiverem
	 * no FIM da string.
	 */
	stripEndingChars(string: string, chars: string): string {
		if (string === chars) return ''
		const regex = new RegExp(`(.+)(${chars})$`)
		return string.replace(regex, '$1')
	},

	/**
	 * Retorna o PATH da URL. Sem os parametros.
	 */
	getURLPath(url: string): string {
		if (!url) return ''

		const urlHasQueryParams = url.includes('?')
		return urlHasQueryParams ? url.substring(0, url.indexOf('?')) : url
	},

	/**
	 * Retorna apenas os parametros de uma URL.
	 */
	getURLQueryParams(url: string): string {
		if (!url) return ''

		const urlHasQueryParams = url.includes('?')
		return urlHasQueryParams ? url.substring(url.indexOf('?')) : ''
	},

	replaceAll(string: string, from: string, to: string): string {
		const regex = new RegExp(from, 'g')
		return string.replace(regex, to)
	},

	isUUID_V4(id: string): boolean {
		const regex = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$', 'gi')
		return regex.test(id)
	},

	stringToBoolean(booleanString: string): boolean {
		if (booleanString === 'true') return true

		if (booleanString === 'false') return false

		return false
	},
} as const
