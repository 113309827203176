import React from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import styled from 'styled-components'
import { SubtitleTableWrapperCP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/SubtitleTableWrapperCP'
import { IQATableTP } from 'submodules/nerit-aquatec-sdk/services/charts/types/IQATableTP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { IQAEnum, IQALabelEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IQAEnum'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { IQAChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IQAChartRequestDTO'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'

interface ICPProps {
    data: IQATableTP[]
    filters: IQAChartRequestDTO
}

/**
 */
export function TableWaterQualityChartCP(props: ICPProps): JSX.Element {

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getIQAChartExport(props.filters)}
                    fileName={'qualidade_da_agua'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<IQATableTP>
                wrappedOnCard={true}
                data={props.data}
                columns={[
                    {
                        title: 'Ponto',
                        render: (text, record) => record.locationName
                    },
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'IQA',
                        render: (text, record) => (
                            <IndexSCP className={record.quality}>
                                {MaskUtils.applyNumberMask(+record.iqa, 2)}
                            </IndexSCP>
                        )
                    },
                ]}
            />

            <SubtitleTableWrapperCP>
                {
                    Object.values(IQAEnum).map((iqa) => (
                        <IndexSCP className={iqa} style={{ marginRight: 10 }}>{ IQALabelEnum[iqa] }</IndexSCP>
                    ))
                }
            </SubtitleTableWrapperCP>
        </>
    )
}

const IndexSCP = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;

  &.${IQAEnum.EXCELENT} {
    background-color: ${ThemeProjectCommon.qualityWaterColors[IQAEnum.EXCELENT]};
  }

  &.${IQAEnum.GOOD} {
    background-color: ${ThemeProjectCommon.qualityWaterColors[IQAEnum.GOOD]};
  }

  &.${IQAEnum.MEDIUM} {
    background-color: ${ThemeProjectCommon.qualityWaterColors[IQAEnum.MEDIUM]};
  }

  &.${IQAEnum.BAD} {
    background-color: ${ThemeProjectCommon.qualityWaterColors[IQAEnum.BAD]};
  }

  &.${IQAEnum.VERY_BAD} {
    background-color: ${ThemeProjectCommon.qualityWaterColors[IQAEnum.VERY_BAD]};
  }
`
