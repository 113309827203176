import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida se é um Inteiro
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_INT })
class IsIntConstraint implements ValidatorConstraintInterface {
	validate(value: number, args: ValidationArguments): boolean {
		const validator = new Validator()
		return validator.isInt(value)
	}

	defaultMessage(args: ValidationArguments): string {
		return 'Valor deve ser do tipo inteiro!'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsInt(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsIntConstraint,
		})
	}
}
