import { SchemaResponseDTO } from 'submodules/nerit-aquatec-sdk/services/sys-admin/dtos/response/SchemaResponseDTO'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { HistoricChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/HistoricChartRequestDTO'

/**
 */
export class RoutingHelper {

    public static async validateDomain(): Promise<SchemaResponseDTO | undefined> {

        try {
            const domain = window.location.pathname.split('/')[1]

            return {
                code: 100,
                name: domain,
                slug: domain
            }

            // // eslint-disable-next-line no-unreachable
            // const reqConfig = SysAdminRequests.getSchemaDataConfig(domain, AppConfig.getInstance().anonymousUserToken)
            // const validationReturn = await RequestHelper.runRequest(reqConfig)

            // const schemaData = ((validationReturn as AxiosResponse)?.data as IApiReturn<SchemaResponseDTO>)?.data

            // if (!schemaData)
            //     return

            // // AuthActions.setDomain(schemaData)
            // return schemaData

        } catch (err) {
            return
        }
    }

    static goToChartHistorical(dto?: HistoricChartRequestDTO): void {

        let url = PrivateRouter.CHART_HISTORIC_SERIES
        if (!!dto)
            url = `${url}?${SCREEN_FILTER_URL_PARAM}=${JSON.stringify(dto)}`

        NeritFrameworkRoutingHelper.historyPush(url)
    }
}
