import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { NatureLabelEnum } from 'submodules/nerit-aquatec-sdk/common/enums/NatureEnum'
import { FieldRegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/FieldRegulationRequests'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FieldRegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/FieldRegulationResponseDTO'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonModalFieldRegulationCP } from 'modules/fields/components/buttons/button-modal-field-regulation/ButtonModalFieldRegulationCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { FieldRegulationSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/FieldRegulationSearchRequestDTO'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface ICPProps {
    shouldLoadData: number
    filtersDto: FieldRegulationSearchRequestDTO
}

export function TableFieldsRegulationCP(props: ICPProps): JSX.Element {

    const [shouldLoadData, setShouldLoadData] = useState<number>(props.shouldLoadData)
    useEffect(() => setShouldLoadData(props.shouldLoadData), [props.shouldLoadData])

    return (
        <TableFromApiCP<FieldRegulationResponseDTO>
            apiConfig={{
                hasPagination: true,
                requestConfigTP: (filtersDto) => FieldRegulationRequests.search({
                    ...filtersDto,
                    ...props.filtersDto
                })
            }}
            appearance={{
                showReloadButton: true,
                wrappedOnCard: true,
                showTotalOnHeader: true,
            }}
            shouldLoadData={shouldLoadData}
            columns={[
                {
                    title: '#',
                    render: (text, record) => record.code
                },
                {
                    title: 'Nome',
                    render: (text, record) => <TextCP text={record.field.name} size={'small'} copyable={true}/>
                },
                {
                    title: 'Natureza',
                    render: (text, record) => NatureLabelEnum[record.nature]
                },
                {
                    title: 'Norma',
                    render: (text, record) => record.regulation.name
                },
                {
                    title: 'Mínimo Permitido',
                    render: (text, record) => record.minPermitted
                },
                {
                    title: 'Máximo Permitido',
                    render: (text, record) => record.maxPermitted
                },
                {
                    render: (text, record) =>
                        <ButtonGroupCP mode={'separeted'}>
                            <ButtonModalFieldRegulationCP
                                onSave={() => setShouldLoadData(TableUtils.getReloadNumber())}
                                fieldRegulation={record}
                                buttonSize={'small'}
                            />
                            <ButtonDeleteRecordCP
                                requestConfigTP={() => FieldRegulationRequests.delete(record.code)}
                                onDelete={() => setShouldLoadData(TableUtils.getReloadNumber())}
                            />
                        </ButtonGroupCP>
                }
            ]}
        />
    )
}
