import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { FilterGeographicChartCP } from 'modules/reports/components/filters/filter-geographic-chart/FilterGeographicChartCP'
import { GeographicChartFiltersFormModel } from 'modules/reports/components/filters/filter-geographic-chart/inner/GeographicChartFiltersFormModel'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { GeographicChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/GeographicChartRequestDTO'
import { ChartGeographicCP } from 'modules/reports/components/charts/chart-geographic/ChartGeographicCP'
import { TableGeographicChartCP } from 'modules/reports/components/tables/table-geographic-chart/TableGeographicChartCP'

/**
 */
export function ScreenContentChartGeographic(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<GeographicChartFiltersFormModel>(new GeographicChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<GenericChartResponseDTO>()

    const [geographicChartRequestDTO, setGeographicChartRequestDTO] = useState<GeographicChartRequestDTO>()
    const getStatisticChartRequest = useRequest<GenericChartResponseDTO>()
    useEffect(onFetStatisticChartRequestChange, [getStatisticChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => {
        filter()
    }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: GeographicChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new GeographicChartFiltersFormModel({
                fieldCode: queryFilters?.fieldCode,
                locationCodes: queryFilters?.locationCodes,
                dateRange: queryFilters?.dateRange,
                fieldRegulationCode: queryFilters?.regulationCode,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        setLoadedData(undefined)
        const formValues = formStateManager.getFormValues()!

        const filters: GeographicChartRequestDTO = {
            fieldCode: formValues.fieldCode,
            dateRange: formValues.dateRange,
            locationCodes: formValues.locationCodes,
            regulationCode: formValues.fieldRegulationCode,
        }
        getStatisticChartRequest.runRequest(ChartsRequests.getGeographicChart(filters))
        setGeographicChartRequestDTO(filters)
    }

    /**
     */
    function onFetStatisticChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getStatisticChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getStatisticChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Geográfico'}/>
            }
            content={
                <>
                    <FilterGeographicChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getStatisticChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!geographicChartRequestDTO &&
                                <>
                                    <ChartGeographicCP data={loadedData}/>
                                    <TableGeographicChartCP
                                        data={loadedData}
                                        filters={geographicChartRequestDTO}
                                    />
                                </>
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
