import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'

interface ICPProps {
	children: React.ReactNode
}

/**
 */
export function SubtitleTableWrapperCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<i>Legenda:</i>

			<FlexCP flexWrap={'wrap'}>{props.children}</FlexCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	padding: 0 20px;
`
