import { ValidationError } from 'class-validator'
import { useEffect, useState } from 'react'
import { IFormModelValidator } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormModelValidator'
import { FormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManager'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

type _HookStateTP<FModelTP extends IFormModelValidator> = { formState: FormStateManager<FModelTP> }

/**
 * HOOK
 * Expoe gerenciador de estado de formularios para uso em componentes funcionais.
 */
export function useFormStateManager<FModelValidatorTP extends IFormModelValidator>(formModel?: FModelValidatorTP): IFormStateManager<FModelValidatorTP> {
	const [hookState, setHookState] = useState<_HookStateTP<FModelValidatorTP>>()

	useEffect(onValidatorSet, [formModel])

	function onValidatorSet(): void {
		if (!!formModel) setHookState({ formState: new FormStateManager<FModelValidatorTP>(formModel) })
	}

	const changeFieldValue = (fieldName: keyof FModelValidatorTP, value: any): void => {
		if (!!hookState) {
			hookState.formState.changeFieldValue(fieldName as any, value)
			setHookState({ formState: hookState.formState })
		}
	}

	const validate = async (): Promise<boolean> => {
		if (!hookState) return false
		const isValid = await hookState.formState.validate()
		setHookState({ formState: hookState.formState })
		return isValid
	}

	const getFieldError = (fieldName: keyof FModelValidatorTP): ValidationError | undefined => {
		if (!hookState) return
		const error = hookState.formState.getFieldError(fieldName)
		setHookState({ formState: hookState.formState })
		return error
	}

	const reset = async (replacingData?: FModelValidatorTP): Promise<void> => {
		if (!!hookState) {
			await hookState.formState.reset(replacingData)
			setHookState({ formState: hookState.formState })
		}
	}

	const setConsiderAllErrors = (consider: boolean): void => {
		if (!!hookState) {
			hookState.formState.considerAllErrors = consider
			setHookState({ formState: hookState.formState })
		}
	}

	const setFieldDirty = (fieldName: keyof FModelValidatorTP): void => {
		if (!!hookState) {
			hookState.formState.setFieldDirty(fieldName)
			setHookState({ formState: hookState.formState })
		}
	}

	return {
		isValid: hookState?.formState.isValid ?? false,
		isDirty: hookState?.formState.isDirty ?? false,
		validationsCount: hookState?.formState.validationsCount ?? 0,
		considerAllErrors: hookState?.formState.considerAllErrors ?? false,

		getFieldValue: (fieldName) => hookState?.formState.getFieldValue(fieldName),
		debugFieldValues: () => hookState?.formState.debugFieldValues(),
		getFormValues: () => hookState?.formState.getFormValues(),
		debugErrors: () => hookState?.formState.debugErrors(),

		setConsiderAllErrors,
		changeFieldValue,
		setFieldDirty,
		getFieldError,
		validate,
		reset,
	}
}
