import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalFieldRegulationCP } from 'modules/fields/components/modals/modal-field-regulation/ModalFieldRegulationCP'
import { FieldRegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/FieldRegulationResponseDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
    fieldRegulation?: FieldRegulationResponseDTO
    buttonSize?: ButtonSizeTP
    onSave: () => void
}

export function ButtonModalFieldRegulationCP(props: ICPProps): JSX.Element {

    const [isModalAddFieldRegulationVisible, setModalAddFieldRegulationVisible] = useState<boolean>(false)

    return (
        <>
            <ButtonCP
                style={{marginLeft: 10}}
                onClick={() => setModalAddFieldRegulationVisible(true)}
                icon={props.fieldRegulation ? 'edit' : 'plus'}
                size={props.buttonSize}
            >
                { props.fieldRegulation ? '' : 'Nova configuração de parâmetro' }
            </ButtonCP>

            <ModalFieldRegulationCP
                visible={isModalAddFieldRegulationVisible}
                onCancel={() => setModalAddFieldRegulationVisible(false)}
                fieldRegulation={props.fieldRegulation}
                onSave={() => {
                    setModalAddFieldRegulationVisible(false)
                    props.onSave()
                }}
            />
        </>
    )
}
