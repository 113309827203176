import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	pageName?: string
}

/**
 * Title da página no Browser
 */
export function TitleBrowserCP(props: ICPProps): JSX.Element {
	if (!props.pageName) return <></>

	return (
		<Helmet>
			<title>{props.pageName}</title>

			<link rel={'icon'} href={ThemeProject.logoHeaderUrl} />
		</Helmet>
	)
}
