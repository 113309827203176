import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableSamplingsCP } from 'modules/samplings/components/table-samplings/TableSamplingsCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonModalSamplingCP } from 'modules/samplings/components/button-modal-sampling/ButtonModalSamplingCP'

export function ScreenSamplings(): JSX.Element {

    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Campanhas'}>
                    <ButtonModalSamplingCP
                        onSave={() => setShouldLoadData(TableUtils.getReloadNumber())}
                    />
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <TableSamplingsCP
                    shouldLoadData={shouldLoadData}
                />
            }
        />
    )
}
