import { ActionMiddlewareHandlerTP } from 'submodules/nerit-framework-ui/common/redux/types/ReduxTypes'
import { AnyAction, Dispatch, Middleware, Reducer } from 'redux'
import { IAction } from 'submodules/nerit-framework-ui/common/redux/types/IAction'

/**
 * Define metodos uteis para lidar com controle de estado de aplicacao via Redux.
 */
export class ReduxUtils {
	private constructor() {}

	/**
	 * Gera & retorna dinamicamente 01 funcao do tipo 'reducer' que funciona
	 * substituindo o valor de 01 chave do estado da aplicacao pelo que quer
	 * que seja recebido como 'payload' de sua respectiva 'action'.
	 */
	static getPayloadReplacingReducer = <TState>(type: string, initialState: TState | null): Reducer<TState | null> => {
		return (state = initialState, action: IAction): TState | null => (action.type === type ? (action.payload as TState | null) : state)
	}

	/**
	 * Gera & retorna 01 middleware para ciclo de tratamento de actions do redux.
	 * Pode ser executado na ocorrencia de 01 action especifica OU na ocorrencia
	 * de qualquer action.
	 */
	static createMiddleware<PayloadTP = any>(handler: ActionMiddlewareHandlerTP<PayloadTP>, actionType?: string): Middleware {
		return (store) => (next: Dispatch<AnyAction>) => (action: IAction<any>) => {
			const result = next(action)

			if (!actionType || action.type === actionType) handler(action, store.getState())

			return result
		}
	}
}
