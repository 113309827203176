import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import React from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { CardWidgetWrapperCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-wrapper/CardWidgetWrapperCP'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

export function ScreenFieldControlCP(): JSX.Element {

    return (
        <ChartsCP bgImage={`${process.env.PUBLIC_URL}/images/bg-planamostragem.png`}>
            <WidgetsRowCP>
                <WidgetsContentSCP>
                    <CardWidgetWrapperCP
                        className={'card-dashboard-widget'}
                        title={'Gestão de resíduos sólidos'}
                        maxWidth={'33%'}
                        marginTop={5}
                        marginBottom={5}
                        marginLeft={5}
                        marginRight={5}
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.FIELD_CLASSIFICATION)}
                    >
                        <BasicStyleWrapperCP className={'card-content'}>
                            <BasicStyleWrapperCP className={'card-icon'}><img src={`${process.env.PUBLIC_URL}/images/tubo-de-amostra.png`}/></BasicStyleWrapperCP>
                            <BasicStyleWrapperCP className={'card-text'}>Classificação das amostras coletadas em um ponto.</BasicStyleWrapperCP>
                        </BasicStyleWrapperCP>

                    </CardWidgetWrapperCP>

                    <CardWidgetWrapperCP
                        className={'card-dashboard-widget'}
                        title={'Planejamento de Amostragem'}
                        maxWidth={'33%'}
                        marginTop={5}
                        marginBottom={5}
                        marginLeft={5}
                        marginRight={5}
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.SAMPLING_MANAGEMENT)}
                    >
                        <BasicStyleWrapperCP className={'card-content'}>
                            <BasicStyleWrapperCP className={'card-icon'}><img src={`${process.env.PUBLIC_URL}/images/cronograma.png`}/></BasicStyleWrapperCP>
                            <BasicStyleWrapperCP className={'card-text'}>Calendário / cronograma de agendamento de coleta de amostras nos pontos.</BasicStyleWrapperCP>
                        </BasicStyleWrapperCP>

                    </CardWidgetWrapperCP>
                </WidgetsContentSCP>
            </WidgetsRowCP>
        </ChartsCP>
    )
}

const ChartsCP = styled.div<{bgImage: string}>`

    background-image: url(${props => (props.bgImage ? props.bgImage : '')});
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
`

const WidgetsContentSCP = styled.div`

  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  width: 90%;
  
  .card-dashboard-widget {
    flex: 1;
    background: rgba(255,255,255,.4);
    transition: background .5s;
  }

  .card-dashboard-widget:hover {
    background: rgba(255,255,255,.9);
  }

  .card-content {
    display: flex;
  }

  .card-icon {
    flex: 1;
    width: 30%;
    max-width: 100px;

    img {
        width: 100%;
        opacity: .4;
    }
  }

  .card-text {
    flex: 1;
    width: 70%;
    padding-left: 10px;
  }
`
