import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { LocationCorrelationChartFiltersFormModel } from 'modules/reports/components/filters/filter-location-correlation-chart/inner/LocationCorrelationChartFiltersFormModel'
import { FilterLocationCorrelationChartCP } from 'modules/reports/components/filters/filter-location-correlation-chart/FilterLocationCorrelationChartCP'
import { LocationCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/LocationCorrelationChartRequestDTO'
import { LocationCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/LocationCorrelationChartResponseDTO'
import { ChartLocationCorrelationCP } from 'modules/reports/components/charts/chart-location-correlation/ChartLocationCorrelationCP'
import { TableLocationCorrelationChartCP } from 'modules/reports/components/tables/table-location-correlation-chart/TableLocationCorrelationChartCP'

/**
 */
export function ScreenContentChartLocationCorrelation(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<LocationCorrelationChartFiltersFormModel>(new LocationCorrelationChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<LocationCorrelationChartResponseDTO>()

    const [locationCorrelationChartRequestDTO, setLocationCorrelationChartRequestDTO] = useState<LocationCorrelationChartRequestDTO>()
    const getChartRequest = useRequest<LocationCorrelationChartResponseDTO>()
    useEffect(onGetChartRequestChange, [getChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: LocationCorrelationChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new LocationCorrelationChartFiltersFormModel({
                fieldCode: queryFilters?.fieldCode,
                locationCodes: queryFilters?.locationCodes,
                dateRange: queryFilters?.dateRange,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        if (formValues.locationCodes.length !== 2) {
            NotificationHelper.error('Ops', 'Selecione APENAS 2 pontos')
            return
        }

        const filters: LocationCorrelationChartRequestDTO = {
            fieldCode: formValues.fieldCode,
            dateRange: formValues.dateRange,
            locationCodes: formValues.locationCodes,
        }
        getChartRequest.runRequest(ChartsRequests.getLocationCorrelationChart(filters))
        setLoadedData(undefined)
        setLocationCorrelationChartRequestDTO(filters)
    }

    /**
     */
    function onGetChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Correlação de Pontos'}/>
            }
            content={
                <>
                    <FilterLocationCorrelationChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!locationCorrelationChartRequestDTO &&
                                <>
                                    <ChartLocationCorrelationCP data={loadedData}/>
                                    <TableLocationCorrelationChartCP
                                        data={loadedData}
                                        filters={locationCorrelationChartRequestDTO}
                                    />
                                </>
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
