import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DataAnalysisFiltersFormModel } from 'modules/locations/components/filters-data-analisys/inner/DataAnalysisFiltersFormModel'
import { WrapperSwitchFilterCP } from 'submodules/nerit-framework-ui/features/common/components/filters/wrapper-switch-filter/WrapperSwitchFilterCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DataAnalysisRequests } from 'submodules/nerit-aquatec-sdk/services/data-analysis/DataAnalysisRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DataAnalysisSamplingDataResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/DataAnalysisSamplingDataResponseDTO'
import { WrapperFiltersCP } from 'submodules/nerit-framework-ui/features/common/components/filters/wrapper-filters/WrapperFiltersCP'

interface ICPProps {
    formStateManager: IFormStateManager<DataAnalysisFiltersFormModel>
    onFilter: () => void
    isLoading?: boolean
}

/**
 */
export function FiltersDataAnalisysCP(props: ICPProps): JSX.Element {

    const getSamplingDataRequest = useRequest<DataAnalysisSamplingDataResponseDTO>()
    useEffect(onGetSamplingDataRequestChange, [getSamplingDataRequest.isAwaiting])

    useEffect(onSelectSampling, props.formStateManager.getFieldValue('samplingCode'))

    /**
     */
    function onSelectSampling(): void {

        const samplingCode = props.formStateManager.getFieldValue('samplingCode')
        if (!samplingCode)
            return

        getSamplingDataRequest.runRequest(DataAnalysisRequests.getSamplingData(samplingCode))
    }

    /**
     */
    function onGetSamplingDataRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getSamplingDataRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const result = getSamplingDataRequest.responseData!
        props.formStateManager.changeFieldValue('locationCodes', result.locations.map((location) => location.code))
        props.formStateManager.changeFieldValue('dateRange', result.dateRange)
    }

    return (
        <WrapperFiltersCP
            filters={
                <RowCP>
                    <ColumnCP size={7}>
                        <SelectLocationCP
                            isMany={true}
                            formStateManager={props.formStateManager}
                            fieldName={'locationCodes'}
                            isRequired={true}
                            maxTagCount={1}
                        />
                    </ColumnCP>
                    <ColumnCP size={4}>
                        <SelectFieldRegulationCP
                            isRequired={true}
                            formStateManager={props.formStateManager}
                            fieldName={'fieldRegulationCode'}
                        />
                    </ColumnCP>
                    <ColumnCP size={5}>
                        <SelectFieldCP
                            formStateManager={props.formStateManager}
                            fieldName={'fieldCodes'}
                            isMany={true}
                            maxTagCount={1}
                        />
                    </ColumnCP>
                    <ColumnCP size={4}>
                        <DateRangePickerCP
                            showBorder={true}
                            marginTop={23}
                            fastFilter={'all'}
                            fieldName={'dateRange'}
                            formStateManager={props.formStateManager}
                        />
                    </ColumnCP>
                    <ColumnCP size={3}>
                        <WrapperSwitchFilterCP>
                            <SwitchCP
                                label={'Somente fora da norma?'}
                                labelAbove={true}
                                formStateManager={props.formStateManager}
                                fieldName={'forceOutsideRegulation'}
                            />
                        </WrapperSwitchFilterCP>
                    </ColumnCP>
                </RowCP>
            }
            filterButton={
                <ButtonCP
                    icon={'search'}
                    type={'primary'}
                    onClick={props.onFilter}
                    loading={props.isLoading}
                />
            }
        />
    )
}
