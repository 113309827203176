/**
 * ENUM
 * Strings que representam 'rapidamente' valores genericos de data.
 */
export enum QuickDateEnum {
	TODAY = 'today',
	TOMORROW = 'tomorrow',
	CURRENT_WEEK = 'current-week',
	CURRENT_MONTH = 'current-month',
}
