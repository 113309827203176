import { OrNullishTP } from 'submodules/nerit-framework-utils/utils/types/OrNullishTP'
import { OrFunctionTP } from 'submodules/nerit-framework-utils/utils/types/OrFunctionTP'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { TerminalLogTP } from 'submodules/nerit-framework-utils/utils/interfaces/TerminalLogTP'
import * as moment from 'moment/moment'

/**
 * UTILS
 * Funcoes utilitarias para uso geral.
 */
export const SystemUtils = {
	performance(type: 'start' | 'finish', timeInit?: Date): Date {
		const final = new Date()
		if (type === 'start') console.log('\n\n')

		console.log(`----- > PERFORMANCE: ${type} - ${new Date()}`)

		if (!!timeInit) {
			const diff = +moment(final).diff(moment(timeInit), 'ms')
			console.log(`----- > TEMPO: ${diff / 1000}s`)
		}

		if (type === 'finish') console.log('\n\n')

		return final
	},

	async sleep(time: number): Promise<void> {
		await new Promise((resolve) => setTimeout(() => resolve(undefined), time))
	},

	debug(...debugItems: any[]): void {
		console.log('\n', ...debugItems, '\n') // eslint-disable-line no-console
	},

	/**
	 * Retorna 01 determinado valor se 01 determinada condicao for verdadeira. Caso contrario retorna
	 * 01 valor padrao (que pode ser indefinido).
	 */
	nvl<ValueTP = any>(condition: boolean, value: OrNullishTP<OrFunctionTP<ValueTP>>, defaultValue?: ValueTP): OrUndefinedTP<ValueTP> {
		let valueToReturn: any
		if (condition) valueToReturn = typeof value === 'function' ? (value as Function)() : value
		return valueToReturn ?? defaultValue
	},

	mountTerminalLog(config: TerminalLogTP): string {
		let extra: string = ''

		if (!!config.extra) {
			config.extra.forEach((extraConfig) => {
				extra = `${extra}\n${extraConfig.title}\t${extraConfig.text}`
			})
		}

		return `
*******************************
                  _ _
                 (_) |
  _ __   ___ _ __ _| |_
 | '_ \\ / _ \\ '__| | __|
 | | | |  __/ |  | | |_
 |_| |_|\\___|_|  |_|\\__|


API Rodando: ${config.serverName}

ambiente:\t${config.env}
porta app:\t${config.port}

${extra}

*******************************
        `
	},
}
