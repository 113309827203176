import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { FieldClassificationTableCP } from 'modules/fields/components/field-classification/field-classification-table/FieldClassificationTableCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { FilterFieldManagementCP } from 'modules/fields/components/field-management/FilterFieldManagementCP'
import { ModalAddFieldClassificationCP } from 'modules/fields/components/field-classification/modal/ModalFieldClassificationCP'

export function ScreenFieldClassification(): JSX.Element {

    const [isNewFieldClassificationVisible, setIsNewFieldClassificationVisible] = useState<boolean>(false)
    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())
    const [locationCode, setLocationCode] = useState<number>()
    const [dateRange, setDateRange] = useState<IDateRangeFilter>()

    function setFilters(locCode?: number, dateInterval?: IDateRangeFilter): void {
        setLocationCode(locCode)
        setDateRange(dateInterval)
        setShouldLoadData(TableUtils.getReloadNumber())
    }

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Gestão de Resíduos Sólidos'}>
                    <ButtonCP
                        onClick={() => setIsNewFieldClassificationVisible(true)}
                    >
                        Nova Classificação
                    </ButtonCP>
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <FilterFieldManagementCP
                        onChangeDateRange={(dr: IDateRangeFilter) => setDateRange(dr)}
                        onChangeLocation={(code: number) => setLocationCode(code)}
                        onChange={(locCode?: number, dateInterval?: IDateRangeFilter) => setFilters(locCode, dateInterval)}
                    />

                    <FieldClassificationTableCP
                        filters={{
                            locationCode,
                            dateRange
                        }}
                        shouldLoadData={shouldLoadData}
                    />

                    <ModalAddFieldClassificationCP
                        visible={isNewFieldClassificationVisible}
                        onCancel={() => setIsNewFieldClassificationVisible(false)}
                        onSave={() => {
                            setIsNewFieldClassificationVisible(false)
                            setShouldLoadData(TableUtils.getReloadNumber())
                        }}
                    />
                </>
            }
        />
    )
}
