import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import React, { useState } from 'react'
import { LocationRequests } from 'submodules/nerit-aquatec-sdk/services/location/LocationRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AddLocationCP } from 'modules/locations/components/AddLocationCP'

interface IAddFieldClassificationModalCPProps {
    visible: boolean
    onCancel: () => void
    onSave: () => void
}

/**
 * Adicionar usuario em uma clinica.
 */
export function ModalSamplingManagementCP(props: IAddFieldClassificationModalCPProps): JSX.Element {

    const doLinkRequest = useRequest<void>('none')

    const [locationCode, setLocationCode] = useState<number>()
    const [regulationCode, setRegulationCode] = useState<number>()
    const [nature, setNature] = useState<string>()
    const [frequency, setFrequency] = useState<string>()
    const [group, setGroup] = useState<string>()
    const [dateRange, setDateRange] = useState<IDateRangeFilter>()
    const [shouldCreateLocation, setShouldCreateLocation] = useState<boolean>(false)
    const [name, setName] = useState<string>()
    const [latitude, setLatitude] = useState<string>()
    const [longitude, setLongitude] = useState<string>()
    const [altitude, setAltitude] = useState<string>()

    const postLocationPlaning = useRequest<any>()

    function init(): void {
        postLocationPlaning.runRequest(LocationRequests.postLocationPlaning({
            locationCode,
            regulationCode,
            nature,
            frequency,
            group,
            dateRange,
            name,
            latitude,
            longitude,
            altitude
        }))

        setLocationCode(undefined)
        setRegulationCode(undefined)
        setNature(undefined)
        setFrequency(undefined)
        setGroup(undefined)
        setDateRange(undefined)
        setShouldCreateLocation(false)
        setName(undefined)
        setLatitude(undefined)
        setLongitude(undefined)
        setAltitude(undefined)

        props.onSave()
    }

    function toogleShouldCreateLocation(): void {
        setLocationCode(undefined)
        setShouldCreateLocation(!shouldCreateLocation)
    }

    function onAddLocationChange(key: string, value: any): void {

        switch (key) {
            case 'name':
                setName(value)
                break
            case 'altitude':
                setAltitude(value)
                break
            case 'longitude':
                setLongitude(value)
                break
            case 'latitude':
                setLatitude(value)
                break
            default:
        }
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={doLinkRequest.isAwaiting}
            noFooter={true}
            onCancel={props.onCancel}
            title={'Nova classificação de amostra'}
            width={600}
        >
            <ButtonCP
                marginTop={25}
                icon={'act'}
                type={shouldCreateLocation ? 'dashed' : 'default'}
                disabled={false}
                onClick={() => toogleShouldCreateLocation()}
            >
                { !shouldCreateLocation ? 'Inserir novo ponto' : 'Selecionar ponto existente' }
            </ButtonCP>

            {
                shouldCreateLocation &&
                <AddLocationCP onSave={(key, value) => onAddLocationChange(key, value)}/>
            }

            {
                !shouldCreateLocation &&
                <SelectLocationCP
                    locationCode={locationCode}
                    isRequired={false}
                    onChange={(locCode) => {
                        setLocationCode(locCode)
                    }}
                />
            }
            <SelectCP
                label={'Natureza'}
                options={[
                    'Água Superficial',
                    'Água Subterrânea',
                    'Água Potável',
                    'Efluente',
                    'Processo',
                    'Solo',
                    'Atmosfera',
                    'Sedimentos',
                    'Resíduos Sólidos'
                ]}
                onChange={(ntr) => {
                    setNature(ntr)
                }}
            />

            <SelectFieldRegulationCP
                fieldRegulationCode={regulationCode}
                isRequired={true}
                onChange={(frCode: number) => {
                    setRegulationCode(frCode)
                }}
            />

            <SelectCP
                label={'Frequência'}
                options={[
                    'Mensal',
                    'Bimestral',
                    'Trimestral',
                    'Semestral',
                    'Anual'
                ]}
                onChange={(act) => setFrequency(act)}
            />

            <InputCP
                label={'Grupo'}
                value={group}
                onChange={(gp) => setGroup(gp)}
            />

            <DateRangePickerCP
                showBorder={true}
                marginTop={23}
                fastFilter={'all'}
                fieldName={'dateRange'}
                onChange={(dr) => setDateRange(dr)}
            />

            <ButtonCP
                marginTop={25}
                icon={'save'}
                type={'primary'}
                disabled={false}
                onClick={init}
            >
                Salvar Planejamento
            </ButtonCP>

        </ModalCP>
    )
}
