import React from 'react'
import styled from 'styled-components'
import { LocationRequests } from 'submodules/nerit-aquatec-sdk/services/location/LocationRequests'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { GetFieldClassificationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/GetFieldClassificationRequestDTO'

interface ICPProps {
    filters: GetFieldClassificationRequestDTO
    shouldLoadData: number
}

export function LocationPlaningTableCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TableFromApiCP<any>
                apiConfig={{
                    hasPagination: true,
                    pageSize: 20,
                    requestConfigTP: () => LocationRequests.getLocationPlaning(props.filters)
                }}
                appearance={{
                    showReloadButton: true,
                    wrappedOnCard: true,
                    showTotalOnHeader: true
                }}
                shouldLoadData={props.shouldLoadData}
                columns={[
                    {
                        title: '#',
                        render: (text, record) => record.code
                    },
                    {
                        title: 'Ponto',
                        render: (text, record) => record.locationName
                    },
                    {
                        title: 'Natureza',
                        render: (text, record) => record.nature
                    },
                    {
                        title: 'Norma',
                        render: (text, record) => record.regulationName
                    },
                    {
                        title: 'Frequência',
                        render: (text, record) => record.frequency
                    },
                    {
                        title: 'Data de Início',
                        render: (text, record) => DateUtils.formatDate(record.beginDate, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Data de Término',
                        render: (text, record) => DateUtils.formatDate(record.endDate, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Grupo',
                        render: (text, record) => record.group
                    }
                ]}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
