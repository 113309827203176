import React from 'react'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SamplingRequests } from 'submodules/nerit-aquatec-sdk/services/samplings/SamplingRequests'

interface ICPProps {
    isMany?: boolean
    isRequired?: boolean
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    width?: number
}

/**
 */
export function SelectSamplingsCP(props: ICPProps): JSX.Element {

    return (
        <AutocompletePickerCP
            label={`Campanha${props.isMany ? '(s)' : ''}`}
            requestConfigGetter={SamplingRequests.names}
            showOptionsOnLoad={true}
            required={props.isRequired}
            isMultiple={props.isMany}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            filterOption={true}
            width={props.width}
        />
    )
}
