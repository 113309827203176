import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { NumberAnimatedCP } from 'submodules-third-party/components/number-animated/NumberAnimatedCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { DashboardRequests } from 'submodules/nerit-aquatec-sdk/services/dashboard/DashboardRequests'
import { CountDashboardTypeEnum } from 'submodules/nerit-aquatec-sdk/services/dashboard/enums/CountDashboardTypeEnum'

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ScreenHome(): JSX.Element {

    return (
        <LayoutCP
            titleBrowser={'Página Inicial'}
            header={
                <HeaderCP title={'Página Inicial'}/>
            }
            content={
                <ContentCP overflowVertical={true}>

                    <FlexCP justifyContent={'flex-end'} margin={{ right: 20, top: 20 }}>
                        <TextCP text={`Bem-vindo, ${StringUtils.getFirstName(AppStateUtils.getLoggedUser()?.name ?? '')}!`}/>
                    </FlexCP>

                    <WidgetsRowCP margin={{ top: 50, bottom: 30 }}>
                        <CardWidgetOnlyValuesCP
                            onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.SAMPLINGS)}
                            requestConfigGetter={DashboardRequests.getCounts({ type: CountDashboardTypeEnum.SAMPLINGS })}
                            main={{
                                title: 'Amostras Analisadas',
                                onRenderValue: (value) => <NumberAnimatedCP value={value}/>
                            }}
                        />
                        <CardWidgetOnlyValuesCP
                            onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.FIELD_CONFIG)}
                            requestConfigGetter={DashboardRequests.getCounts({ type: CountDashboardTypeEnum.FIELDS })}
                            main={{
                                title: 'Parâmetros Analisados',
                                onRenderValue: (value) => <NumberAnimatedCP value={value}/>
                            }}
                        />
                        <CardWidgetOnlyValuesCP
                            onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.LOCATIONS)}
                            requestConfigGetter={DashboardRequests.getCounts({ type: CountDashboardTypeEnum.LOCATIONS })}
                            main={{
                                title: 'Pontos Analisados',
                                onRenderValue: (value) => <NumberAnimatedCP value={value}/>
                            }}
                        />
                    </WidgetsRowCP>
                </ContentCP>
            }
        />
    )
}

