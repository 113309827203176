/**
 * ENUM
 * Define bases de tempo.
 *
 * NOTE: Manter valores em lowercase para compatibilidade com moment.js!
 */
export enum TimeBaseEnum {
	SECOND = 'second',
	MINUTE = 'minute',
	HOUR = 'hour',
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year',
}
