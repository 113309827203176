import React from 'react'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ScreenPrivateUser } from 'app/screens/screen-private-user/ScreenPrivateUser'

/**
 * Componente verificador se esta logado, pois se caiu aqui esta chamando rota privada.
 */
export function PrivateAccessVerifierCP(): JSX.Element {

    const loggedUserData = AppStateUtils.getLoggedUser()

    if (!loggedUserData)
        return <RedirectCP path={PublicRouter.LOGIN}/>

    // Se estiver logado, inclui a tela do sistema e nela tera o roteador das rotas
    return <ScreenPrivateUser/>
}
