import { ChartTypeTP } from 'modules/reports/components/chart-actions/ChartActionsCP'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { ChartOptions, YAxisPlotLinesOptions } from 'highcharts'
import * as _ from 'lodash'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 */
export class HighChartsUtils {

    static getChart(chartType: ChartTypeTP): ChartOptions {

        return {
            // width: '97%',
            height: 350,
            type: chartType
            // zoom: {
            //     enabled: true,
            //     type: 'xy'
            // },
            // toolbar: {
            //     show: true,
            //     tools: {
            //         selection: false,
            //         pan: false,
            //         reset: false,
            //         zoom: false,
            //         zoomin: false,
            //         zoomout: false,
            //     }
            // }
        }
    }

    /**
     */
    static getMinMaxLines(minPermitted?: number, maxPermitted?: number): YAxisPlotLinesOptions[] {

        const commonAttrs: YAxisPlotLinesOptions = {
            value: 6, // Valor máximo
            color: 'red', // Cor da linha
            width: 2, // Largura da linha
            dashStyle: 'LongDash', // Estilo da linha (pontilhada, contínua, etc.)
            label: {
                style: {
                    color: 'red', // Cor do texto
                    textAlign: 'right' // Alinhamento do texto
                },
                align: 'right', // Alinha o texto à direita
                x: -5 // Move o texto para a direita
            }
        }

        const result: YAxisPlotLinesOptions[] = []

        if (!!minPermitted) {
            commonAttrs.value = minPermitted
            commonAttrs.label!.text = `Valor Min: ${minPermitted}`
            result.push(_.cloneDeep(commonAttrs))
        }

        if (!!maxPermitted) {
            commonAttrs.value = maxPermitted
            commonAttrs.label!.text = `Valor Máx: ${maxPermitted}`
            result.push(_.cloneDeep(commonAttrs))
        }

        return result
    }

    static getGrid(): ApexGrid {
        return {
            xaxis: {
                lines: {show: true}
            },
            yaxis: {
                lines: {show: true,},
            },
        }
    }

    static getYMaxOrMin(data: GenericChartResponseDTO, type: 'max' | 'min'): number {

        let result = 0
        if (!!data.regulationLimit)
            result = type === 'max' ? data.regulationLimit.maxPermitted : data.regulationLimit?.minPermitted

        data.series.forEach((serie) => {
            serie.dataArray.forEach((data) => {
                if (type === 'max' && +data.y > result)
                    result = +data.y
                else if (type === 'min' && +data.y < result)
                    result = +data.y
            })
        })

        return +result
    }

    static isAboveOrBelowLimit(data: GenericChartResponseDTO, value: number): boolean {

        if (!data.regulationLimit)
            return false

        if (value > data.regulationLimit.maxPermitted)
            return true

        if (value < data.regulationLimit.minPermitted)
            return true

        return false
    }

    static getMinOrMaxPermittedSerie(type: 'min' | 'max', totalXData: number, minOrMaxValue?: number): any {

        if (!minOrMaxValue)
            return

        const values: number[] = []
        for (let i = 0; i < totalXData; i++) {
            values.push(+minOrMaxValue)
        }

        return {
            name: `Valor ${type === 'min' ? 'mínimo' : 'máximo'} permitido`,
            data: values,
            color: ThemeProject.error,
            type: 'line'
        }
    }
}
