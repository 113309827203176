import { NESWBounds } from 'google-map-react'
import { MapPinTP } from 'modules/map/components/map-pin/inner/MapPinTP'
import { CoordinatesEnum } from '../../../enums/CoordinatesEnum'

/**
 * UTILITARIOS para componente de mapa.
 * @see MapCP
 */
export class MapCpUtils {

    private static readonly _DEFAULT_LAT_RANGE = 3  // Faixa de latitudes padrao a ser exibida quando houver apenas 01 ponto
    private static readonly _DEFAULT_LON_RANGE = 6  // Faixa de longitudes padrao a ser exibida quando houver apenas 01 ponto

    static getBounds(pins: MapPinTP[]): NESWBounds {

        const maxLongitude = MapCpUtils._getBound('max', 'lng', pins)
        const minLongitude = MapCpUtils._getBound('min', 'lng', pins)
        const maxLatitude = MapCpUtils._getBound('max', 'lat', pins)
        const minLatitude = MapCpUtils._getBound('min', 'lat', pins)

        return {
            ne: { lat: maxLatitude, lng: maxLongitude },    // nordeste
            nw: { lat: maxLatitude, lng: minLongitude },    // noroeste
            se: { lat: minLatitude, lng: maxLongitude },    // sudeste
            sw: { lat: minLatitude, lng: minLongitude },    // sudoeste
        }
    }

    private static _getBound(limit: 'max' | 'min', coordinate: 'lat' | 'lng', pins: MapPinTP[]): number {

        const isMaxLat = (limit === 'max' && coordinate === 'lat')
        const isMinLat = (limit === 'min' && coordinate === 'lat')
        const isMaxLon = (limit === 'max' && coordinate === 'lng')
        const isMinLon = (limit === 'min' && coordinate === 'lng')

        // Por padrao retorna extremidades do territorio brasileiro (se nao houver pontos)
        if (!pins.length) {
            if (isMaxLat)
                return CoordinatesEnum.BR_MAX_LAT
            if (isMinLat)
                return CoordinatesEnum.BR_MIN_LAT
            if (isMaxLon)
                return CoordinatesEnum.BR_MAX_LON
            if (isMinLon)
                return CoordinatesEnum.BR_MIN_LON
        }

        // Quando houver apenas 01 ponto, exibir area de tamanho parametrizada ao redor dele
        if (pins.length === 1) {

            // Latitude
            const pinLatitude = pins[0].lat
            const halfLatRange = (MapCpUtils._DEFAULT_LAT_RANGE / 2)

            if (isMaxLat)
                return (pinLatitude + halfLatRange)
            if (isMinLat)
                return (pinLatitude - halfLatRange)

            // Longitude
            const pinLongitude = pins[0].lng
            const halfLonRange = (MapCpUtils._DEFAULT_LON_RANGE / 2)

            if (isMaxLon)
                return (pinLongitude + halfLonRange)
            if (isMinLon)
                return (pinLongitude - halfLonRange)
        }

        // Calcula coordenada limite
        const mathFunction = (limit === 'max') ? Math.max : Math.min
        const bound = pins.reduce<number>((acc, pin) => mathFunction(acc, pin[coordinate]), pins[0][coordinate])

        // Garante resultado limitado ao territorio brasileiro
        if (coordinate === 'lat') {
            if (bound < CoordinatesEnum.BR_MIN_LAT)
                return CoordinatesEnum.BR_MIN_LAT
            if (bound > CoordinatesEnum.BR_MAX_LAT)
                return CoordinatesEnum.BR_MAX_LAT

        } else {
            if (bound < CoordinatesEnum.BR_MIN_LON)
                return CoordinatesEnum.BR_MIN_LON
            if (bound > CoordinatesEnum.BR_MAX_LON)
                return CoordinatesEnum.BR_MAX_LON
        }

        return bound
    }
}
