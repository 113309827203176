import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { UserMenuUtils } from 'app/components/user-main-menu/inner/UserMenuUtils'
import { AuthActions } from 'app/utils/AuthActions'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import React from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { MenuUserMainCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-user-main/MenuUserMainCP'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { LogoMenuCP } from 'submodules/nerit-framework-ui/common/components/menu/logo-menu/LogoMenuCP'
import { ButtonSwitchThemeCP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/ButtonSwitchTheme'

interface ICPProps {
    onSelectMenuItem: () => void
}

/**
 * Sider do sistema para usuários da clínica
 */
export function UserMainMenuCP(props: ICPProps): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUser()!

    return (
        <MenuUserMainCP
            onSelectMenuItem={props.onSelectMenuItem}
            menuTop={{
                topContent: (
                    <>
                         <LogoMenuCP/>
                        <MenuDividerSCP/>
                    </>
                ),
                options: UserMenuUtils.getMenuOptionsTop(),
            }}
            menuBottom={{
                options: UserMenuUtils.getMenuOptionsBottom(),
                bottomContent: (
                    <>
                        <MenuDividerSCP/>
                        <ButtonSwitchThemeCP />
                        <ProfileMenuItemCP
                            optionTitle={loggedUser.name}
                            profileOptions={[
                                {
                                    text: 'Meu Perfil',
                                    action: () => NeritFrameworkRoutingHelper.historyPush(PrivateRouter.PROFILE)
                                },
                                {
                                    text: 'Sair',
                                    action: () => {
                                        AuthActions.logout()
                                        NeritFrameworkRoutingHelper.historyPush(PublicRouter.LOGIN)
                                    }
                                }
                            ]}
                        />
                    </>
                )
            }}
        />
    )
}

const MenuDividerSCP = styled.div`
    width: 67px;
    height: 2px;
    background: rgba(255,255,255,0.5);
    margin-bottom: 15px;
`
