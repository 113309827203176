import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { SamplingSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/requests/SamplingSaveRequestDTO'
import { SamplingResponseDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/responses/SamplingResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class SamplingRequests {

	public static CONTROLLER_ROOT = 'samplings'
	public static LIST_EP = 'get-list'
	public static CREATE_EP = 'create'
    public static UPDATE_EP = 'update/:code'
    public static DELETE_EP = 'delete/:code'
    public static NAMES_EP = 'names'

	static getList = (): RequestConfigTP<ISearchResult<SamplingResponseDTO>> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${SamplingRequests.CONTROLLER_ROOT}/${SamplingRequests.LIST_EP}`,
		method: HttpMethodEnum.GET,
	})

	static create = (dto: SamplingSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${SamplingRequests.CONTROLLER_ROOT}/${SamplingRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static update = (code: number, dto: SamplingSaveRequestDTO): RequestConfigTP<void> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${SamplingRequests.CONTROLLER_ROOT}/${SamplingRequests.UPDATE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

	static deleteSampling = (code: number): RequestConfigTP<void> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${SamplingRequests.CONTROLLER_ROOT}/${SamplingRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE
	})

	static names = (dto: any): RequestConfigTP<NameAndCodeResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${SamplingRequests.CONTROLLER_ROOT}/${SamplingRequests.NAMES_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })
}
