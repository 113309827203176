export enum IQAEnum {
    EXCELENT = 'EXCELENT',
    GOOD = 'GOOD',
    MEDIUM = 'MEDIUM',
    BAD = 'BAD',
    VERY_BAD = 'VERY_BAD',
}

export enum IQALabelEnum {
    EXCELENT = 'Excelente',
    GOOD = 'Bom',
    MEDIUM = 'Médio',
    BAD = 'Ruim',
    VERY_BAD = 'Muito Ruim',
}
