import { Reducer, useEffect, useReducer, useState } from 'react'
import { AxiosResponse } from 'axios'
import * as _ from 'lodash'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { RequestStateTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestStateTP'
import { UseRequestActionTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/UseRequestActionTP'
import { UseRequestIdTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/UseRequestIdTP'
import { UseRequestUtils } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequestUtils'
import { RequestTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestTP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

const _responseWrapperAux = { 'api-return': undefined, none: undefined }

type _DebugModeTP = 'disabled' | 'by-id' | 'all'
type _ResponseWrapperTP = keyof typeof _responseWrapperAux
type _RequestIdReducerTP = Reducer<UseRequestIdTP, void>
type _RequestStateReducerTP<ResDataTP> = Reducer<RequestStateTP<ResDataTP>, UseRequestActionTP<ResDataTP>>

const DEBUG_MODE: _DebugModeTP = 'disabled'
const RESPONSE_WRAPPERS = Object.keys(_responseWrapperAux) as _ResponseWrapperTP[]

const ERR_INVALID_WRAPPER = 'Parametrizcao invalida (wrapper)'
const ERR_INVALID_CONFIG = 'Parametrizcao invalida (config)'
const ERR_NO_API_RETURN = 'Retorno vazio ou mal formatado (api return)'

export function useRequest<ResDataTP>(initialConfig?: RequestConfigTP, responseWrapper?: _ResponseWrapperTP, debugId?: string): RequestTP<ResDataTP> // Formato de chamada 01
export function useRequest<ResDataTP>(responseWrapper: _ResponseWrapperTP, debugId?: string): RequestTP<ResDataTP> // Formato de chamada 02

/**
 * Encapsula gestao de 01 requisicao HTTP generica:
 * Pensado prioritariamente para tratar requisicoes a api(s) nerit.
 */
export function useRequest<ResDataTP>(
	param1?: RequestConfigTP | _ResponseWrapperTP,
	param2?: _ResponseWrapperTP | string,
	param3?: string,
): RequestTP<ResDataTP> {
	const [id, incrementCancellationsCount] = useReducer<_RequestIdReducerTP>(UseRequestUtils.requestIdReducer, UseRequestUtils.getInitialId())
	const [requestState, dispatch] = useReducer<_RequestStateReducerTP<ResDataTP>>(UseRequestUtils.requestStateReducer, UseRequestUtils.INITIAL_STATE)

	const [mustRun, setMustRun] = useState<boolean>(false)
	const [requestConfig, setRequestConfig] = useState<RequestConfigTP>()
	const [responseWrapper, setResponseWrapper] = useState<_ResponseWrapperTP>()

	useEffect(onRunningStateChange, [mustRun, requestState.isAwaiting])

	const debugCustomId = !!param2 && !(RESPONSE_WRAPPERS as string[]).includes(param2 ?? '') ? param2 : param3
	const enableDebug = DEBUG_MODE === 'all' || (DEBUG_MODE === 'by-id' && !!debugCustomId)

	function onRunningStateChange(): void {
		if (enableDebug) UseRequestUtils.debugComputeCalling(onRunningStateChange.name, id, debugCustomId)

		if (!mustRun) return

		if (requestState.isAwaiting) cancelRequest()
		else runRequest()
	}

	function askForNewExecution(config?: RequestConfigTP): void {
		// Validar config da requisicao
		const initialConfig = typeof param1 === 'object' ? param1 : {}
		config = Object.assign(initialConfig, config)
		if (!config?.url || !config?.method) throw ERR_INVALID_CONFIG

		// Validar wrapper de retorno
		const wrapper = !!param1 && typeof param1 !== 'object' ? param1 : param2
		if (!!wrapper && !(RESPONSE_WRAPPERS as string[]).includes(wrapper)) throw ERR_INVALID_WRAPPER

		// 'Encomendar' 01 nova execucao
		if (enableDebug) UseRequestUtils.debugComputeCalling(askForNewExecution.name, id, debugCustomId)

		setRequestConfig(config)
		setResponseWrapper((wrapper as _ResponseWrapperTP) ?? 'api-return')
		setMustRun(true)
	}

	async function runRequest(): Promise<void> {
		if (!requestConfig || !responseWrapper) return

		onWillStart()

		if (enableDebug) UseRequestUtils.debugComputeCalling(runRequest.name, id, debugCustomId)

		try {
			const response = await RequestHelper.runRequest(requestConfig, UseRequestUtils.getIdString(id))
			UseRequestUtils.validateResponse(response)
			onIsSuccess(response)
		} catch (error) {
			if (error === RequestHelper.CANCELLED_RESPONSE) onIsCancelled()
			else onIsFailure(error)
		}
	}

	function cancelRequest(): void {
		if (enableDebug) UseRequestUtils.debugComputeCalling(cancelRequest.name, id, debugCustomId)
		RequestHelper.cancelRequest(UseRequestUtils.getIdString(id))
	}

	function onIsSuccess(response: AxiosResponse): void {
		if (enableDebug) UseRequestUtils.debugComputeCalling(onIsSuccess.name, id, debugCustomId)

		onWillFinish({
			isSuccess: true,
			responseStatus: response.status,
			responseData: getResponseData(response),
			responseType: UseRequestUtils.getResponseContentType(response.headers),
		})
	}

	function onIsFailure(error: any): void {
		if (enableDebug) UseRequestUtils.debugComputeCalling(onIsFailure.name, id, debugCustomId)

		onWillFinish({
			responseStatus: error?.status,
			responseType: UseRequestUtils.getResponseContentType(error?.headers ?? {}),
			error: error?.data ?? error,
		})
	}

	function onIsCancelled(): void {
		if (enableDebug) UseRequestUtils.debugComputeCalling(onIsCancelled.name, id, debugCustomId)
		incrementCancellationsCount()
		onWillFinish({ isCancelled: true })
	}

	function onWillStart(): void {
		if (enableDebug) UseRequestUtils.debugStart(id, debugCustomId)
		dispatch({ type: 'start' })
		setMustRun(false)
	}

	function onWillFinish(finalState: Partial<RequestStateTP<ResDataTP>>): void {
		if (enableDebug) {
			UseRequestUtils.debugComputeCalling(onWillFinish.name, id, debugCustomId)
			UseRequestUtils.debugEnd(id, debugCustomId)
		}

		const isSuccess = !!finalState.isSuccess && !finalState.isCancelled

		dispatch({
			type: 'finish',
			payload: {
				...finalState,
				isSuccess,
				responseData: SystemUtils.nvl(isSuccess, finalState.responseData),
				error: SystemUtils.nvl(!isSuccess, finalState.error),
			},
		})
	}

	function getResponseData(response: AxiosResponse): ResDataTP {
		const responseDataReceived = response.data
		if (responseWrapper === 'none') return responseDataReceived

		if (responseWrapper === 'api-return') {
			const responseData = (responseDataReceived as IApiReturn)?.data
			if (!!responseData) return responseData

			throw ERR_NO_API_RETURN
		}

		throw ERR_INVALID_CONFIG
	}

	return {
		runRequest: _.debounce(askForNewExecution, 100),
		cancelRequest,
		...requestState,
	}
}
