export enum DayPeriodEnum {
	ALL = 'ALL',
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON',
	NIGHT = 'NIGHT',
}

export enum DayPeriodLabelEnum {
	ALL = 'Todos',
	MORNING = 'Manhã',
	AFTERNOON = 'Tarde',
	NIGHT = 'Noite',
}
