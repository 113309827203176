import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * Definicao de parametros de configuracao globais do app.
 */
export class AppConfig {

    // readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum
    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum
    readonly reduxEncryptKey = 'bmVyaXRzZvvyZX1rZXk='
    readonly googleMapApiKey = 'AIzaSyBm-NgJxLqJKIoXpMPc2LkM7g0dva8mTnk'

    readonly uiBaseUrl: string
    readonly apiBaseUrl: string

    readonly anonymousUserToken: string

    /** Instancia unica da classe. */
    private static instance: AppConfig

    private constructor() {

        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://localhost:3001'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.vVV6TbAGvXeH4Mo-IG0fIJ3myLmKOk023HF95kisKKc'
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://app.sistemaquatec.com.br'
                this.apiBaseUrl = 'https://api.sistemaquatec.com.br'
                break

            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    /** Gera & retorna instancia unica da classe. */
    static getInstance(): AppConfig {
        if (!this.instance)
            this.instance = new AppConfig()
        return this.instance
    }
}
