import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	leftContent?: React.ReactNode
	showReloadButton?: boolean
	wrappedOnCard?: boolean
	size?: ButtonSizeTP
	loading: boolean
	onClick: () => void
	totalOnHeader?: {
		show: boolean
		total?: number
		recordLabel?: string
	}
}

/**
 * Tabela padrao buscada na api.
 */
export function TableTopBarICP(props: ICPProps): JSX.Element {
	let justifyContent: 'center' | 'flex-end' | 'space-between' | 'flex-start' | undefined
	// Define alinhamento da barra
	if (props.showReloadButton && !!props.leftContent) justifyContent = 'space-between'
	else if (props.showReloadButton && !props.leftContent) justifyContent = 'flex-end'
	else if (!props.showReloadButton && !!props.leftContent) justifyContent = 'flex-start'

	if (!props.showReloadButton && !props.leftContent && !props.totalOnHeader?.show) return <></>

	return (
		<FlexCP
			justifyContent={justifyContent}
			alignItems={'center'}
			margin={{
				right: props.wrappedOnCard ? 20 : 0,
				left: props.wrappedOnCard ? 20 : 0,
				top: props.wrappedOnCard ? 20 : 0,
				bottom: !props.wrappedOnCard ? 10 : 0,
			}}
		>
			{props.leftContent}

			<FlexCP alignItems={'center'}>
				{props.totalOnHeader?.show && (
					<TextCP
						text={`${props.totalOnHeader.total !== undefined ? MaskUtils.applyNumberMask(props.totalOnHeader.total, 0) : '...'} ${
							props.totalOnHeader.recordLabel ?? 'registros'
						}`}
						fontStyle={'italic'}
						color={ThemeProject.gray300}
					/>
				)}
				{props.showReloadButton && (
					<ButtonCP
						marginLeft={10}
						type={'ghost'}
						icon={'reload'}
						size={props.size ?? 'small'}
						onClick={props.onClick}
						loading={props.loading}
					/>
				)}
			</FlexCP>
		</FlexCP>
	)
}
