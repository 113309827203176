import { IReduxState } from 'app/redux/interfaces/IReduxState'
import { ReduxUtils } from 'submodules/nerit-framework-ui/common/redux/ReduxUtils'
import { SchemaResponseDTO } from 'submodules/nerit-aquatec-sdk/services/sys-admin/dtos/response/SchemaResponseDTO'
import { AuthResponseDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/responses/AuthResponseDTO'

export const REDUCER_LOGGED_USER = 'REDUCER_LOGGED_USER'
export const REDUCER_UPDATE_DOMAIN = 'update_domain'

/**
 * Determina mapa de reducers para gestao de estado da aplicacao via redux.
 */
export const Reducers: IReduxState = {

    auth: ReduxUtils.getPayloadReplacingReducer<AuthResponseDTO>(REDUCER_LOGGED_USER, null),
    domainData: ReduxUtils.getPayloadReplacingReducer<SchemaResponseDTO>(REDUCER_UPDATE_DOMAIN, null),
}
