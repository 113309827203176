import React from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'

interface IAddFieldClassificationModalCPProps {
    onSave: (ney: string, value: any) => void
}

/**
 * Adicionar usuario em uma clinica.
 */
export function AddLocationCP(props: IAddFieldClassificationModalCPProps): JSX.Element {

    return (
        <>
            <InputCP
                label={'Nome do ponto'}
                onChange={(gp) => props.onSave('name', gp)}
            />
            <InputCP
                label={'Latitude'}
                onChange={(gp) => props.onSave('latitude', gp)}
            />
            <InputCP
                label={'Longitude'}
                onChange={(gp) => props.onSave('longitude', gp)}
            />
            <InputCP
                label={'Altitude'}
                onChange={(gp) => props.onSave('altitude', gp)}
            />
        </>
    )
}