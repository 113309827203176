import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { MapPinTP } from 'modules/map/components/map-pin/inner/MapPinTP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import markerRed from 'modules/map/components/map-pin/inner/marker-red.png'
import markerYellow from 'modules/map/components/map-pin/inner/marker-yellow.png'
import markerGreen from 'modules/map/components/map-pin/inner/marker-green.png'

type WrapperScpPropsTP = Pick<MapPinTP, 'color'> & { cursorPointer: boolean }

/**
 * COMPONENTE
 * Marcador do mapa.
 *
 * NOTE: Coordenadas devem ser props do componente para cumprir especificacao da biblioteca de mapas sem causar erro de tipagem.
 */
export function MapPinCP(props: MapPinTP): JSX.Element {

    let imgSrc
    switch (props.markerImg) {
        case 'red':
            imgSrc = markerRed
            break

        case 'green':
            imgSrc = markerGreen
            break

        case 'yellow':
        default:
            imgSrc = markerYellow
            break
    }

    const pinCP = (
        <WrapperSCP
            color={props.color}
            cursorPointer={!!props.popOverContent || !!props.onClick}
            onClick={props.onClick}
        >
            <PinTextSCP>
                {props.text}
            </PinTextSCP>

            <PinImgSCP>
                <img src={imgSrc}/>
            </PinImgSCP>
        </WrapperSCP>
    )

    return (
        !props.popOverContent
            ? pinCP
            : <PopOverCP trigger={'hover'} title={props.popOverTitle} content={props.popOverContent}>
                {pinCP}
            </PopOverCP>
    )
}

const WrapperSCP = styled.div<WrapperScpPropsTP>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'default')};
`

const PinImgSCP = styled.div`
  img {
    height: 40px;
  }
`

const PinTextSCP = styled.span`
  margin-bottom: 5px;
  padding: 0 2px;
  font-weight: bold;
  font-size: 11px;
  background: white;
  border: solid 1px #000;
  border-radius: 2px;
  white-space: nowrap;
`
