import React from 'react'
import styled from 'styled-components'

interface ICPProps {
	children: JSX.Element
}

/**
 */
export function WrapperTableBulkActionsCP(props: ICPProps): JSX.Element {
	return <BulkActionBtnsContainerSCP>{props.children}</BulkActionBtnsContainerSCP>
}

const BulkActionBtnsContainerSCP = styled.div`
	display: flex;
	margin-left: 20px;
	margin-top: 20px;
`
