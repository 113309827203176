import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { NameListResponseDTO } from 'submodules/nerit-aquatec-sdk/common/dtos/responses/NameListResponseDTO'
import { RegulationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/RegulationSaveRequestDTO'
import { RegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/RegulationResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class RegulationRequests {

    public static CONTROLLER_ROOT ='regulation'
    public static LIST_NAMES_EP ='get-name-list'
	public static SEARCH_EP = 'search'
    public static SAVE_EP ='save'
	public static UPDATE_EP = 'update/:code'
	public static DELETE_EP = 'delete/:code'

    static getNameList = (): RequestConfigTP<ISearchResult<NameListResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${RegulationRequests.CONTROLLER_ROOT}/${RegulationRequests.LIST_NAMES_EP}`,
        method: HttpMethodEnum.GET,
    })

	static search = (): RequestConfigTP<ListResponseDTO<RegulationResponseDTO>> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${RegulationRequests.CONTROLLER_ROOT}/${RegulationRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
	})

    static save = (dto: RegulationSaveRequestDTO): RequestConfigTP<void> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${RegulationRequests.CONTROLLER_ROOT}/${RegulationRequests.SAVE_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

	static update = (code: number, dto: RegulationSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${RegulationRequests.CONTROLLER_ROOT}/${RegulationRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${RegulationRequests.CONTROLLER_ROOT}/${RegulationRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})
}
