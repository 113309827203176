import React from 'react'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'
import { LocationCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/LocationCorrelationChartResponseDTO'
import styled from 'styled-components'

interface ICPProps {
    data: LocationCorrelationChartResponseDTO
}

/**
 */
export function ChartLocationCorrelationCP(props: ICPProps): JSX.Element {

    const chartFormattedData: [number, number][] = []
    props.data.serie.dataArray.forEach((item) => {
        chartFormattedData.push([+item.x, +item.y])
    })

    return (
        <CardHighChartCP
            chartType={'scatter'}
            buttonsToHide={['chartType']}
            chartOptions={{
                chart: HighChartsUtils.getChart('scatter'),
                series: [
                    {
                        name: 'Correlação',
                        data: chartFormattedData,
                    } as any,
                    {
                        name: 'Linha de Tendência',
                        type: 'line',
                        data: props.data.linearRegression.trendLine.map((trendLine) => [+trendLine.x, +trendLine.y]),
                    } as any,
                ],
                xAxis: {
                    title: {
                        text: props.data.serie.xAxisLabel,
                    }
                },
                yAxis: {
                    title: {
                        text: props.data.serie.yAxisLabel,
                    }
                }
            }}
            footer={
                <SubtitleWrapper>
                    <p>{props.data.linearRegression.linearRegressionFormula}</p>
                    <p>{`R² = ${props.data.linearRegression.rSquare}`}</p>
                </SubtitleWrapper>
            }
        />
    )
}

const SubtitleWrapper = styled.div`
  p {
    text-align: center;
    font-size: 10px;
    color: #646464;
  }
`
