import React from 'react'
import styled from 'styled-components'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICPProps {
    show?: boolean
}

/**
 * COMPONENTE: Logo.
 */
export function LoadingOverlayLogoMovimentCP(props: ICPProps): JSX.Element {

    return (
        <LoadingOverlayCP
            show={props.show ?? false}
            customLogo={
                <WrapperSCP>
                    <LogoCP
                        imgUrl={ThemeProject.logoLoginUrl}
                        width={50}
                    />
                    <LoadingIconCP size={60} color={ThemeProject.primary}/>
                </WrapperSCP>
            }
        />
	)
}

const WrapperSCP = styled.div`
  img {
    position: absolute;
    margin: 6px;
  }
`
