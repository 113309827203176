import React from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

interface ICPProps extends BasicStyleWrapperCPProps {
	children: React.ReactNode
	loading?: boolean
}

/**
 * Wrappper para uma linha de widgets.
 */
export function WidgetsRowCP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<WidgetsContentSCP noFlexOnSmall={screenSize.xs}>
				<LoadingOverlayCP show={props.loading ?? false} />

				{props.children}
			</WidgetsContentSCP>
		</BasicStyleWrapperCP>
	)
}

const WidgetsContentSCP = styled.div<{ noFlexOnSmall?: boolean }>`
	position: relative;
	display: ${(props) => (props.noFlexOnSmall ? undefined : 'flex')};
	justify-content: center;
	align-items: flex-start;

	.card-dashboard-widget {
		flex: 1;
		max-width: ${(props) => (props.noFlexOnSmall ? 'initial' : undefined)};
		margin-bottom: ${(props) => (props.noFlexOnSmall ? '20px' : undefined)};
	}
`
