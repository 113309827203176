import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/RegulationResponseDTO'
import { RegulationFormModel } from 'modules/fields/components/modals/modal-regulation/inner/RegulationFormModel'
import { RegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/RegulationRequests'
import { RegulationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/RegulationSaveRequestDTO'

interface ICPProps extends BasicModalCPProps {
    regulation?: RegulationResponseDTO
}

/**
 */
export function ModalRegulationCP(props: ICPProps): JSX.Element {

    const [formModel, setFormModel] = useState<RegulationFormModel>(new RegulationFormModel())
    const formStateManager = useFormStateManager(formModel)

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new RegulationFormModel())
        if (!props.visible || !props.regulation)
            return

        setFormModel(new RegulationFormModel({
            name: props.regulation.name,
            regClass: props.regulation.class,
            description: props.regulation.description,
        }))
    }

    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: RegulationSaveRequestDTO = {
            name: formValues.name,
            regClass: formValues.regClass,
            description: formValues.description,
        }

        if (!!props.regulation)
            saveRequest.runRequest(RegulationRequests.update(props.regulation.code, dto))
        else
            saveRequest.runRequest(RegulationRequests.save(dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onOk={save}
            onCancel={props.onCancel}
            title={'Nova Norma'}
            width={500}
        >
            <InputCP
                label={'Nome'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'name'}
            />

            <InputCP
                label={'Classe'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'regClass'}
            />

            <InputCP
                label={'Descrição'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'description'}
            />
        </ModalCP>
    )
}
