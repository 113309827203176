import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { NatureEnum } from 'submodules/nerit-aquatec-sdk/common/enums/NatureEnum'
import { FieldRegulationSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/FieldRegulationSearchRequestDTO'

export class FieldRegulationFiltersFormModel extends FormModel {

    @IsOptional()
    fieldRegulationCode?: number

    @IsOptional()
    fieldCode?: number

    @IsOptional()
    nature?: NatureEnum

    constructor(initialData?: Partial<FieldRegulationFiltersFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

    /**
     */
    static formatDto(formStateManager: IFormStateManager<FieldRegulationFiltersFormModel>): FieldRegulationSearchRequestDTO {

        if (!formStateManager.getFormValues())
            return {}

        const formValues = formStateManager.getFormValues()!
        return {
            fieldCode: formValues.fieldCode,
            regulationCode: formValues.fieldRegulationCode,
            nature: formValues.nature
        }
    }
}
