import React from 'react'
import styled from 'styled-components'

interface ICPProps {
	children?: JSX.Element
}

/**
 */
export function WrapperSwitchFilterCP(props: ICPProps): JSX.Element {
	return <SwitchWrapperSCP>{props.children}</SwitchWrapperSCP>
}

const SwitchWrapperSCP = styled.div`
	margin-top: 10px;
	margin-left: 5px;
	.ant-typography {
		line-height: 20px;
	}
`
