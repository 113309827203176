import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { RegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/RegulationResponseDTO'
import { ModalRegulationCP } from 'modules/fields/components/modals/modal-regulation/ModalRegulationCP'

interface ICPProps {
    regulation?: RegulationResponseDTO
    buttonSize?: ButtonSizeTP
    onSave: () => void
}

export function ButtonModalRegulationCP(props: ICPProps): JSX.Element {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    return (
        <>
            <ButtonCP
                style={{marginLeft: 10}}
                onClick={() => setIsModalVisible(true)}
                icon={props.regulation ? 'edit' : 'plus'}
                size={props.buttonSize}
            >
                { props.regulation ? '' : 'Nova Norma' }
            </ButtonCP>

            <ModalRegulationCP
                regulation={props.regulation}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onSave={() => {
                    setIsModalVisible(false)
                    props.onSave()
                }}
            />
        </>
    )
}
