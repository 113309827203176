import React from 'react'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FieldRegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/FieldRegulationRequests'

interface ICPProps {
    value?: number
    isRequired?: boolean
    isMany?: boolean
    onChange?: (code: number) => void
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    maxTagCount?: number
    returnFullOption?: boolean
}

export function SelectFieldCP(props: ICPProps): JSX.Element {

    return (
        <AutocompletePickerCP
            label={'Parâmetro'}
            requestConfigGetter={FieldRegulationRequests.getFieldList}
            showOptionsOnLoad={true}
            required={!!props.isRequired}
            isMultiple={!!props.isMany}
            value={props.value}
            onChange={props.onChange}
            filterOption={true}
            returnFullOption={props.returnFullOption}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            maxTagCount={props.maxTagCount}
        />
    )
}
