import { TablePaginationTP } from 'submodules/nerit-framework-ui/common/components/table/types/TablePaginationTP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 * Utilitarios gerais do componente de tabela
 */
export const TableUtils = {
	getDefaultPagination(pageSize?: number): TablePaginationTP {
		return {
			current: 1,
			pageSize: pageSize ?? 50,
			total: 0,
			showTotal: (total, range) => `Total: ${MaskUtils.applyNumberMask(total, 0)} (mostrando de ${range.join(' a ')})`,
		}
	},

	removePaginationAttributes(filterDto: any): any {
		delete filterDto.itemsPerPage
		delete filterDto.page

		return filterDto
	},

	getReloadNumber(): number {
		return new Date().getTime()
	},

	/**
	 */
	containsTxt(stringMain: string, stringSearch: string): boolean {
		return StringUtils.removeAccents(stringMain).toLowerCase().includes(StringUtils.removeAccents(stringSearch.toLowerCase()))
	},
}
