import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { FilterHistoricalChartCP } from 'modules/reports/components/filters/filter-historical-chart/FilterHistoricalChartCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { HistoricalChartFiltersFormModel } from 'modules/reports/components/filters/filter-historical-chart/inner/HistoricalChartFiltersFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { HistoricChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/HistoricChartRequestDTO'
import { ChartHistoricalSeriesCP } from 'modules/reports/components/charts/chart-historical-series/ChartHistoricalSeriesCP'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { TableGenericChartCP } from 'modules/reports/components/tables/table-generic-chart/TableGenericChartCP'

/**
 */
export function ScreenContentChartHistorical(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<HistoricalChartFiltersFormModel>(new HistoricalChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<GenericChartResponseDTO>()

    const [historicChartRequestDTO, setHistoricChartRequestDTO] = useState<HistoricChartRequestDTO>()
    const getHistoricalChartRequest = useRequest<GenericChartResponseDTO>()
    useEffect(onGetHistoricChartChange, [getHistoricalChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: HistoricChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new HistoricalChartFiltersFormModel({
                fieldCodes: queryFilters?.fieldCodes,
                locationCode: queryFilters?.locationCode,
                dateRange: queryFilters?.dateRange,
                fieldRegulationCode: queryFilters?.regulationCode,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        setLoadedData(undefined)
        const formValues = formStateManager.getFormValues()!

        const filters: HistoricChartRequestDTO = {
            fieldCodes: formValues.fieldCodes,
            regulationCode: formValues.fieldRegulationCode,
            dateRange: formValues.dateRange,
            locationCode: formValues.locationCode,
        }
        getHistoricalChartRequest.runRequest(ChartsRequests.getHistoricChart(filters))
        setHistoricChartRequestDTO(filters)
    }

    /**
     */
    function onGetHistoricChartChange(): void {

        if (!RequestUtils.isValidRequestReturn(getHistoricalChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getHistoricalChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Série Histórica'}/>
            }
            content={
                <>
                    <FilterHistoricalChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getHistoricalChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData || !historicChartRequestDTO}>
                        <>
                            <ChartHistoricalSeriesCP data={loadedData!}/>
                            <TableGenericChartCP
                                data={loadedData!}
                                export={{
                                    exportRequestConfig: () => ChartsRequests.getHistoricChartExport(historicChartRequestDTO!),
                                    fileName: 'serie_historica',
                                }}
                            />
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
