import { ChartTypeTP } from 'modules/reports/components/chart-actions/ChartActionsCP'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'

/**
 */
export class ApexChartUtils {

    static getChart(chartType: ChartTypeTP): ApexChart {
        return {
            width: '97%',
            height: 350,
            type: chartType as any,
            zoom: {
                enabled: true,
                type: 'xy'
            },
            toolbar: {
                show: true,
                tools: {
                    selection: false,
                    pan: false,
                    reset: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                }
            }
        }
    }

    /**
     */
    static getMinMaxAnnotation(minPermitted?: number, maxPermitted?: number): ApexAnnotations {

        const result: YAxisAnnotations[] = []

        if (!!minPermitted) {
            result.push({
                y: minPermitted,
                borderColor: '#FF4560',
                label: {
                    borderColor: '#FF4560',
                    style: {color: '#fff', background: '#FF4560'},
                    text: `Min: ${minPermitted}`
                }
            })
        }

        if (!!maxPermitted) {
            result.push({
                y: maxPermitted,
                borderColor: '#FF4560',
                label: {
                    borderColor: '#FF4560',
                    style: {color: '#fff', background: '#FF4560'},
                    text: `Máx: ${maxPermitted}`
                }
            })
        }

        return { yaxis: result }
    }

    static getGrid(): ApexGrid {
        return {
            xaxis: {
                lines: {show: true}
            },
            yaxis: {
                lines: {show: true,},
            },
        }
    }

    static getYMaxOrMin(data: GenericChartResponseDTO, type: 'max' | 'min'): number {

        let result = 0
        if (!!data.regulationLimit)
            result = type === 'max' ? data.regulationLimit.maxPermitted : data.regulationLimit?.minPermitted

        data.series.forEach((serie) => {
            serie.dataArray.forEach((data) => {
                if (type === 'max' && +data.y > result)
                    result = +data.y
                else if (type === 'min' && +data.y < result)
                    result = +data.y
            })
        })

        return +result
    }

    static isAboveOrBelowLimit(data: GenericChartResponseDTO, value: number): boolean {

        if (!data.regulationLimit)
            return false

        if (value > data.regulationLimit.maxPermitted)
            return true

        if (value < data.regulationLimit.minPermitted)
            return true

        return false
    }
}
