import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import { SelectNatureCP } from 'common/components/form/SelectNatureCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'

interface ICPProps {
    locationCode?: number
    dateRange?: IDateRangeFilter
    onChangeDateRange: (dr: IDateRangeFilter) => void
    onChangeLocation: (code: number) => void
    onChangeNature: (ntr: string) => void
    onChange: (locCode?: number, dateInterval?: IDateRangeFilter, nature?: string) => void
    shouldShowDate?: boolean
    shouldShowNature?: boolean
}

export function FilterFieldClassificationCP(props: ICPProps): JSX.Element {

    const [locationCode, setLocationCode] = useState<number | undefined>()
    const [nature, setNature] = useState<string | undefined>()
    const [dateRange, setDateRange] = useState<IDateRangeFilter | undefined>()

    useEffect(() => props.onChange(
        locationCode,
        dateRange,
        nature
    ), [locationCode, dateRange, nature])

    return (
        <ContentSCP>
            <RowCP>
                <ColumnCP size={8}>
                    <SelectLocationCP
                        onChange={(code) => setLocationCode(code)}
                    />
                </ColumnCP>

                {
                    props.shouldShowNature &&
                    <ColumnCP size={8}>
                        <SelectNatureCP
                            onChange={(ntr) => setNature(ntr)}
                        />
                    </ColumnCP>
                }

                { props.shouldShowDate &&
                    <ColumnCP size={8}>
                        <DateRangePickerCP
                            showBorder={true}
                            marginTop={23}
                            fastFilter={'all'}
                            fieldName={'dateRange'}
                            onChange={(dr) => setDateRange(dr)}
                        />
                    </ColumnCP>
                }
            </RowCP>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
    width: 100%;
    background: #fff;
    padding: 1rem 2rem;
`
