import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TransientValueTrimmerConfigTP } from 'submodules/nerit-framework-ui/common/transient-value-trimmer/types/TransientValueTrimmerConfigTP'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'

type SetSearchStringTP = (searchString: string) => void

/**
 * UTILITARIOS auxiliares para componente generico de automcomplete.
 */
export const AutocompletePickerUtils = {
	getSelectOptions(options: NameAndCodeResponseDTO[]): SelectFullOptionTP[] {
		return options.map((option, i) => ({
			key: `autocomplete-${i}-${StringUtils.getSlugStyleString(option.name)}`,
			value: option.code,
			label: option.name,
		}))
	},

	getUniqueOptionsList(items: SelectFullOptionTP[]): SelectFullOptionTP[] {
		const uniqueItemsMap = new Map<number | string, SelectFullOptionTP>()
		items.forEach((item) => uniqueItemsMap.set(item.value, item))
		const uniqueList: SelectFullOptionTP[] = []
		uniqueItemsMap.forEach((mappedOption) => uniqueList.push(mappedOption))
		return uniqueList
	},

	getSearchStrTrimmerConfig(setSearchString: SetSearchStringTP): TransientValueTrimmerConfigTP<string> {
		return {
			delay: 350,
			onFinish: setSearchString,
			debugType: 'disabled',
			debugName: 'auto-complete-picker',
		}
	},
}
