import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'

export class MatrixCorrelationChartFiltersFormModel extends FormModel {

    @IsRequired()
    locationCodes: number[]

    @IsRequired()
    fieldCodes: number[]

    @IsOptional()
    dateRange?: IDateRangeFilter

    constructor(initialData?: Partial<MatrixCorrelationChartFiltersFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
