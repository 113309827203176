import React from 'react'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'

interface ICPProps {
    data: GenericChartResponseDTO
}

/**
 */
export function ChartGeographicCP(props: ICPProps): JSX.Element {

    const chartFormattedData: [number, number][] = []
    props.data.series.forEach((serie, index) => {
        serie.dataArray.forEach((item) => {
            chartFormattedData.push([index, +item.y])
        })
    })

    const series: any = [{
        name: 'valores',
        data: chartFormattedData,
    }]

    if (!!props.data.regulationLimit?.minPermitted) {
        series.push(HighChartsUtils.getMinOrMaxPermittedSerie('min', props.data.series.length, props.data.regulationLimit?.minPermitted))
    }

    if (!!props.data.regulationLimit?.maxPermitted) {
        series.push(HighChartsUtils.getMinOrMaxPermittedSerie('max', props.data.series.length, props.data.regulationLimit?.maxPermitted),)
    }

    return (
        <CardHighChartCP
            chartType={'scatter'}
            buttonsToHide={['chartType']}
            chartOptions={{
                chart: HighChartsUtils.getChart('scatter'),
                series: series,
                xAxis: {
                    categories: props.data.series.map((serie) => serie.xAxisLabel)
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                exporting: {
                    enabled: true,
                }
            }}
        />
    )
}
