import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { DataAnalysisSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/request/DataAnalysisSearchRequestDTO'
import { DataAnalysisListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/DataAnalysisListItemResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DataAnalysisSamplingDataResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/DataAnalysisSamplingDataResponseDTO'

export class DataAnalysisRequests {

    public static CONTROLLER_ROOT = 'data-analysis'
    public static SEARCH_EP = 'search'
    public static SEARCH_EXPORT_EP = 'search-export'
    public static GET_SAMPLING_DATA_EP = 'get-sampling-data/:samplingCode'

    static search = (dto: DataAnalysisSearchRequestDTO): RequestConfigTP<ListResponseDTO<DataAnalysisListItemResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${DataAnalysisRequests.CONTROLLER_ROOT}/${DataAnalysisRequests.SEARCH_EP}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

	static searchExport = (dto: DataAnalysisSearchRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${DataAnalysisRequests.CONTROLLER_ROOT}/${DataAnalysisRequests.SEARCH_EXPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getSamplingData = (samplingCode: number): RequestConfigTP<DataAnalysisSamplingDataResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${DataAnalysisRequests.CONTROLLER_ROOT}/${DataAnalysisRequests.GET_SAMPLING_DATA_EP.replace(':samplingCode', samplingCode.toString())}`,
        method: HttpMethodEnum.GET,
    })

}
