import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface IFontAwsomeIconCPProps {
	type: IconProp
	color?: string
	size?: SizeProp
	spin?: boolean
	onClick?: () => void
	style?: CSSProperties
	tooltip?: string
	loading?: boolean
}

/**
 * Componente WRAPPER de Icone do FontAwsome
 */
export function FontAwsomeIconCP(props: IFontAwsomeIconCPProps): JSX.Element {
	return (
		<Tooltip title={props.tooltip}>
			<IconWrapperSCP onClick={props.onClick} style={props.style}>
				{props.loading ? (
					<FontAwesomeIcon icon={faSpinner} color={props.color} spin={true} size={props.size ? props.size : '1x'} />
				) : (
					<FontAwesomeIcon icon={props.type} color={props.color} size={props.size} spin={props.spin} />
				)}
			</IconWrapperSCP>
		</Tooltip>
	)
}

const IconWrapperSCP = styled.span`
	cursor: ${(props) => (!!props.onClick ? 'pointer' : undefined)};
`
