import { AxiosResponse } from 'axios'
import { RequestStateTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestStateTP'
import { UseRequestActionTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/UseRequestActionTP'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { UseRequestIdTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/UseRequestIdTP'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { OrUndefTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefTP'

/**
 * UTILITARIOS para hook de gestao de requisicoes.
 */
export class UseRequestUtils {
	private static readonly _DEFAULT_STATE: RequestStateTP<any> = {
		isCancelled: false,
		isAwaiting: false,
		isSuccess: false,
		wasTried: true,

		responseData: undefined,
		responseType: undefined,
		responseStatus: undefined,
		error: undefined,
	}

	static readonly INITIAL_STATE: RequestStateTP<any> = {
		// eslint-disable-line @typescript-eslint/member-ordering
		...UseRequestUtils._DEFAULT_STATE,
		wasTried: false,
	}

	static getInitialId(): UseRequestIdTP {
		return {
			id: RequestUtils.getNewRequestId(),
			cancellationCount: 0,
		}
	}

	/**
	 * Retorna ID 'dinamico' da requisicao:
	 *
	 * - Toda requisicao gerida pelo hook possui 01 'id inicial' que correponde ao 1o ID obtido para indetifica-la;
	 * - Cada possivel cancelamento da mesma invalida seu identificador atual tornando necessario que se gere 01 novo;
	 * - Cada cancelamento incrementa o contador de cancelamentos;
	 * - O id dinamico corresponde a 01 identificador que se atualiza para ser sempre o 'mesmo' (para o hook) & 01 novo (para a helper),
	 * ao mesmo tempo;
	 *
	 * @see RequestHelper
	 */
	static getIdString(identification: UseRequestIdTP, debugId?: string): string {
		const id = debugId ?? identification.id
		return !!identification.cancellationCount ? `${id}.${identification.cancellationCount + 1}` : id
	}

	static requestStateReducer(state: RequestStateTP<any>, action: UseRequestActionTP<any>): RequestStateTP<any> {
		switch (action.type) {
			case 'start':
				return {
					...UseRequestUtils._DEFAULT_STATE,
					isAwaiting: true,
				}
			case 'finish':
				return {
					...UseRequestUtils._DEFAULT_STATE,
					...action.payload,
					isAwaiting: false,
				}
			default:
				return UseRequestUtils._DEFAULT_STATE
		}
	}

	static requestIdReducer(currentState: UseRequestIdTP): UseRequestIdTP {
		return {
			...currentState,
			cancellationCount: currentState.cancellationCount + 1,
		}
	}

	static validateResponse(response?: AxiosResponse): void {
		if (!response?.status || response?.status < 200 || response?.status >= 400) throw response
	}

	static getResponseContentType(responseHeaders: {}): OrUndefTP<MimeTypeEnum> {
		const responseTypeString: OrUndefTP<string> = responseHeaders['content-type'] ?? responseHeaders['content-segmentType']
		if (!responseTypeString) return undefined

		for (const mimeType of Object.values(MimeTypeEnum)) {
			if (responseTypeString.includes(mimeType)) return mimeType
		}

		return undefined
	}

	/* eslint-disable no-console */

	static debugStart(identification: UseRequestIdTP, debugId?: string): void {
		const id = this.getIdString(identification, debugId)
		console.time(this._getTimingLabel(id))
		console.log(this._getDebugMsgLabel('START', id))
	}

	static debugEnd(identification: UseRequestIdTP, debugId?: string): void {
		const id = this.getIdString(identification, debugId)
		console.timeEnd(this._getTimingLabel(id))
	}

	/** Exibe contagem atualizada de chamadas para 01 mesma funcao. */
	static debugComputeCalling(functionName: string, identification: UseRequestIdTP, debugId?: string): void {
		console.count(this._getDebugMsgLabel(functionName, identification, debugId))
	}

	/* eslint-enable no-console */

	private static _getTimingLabel(id: string): string {
		return this._getDebugMsgLabel('END', id)
	}

	private static _getDebugMsgLabel(placementText: string, idString: string): string
	private static _getDebugMsgLabel(placementText: string, identification: UseRequestIdTP, debugId?: string): string

	private static _getDebugMsgLabel(placementText: string, param2: UseRequestIdTP | string, debugId?: string): string {
		const id = typeof param2 === 'string' ? param2 : this.getIdString(param2, debugId)
		return `${useRequest.name}-${id}: ${placementText}`
	}
}
