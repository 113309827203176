import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { FilterWaterContaminationChartCP } from 'modules/reports/components/filters/filter-water-contamination-chart/FilterWaterContaminationChartCP'
import { WaterContaminationChartFiltersFormModel } from 'modules/reports/components/filters/filter-water-contamination-chart/inner/WaterContaminationChartFiltersFormModel'
import { WaterContaminationFilterUtils } from 'modules/reports/components/filters/filter-water-contamination-chart/inner/WaterContaminationFilterUtils'
import { ToxicityIndexChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/ToxicityIndexChartRequestDTO'
import { TableWaterContaminationChartCP } from 'modules/reports/components/tables/table-water-contamination-chart/TableWaterContaminationChartCP'
import { ToxicityChartListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/ToxicityChartListItemResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'

/**
 */
export function ScreenContentChartWaterContamination(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<WaterContaminationChartFiltersFormModel>(new WaterContaminationChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<ToxicityChartListItemResponseDTO[]>()

    const [toxicityIndexChartRequestDTO, setToxicityIndexChartRequestDTO] = useState<ToxicityIndexChartRequestDTO>()
    const getChartRequest = useRequest<ListResponseDTO<ToxicityChartListItemResponseDTO>>()
    useEffect(onGetChartRequestChange, [getChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: ToxicityIndexChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new WaterContaminationChartFiltersFormModel({
                fieldCodes: queryFilters?.fieldCodes ?? WaterContaminationFilterUtils.DEFAULT_FIELD_CODES,
                locationCodes: queryFilters?.locationCodes,
                regulationCode: queryFilters?.regulationCode,
                dateRange: queryFilters?.dateRange,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        } else {
            setFormModel(new WaterContaminationChartFiltersFormModel({
                fieldCodes: WaterContaminationFilterUtils.DEFAULT_FIELD_CODES,
            }))
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const filters: ToxicityIndexChartRequestDTO = {
            fieldCodes: formValues.fieldCodes,
            locationCodes: formValues.locationCodes,
            regulationCode: formValues.regulationCode,
            dateRange: formValues.dateRange,
        }
        getChartRequest.runRequest(ChartsRequests.getToxicityChart(filters))
        setLoadedData(undefined)
        setToxicityIndexChartRequestDTO(filters)
    }

    /**
     */
    function onGetChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getChartRequest.responseData!.list)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Contaminação da água'}/>
            }
            content={
                <>
                    <FilterWaterContaminationChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!toxicityIndexChartRequestDTO &&
                                <TableWaterContaminationChartCP
                                    data={loadedData}
                                    filters={toxicityIndexChartRequestDTO}
                                />
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
