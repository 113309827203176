const isDark = localStorage.getItem('theme') === 'dark'

export const ThemeFrameworkCommon = {
	browserDefaultColorLight: isDark ? '#181818' : '#ffffff',
	browserDefaultColorDark: isDark ? '#ffffff' : '#181818',
	browserDefaultBackgroundLight: isDark ? '#181818' : '#ffffff',
	browserDefaultBackgroundDark: isDark ? '#ffffff' : '#181818',

	white: '#ffffff',
	black: '#181818',

	fontSizes: {
		extraSmall: '.6rem',
		small: '.7rem',
		normal: '.85rem',
		large: '1rem',
		extraLarge: '1.3rem',
		title: '2.5rem',
	},

	spaces: ['.2rem', '.5rem', '.8rem', '1rem', '1.2rem', '1.5rem', '2rem', '2.4rem', '2.7rem', '3rem', '3.4rem', '3.5rem', '4rem'],
}
