import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import { SelectNatureCP } from 'common/components/form/SelectNatureCP'
import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FieldRegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/FieldRegulationRequests'
import { FieldRegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/FieldRegulationResponseDTO'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FieldRegulationFormModel } from 'modules/fields/components/modals/modal-field-regulation/inner/FieldRegulationFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { FieldRegulationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/FieldRegulationSaveRequestDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps extends BasicModalCPProps {
    fieldRegulation?: FieldRegulationResponseDTO
}

/**
 */
export function ModalFieldRegulationCP(props: ICPProps): JSX.Element {

    const [formModel, setFormModel] = useState<FieldRegulationFormModel>(new FieldRegulationFormModel())
    const formStateManager = useFormStateManager(formModel)

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new FieldRegulationFormModel())
        if (!props.visible || !props.fieldRegulation)
            return

        setFormModel(new FieldRegulationFormModel({
            fieldCode: props.fieldRegulation.field.code,
            regulationCode: props.fieldRegulation.regulation.code,
            maxPermitted: props.fieldRegulation.maxPermitted,
            minPermitted: props.fieldRegulation.minPermitted,
            nature: props.fieldRegulation.nature
        }))
    }

    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: FieldRegulationSaveRequestDTO = {
            regulationCode: formValues.regulationCode,
            fieldCode: formValues.fieldCode,
            nature: formValues.nature,
            minPermitted: formValues.minPermitted,
            maxPermitted: formValues.maxPermitted
        }

        if (!!props.fieldRegulation)
            saveRequest.runRequest(FieldRegulationRequests.update(props.fieldRegulation.code, dto))
        else
            saveRequest.runRequest(FieldRegulationRequests.save(dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onOk={save}
            onCancel={props.onCancel}
            title={'Nova Norma'}
            width={500}
        >
            <SelectFieldCP
                isMany={false}
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'fieldCode'}
            />

            <SelectFieldRegulationCP
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'regulationCode'}
            />

            <SelectNatureCP
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'nature'}
            />

            <RowCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Mínimo Permitido'}
                        formStateManager={formStateManager}
                        fieldName={'minPermitted'}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Máximo Permitido'}
                        formStateManager={formStateManager}
                        fieldName={'maxPermitted'}
                    />
                </ColumnCP>
            </RowCP>
        </ModalCP>
    )
}
