import React, { useEffect, useState } from 'react'
import { Popover } from 'antd'
import { TooltipPlacementTP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import styled from 'styled-components'

interface IPopOverCPProps {
	children: React.ReactNode
	content?: JSX.Element | JSX.Element[] | false
	title?: string
	visible?: boolean
	trigger?: 'hover' | 'click' | 'focus'
	placement?: TooltipPlacementTP
	onClose?: () => void
	destroyOnHide?: boolean
}

/**
 * Exibe modal posicionado rente ao conteudo envolto pelo componente.
 */
export function PopOverCP(props: IPopOverCPProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false)

	useEffect(onVisiblePropChange, [props.visible])

	function onVisiblePropChange(): void {
		if (props.visible !== undefined) setIsVisible(props.visible)
	}

	function onVisibilityStateChange(_visible: boolean): void {
		setIsVisible(_visible)
		if (!_visible && !!props.onClose) props.onClose()
	}

	if (props.trigger === 'hover') {
		return (
			<Popover content={props.content} title={props.title} placement={props.placement ?? 'top'}>
				<div>{props.children}</div>
			</Popover>
		)
	}

	return (
		<Popover
			content={props.content}
			title={<TitleSCP>{props.title}</TitleSCP>}
			visible={isVisible}
			trigger={props.trigger}
			placement={props.placement ?? 'top'}
			onVisibleChange={onVisibilityStateChange}
			destroyTooltipOnHide={props.destroyOnHide}
		>
			{props.children}
		</Popover>
	)
}

const TitleSCP = styled.div`
	font-weight: 700;
	padding: 0.6rem 0;
`
