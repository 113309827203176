import { useMediaQuery } from 'react-responsive'
import { BreakpointsTP } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/BreakpointsTP'
import { UseScreenSizeUtils } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSizeUtils'

/**
 * HOOK (Responsividade)
 * Expoe flags que representam faixas de tamanho de tela indicando se a largura atual do viewport
 * corresponde a cada uma destas faixas.
 *
 * NOTE: Utiiza mesmo padrao de nomenclatura tradicional do framework Twitter Bootstrap.
 *
 * @see BreakpointsTP
 */
export function useScreenSize(): Record<BreakpointsTP | 'smd', boolean> {
	return {
		xs: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('xs')), // Extra Small
		sm: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('sm')), // Small
		md: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('md')), // Medium
		lg: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('lg')), // Large
		xl: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('xl')), // Extra large

		smd: useMediaQuery(UseScreenSizeUtils.getSmdBounds()),
	}
}
