import { validate, ValidationError } from 'class-validator'
import { IFormModelValidator } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormModelValidator'

/**
 * VALIDADOR
 * Classe base a ser extendida para criar validadores de campos de para formulario.
 */
export abstract class FormModel implements IFormModelValidator {
	// @TODO - Rever porque os filhos precisam colocar o object.assing, se ja esta no pai
	protected constructor(initialData?: any) {
		if (!!initialData) Object.assign(this, initialData)
	}

	async validate(): Promise<ValidationError[]> {
		return validate(this)
	}
}
