import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IScpProps {
	isSecondaryMenu?: boolean
}

interface ICPProps extends IScpProps {
	isActive?: boolean
}

/**
 * Barra no menu indicando qual eh o menu atual.
 */
export function BarActiveMenuItemCP(props: ICPProps): JSX.Element {
	if (!props.isActive) return <></>

	return <ActiveItemBarSCP isSecondaryMenu={props.isSecondaryMenu} />
}

const ActiveItemBarSCP = styled.div<IScpProps>`
	background-color: ${(props) =>
		props.isSecondaryMenu ? ThemeFrameworkCommon.browserDefaultBackgroundDark : ThemeFrameworkCommon.browserDefaultBackgroundLight};
	width: 3px;
	position: absolute;
	right: 0;
	height: 100%;
	top: 0;
`
