import Menu, { MenuMode } from 'antd/lib/menu'
import React from 'react'
import styled from 'styled-components'

type _ScpPropsTP = {
	width?: number
}

type _AntPropsTP = {
	defaultOpenKeys?: string[]
	selectable?: boolean
	inlineCollapsed?: boolean
	onSelectionChange?: (openKeys: string[]) => void
	mode?: MenuMode
}

interface IMenuCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> {}

/**
 * COMPONENTE: Menu.
 */
export function MenuCP(props: IMenuCPProps): JSX.Element {
	return (
		<WrapperSCP width={props.width}>
			<Menu
				onOpenChange={props.onSelectionChange}
				defaultOpenKeys={props.defaultOpenKeys}
				inlineCollapsed={props.inlineCollapsed}
				selectable={props.selectable}
				mode={props.mode ?? 'inline'}
			>
				{props.children}
			</Menu>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<_ScpPropsTP>`
	.ant-menu {
		width: ${(props) => (props.width ? `${props.width.toString()}px` : 'unset')};
		background: none;
		border-right: 0;
	}
`
