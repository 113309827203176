export enum NatureEnum {
    SURFACE_WATER='SURFACE_WATER',
    UNDERGROUND_WATER='UNDERGROUND_WATER',
    POTABLE_WATER='POTABLE_WATER',
    EFFLUENT='EFFLUENT',
    PROCESS='PROCESS',
    GROUND='GROUND',
    ATMOSPHERE='ATMOSPHERE',
    SEDIMENTS='SEDIMENTS',
    SOLID_WASTE='SOLID_WASTE',
	DRINKING_WATER='DRINKING_WATER'
}

export enum NatureLabelEnum {
    SURFACE_WATER = 'Água Superficial',
    UNDERGROUND_WATER = 'Água Subterrânea',
    POTABLE_WATER = 'Água Potável',
    EFFLUENT = 'Efluente',
    PROCESS = 'Processo',
    GROUND = 'Solo',
    ATMOSPHERE = 'Atmosfera',
    SEDIMENTS = 'Sedimentos',
    SOLID_WASTE = 'Resíduos Sólidos',
	DRINKING_WATER = 'Água potável 2'
}
