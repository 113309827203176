import React, { useEffect, useState } from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import * as _ from 'lodash'
import { FieldCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/FieldCorrelationChartResponseDTO'
import { IGenericChartSeriesAndData } from 'modules/reports/components/tables/table-generic-chart/inner/IGenericChartSeriesAndData'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { FieldCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/FieldCorrelationChartRequestDTO'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'

interface ICPProps {
    data: FieldCorrelationChartResponseDTO
    filters: FieldCorrelationChartRequestDTO
}

/**
 */
export function TableFieldCorrelationChartCP(props: ICPProps): JSX.Element {

    const [data, setData] = useState<IGenericChartSeriesAndData[]>([])
    useEffect(init, [props.data])


    /**
     */
    function init(): void {

        const formattedData: IGenericChartSeriesAndData[] = []
        props.data.serie.dataArray.forEach((serie) => {
            formattedData.push({
                date: serie.date,
                x: serie.x,
                y: serie.y,
                z: serie.z
            })
        })
        setData(_.sortBy(formattedData, 'date'))
    }

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getFieldCorrelationChartExport(props.filters)}
                    fileName={'correlacao_de_parametros'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<IGenericChartSeriesAndData>
                wrappedOnCard={true}
                data={data}
                columns={[
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.transformDateStrFormat(DateFormatEnum.US_WITHOUT_TIME, DateFormatEnum.BR_WITHOUT_TIME, record.date)
                    },
                    {
                        title: props.data.serie.xAxisLabel,
                        render: (text, record) => record.x
                    },
                    {
                        title: props.data.serie.yAxisLabel,
                        render: (text, record) => record.y
                    },
                    {
                        title: props.data.serie.zAxisLabel,
                        hide: !props.data.serie.zAxisLabel,
                        render: (text, record) => record.z,
                    },
                ]}
            />
        </>
    )
}
