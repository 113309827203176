import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class MapFiltersFormModel extends FormModel {

    @IsRequired()
    samplingCode?: number

    @IsRequired()
    fieldCode?: number

    @IsOptional()
    dateRange?: IDateRangeFilter

    @IsOptional()
    fieldRegulationCode?: number

    @IsOptional()
    tolerance?: number

    showTitle?: boolean
    fieldName?: number

    constructor(initialData?: Partial<MapFiltersFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
