import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { CountDashboardRequestDTO } from 'submodules/nerit-aquatec-sdk/services/dashboard/dtos/requests/CountDashboardRequestDTO'
import { WidgetOnlyValuesResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/dashboard/dtos/response/WidgetOnlyValuesResponseDTO'

export class DashboardRequests {

    public static CONTROLLER_ROOT = 'dashboard'
    public static GET_COUNTS = 'counts'

    static getCounts = (dto: CountDashboardRequestDTO): RequestConfigTP<WidgetOnlyValuesResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.GET_COUNTS}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

}
