import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { FilterFieldClassificationCP } from 'modules/fields/components/field-classification/filter-field-classification/FilterFieldClassificationCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { ModalSamplingManagementCP } from 'modules/fields/components/field-management/modal/ModalSamplingManagementCP'
import { LocationPlaningTableCP } from 'modules/fields/components/field-classification/field-classification-table/LocationPlaningTableCP'

export function ScreenSamplingManagement(): JSX.Element {

    const [isNewFieldClassificationVisible, setIsNewFieldClassificationVisible] = useState<boolean>(false)
    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())
    const [locationCode, setLocationCode] = useState<number>()
    const [nature, setNature] = useState<string>()
    const [dateRange, setDateRange] = useState<IDateRangeFilter>()

    function setFilters(locCode?: number, dateInterval?: IDateRangeFilter, ntr?: string): void {
        setLocationCode(locCode)
        setDateRange(dateInterval)
        setNature(ntr)
        setShouldLoadData(TableUtils.getReloadNumber())
    }

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Planejamento de amostragem'}>
                    <ButtonCP
                        onClick={() => setIsNewFieldClassificationVisible(true)}
                    >
                        Agendar Tarefa
                    </ButtonCP>
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <FilterFieldClassificationCP
                        onChangeDateRange={(dr: IDateRangeFilter) => setDateRange(dr)}
                        onChangeLocation={(code: number) => setLocationCode(code)}
                        onChangeNature={(ntr: string) => setNature(ntr)}
                        onChange={(locCode?: number, dateInterval?: IDateRangeFilter, ntr?: string) => setFilters(locCode, dateInterval, ntr)}
                        shouldShowNature={true}
                        shouldShowDate={true}
                    />

                    <LocationPlaningTableCP
                        filters={{
                            locationCode,
                            dateRange,
                            nature
                        }}
                        shouldLoadData={shouldLoadData}
                    />

                    <ModalSamplingManagementCP
                        visible={isNewFieldClassificationVisible}
                        onCancel={() => setIsNewFieldClassificationVisible(false)}
                        onSave={() => {
                            setIsNewFieldClassificationVisible(false)
                            setShouldLoadData(TableUtils.getReloadNumber())
                        }}
                    />
                </>
            }
        />
    )
}
