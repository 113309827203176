import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { SearchMarkersRequestDTO } from 'submodules/nerit-aquatec-sdk/services/map/dtos/requests/SearchMarkersRequestDTO'
import { MarkerResponseDTO } from 'submodules/nerit-aquatec-sdk/services/map/dtos/responses/MarkerResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class MapRequests {

    public static CONTROLLER_ROOT = 'map'
    public static GET_MARKERS = 'get-markers'

    static getMarkers = (dto: SearchMarkersRequestDTO): RequestConfigTP<ISearchResult<MarkerResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${MapRequests.CONTROLLER_ROOT}/${MapRequests.GET_MARKERS}`,
        method: HttpMethodEnum.GET,
        params: dto
    })
}
