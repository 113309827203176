import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ModalLocationCP } from 'modules/locations/components/modal-location/ModalLocationCP'
import { ModalSamplingCP } from 'modules/samplings/components/modal-sampling/ModalSamplingCP'
import { useLocation } from 'react-router'
import { ScreenContentDevTestScreenLinks } from 'app/screens/screen-dev/content/ScreenContentDevTestScreenLinks'
import { LoadingOverlayLogoMovimentCP } from 'app/components/loading-overlay-logo-moviment/LoadingOverlayLogoMovimentCP'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function ScreenDevTest(): JSX.Element {

    const routeLocation = useLocation()
    const params = new URLSearchParams(routeLocation.search)

    function doNothing(): void {
        NotificationHelper.info('Do Nothing')
    }

    if (!!params.get('screenLinks'))
        return <ScreenContentDevTestScreenLinks/>

    return (
        <>
            <ModalLocationCP visible={false} onCancel={doNothing} onSave={doNothing}/>
            <ModalSamplingCP visible={false} onCancel={doNothing} onSave={doNothing}/>

            <LoadingOverlayLogoMovimentCP/>
        </>
    )
}
