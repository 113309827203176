import { RequestHeaderTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestHeaderTP'

export const AuthUtils = {
	/**
	 * Retorna config para determinar 01 cabecalho de autenticacao do tipo 'bearer'
	 * numa requisicao gerida com mecanismo de gestao de requisicoes http da aplicacao.
	 */
	getBearerAuthHeaderConfig(token: string): RequestHeaderTP {
		return {
			headerName: 'Authorization',
			headerValue: `Bearer ${token}`,
		}
	},

	/**
	 * Retorna config para determinar 01 cabecalho definindo o parametro 'schema'
	 * numa requisicao gerida com mecanismo de gestao de requisicoes http da aplicacao.
	 */
	getSchemaHeaderConfig(schema: string): RequestHeaderTP {
		return {
			headerName: 'schema',
			headerValue: schema,
		}
	},

	getOnBehalfHeaderConfig(onBehalf: string): RequestHeaderTP {
		return {
			headerName: 'on-behalf-of',
			headerValue: onBehalf,
		}
	},
}
