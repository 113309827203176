import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { WrapperFiltersCP } from 'submodules/nerit-framework-ui/features/common/components/filters/wrapper-filters/WrapperFiltersCP'
import { FieldRegulationFiltersFormModel } from 'modules/fields/components/filters/filters-field-regulation/inner/FieldRegulationFiltersFormModel'
import { SelectNatureCP } from 'common/components/form/SelectNatureCP'

interface ICPProps {
    formStateManager: IFormStateManager<FieldRegulationFiltersFormModel>
    onFilter: () => void
    isLoading?: boolean
}

/**
 */
export function FiltersFieldRegulationCP(props: ICPProps): JSX.Element {

    return (
        <WrapperFiltersCP
            filters={
                <RowCP>
                    <ColumnCP size={12}>
                        <SelectFieldRegulationCP
                            formStateManager={props.formStateManager}
                            fieldName={'fieldRegulationCode'}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <SelectFieldCP
                            formStateManager={props.formStateManager}
                            fieldName={'fieldCode'}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <SelectNatureCP
                            formStateManager={props.formStateManager}
                            fieldName={'nature'}
                        />
                    </ColumnCP>
                </RowCP>
            }
            filterButton={
                <ButtonCP
                    icon={'search'}
                    type={'primary'}
                    onClick={props.onFilter}
                    loading={props.isLoading}
                />
            }
        />
    )
}
