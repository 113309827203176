import { ThemeProject } from 'config/theme/project/ThemeProject'
import * as React from 'react'
import styled from 'styled-components'

/**
 * Logo no cabeçalho do menu.
 */
export function LogoMenuCP(): JSX.Element {
	return (
		<MenuLogoSCP>
			<img height={40} src={ThemeProject.logoMenuUrl} alt={'Logo'} />
		</MenuLogoSCP>
	)
}

const MenuLogoSCP = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
`
