import React from 'react'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import { ScreenHome } from 'app/screens/screen-home/ScreenHome'
import { ScreenFieldClassification } from 'modules/fields/screens/screen-field-classification/ScreenFieldClassification'
import { ScreenSamplingManagement } from 'modules/samplings/screens/screen-samplings-management/ScreenSamplingManagement'
import { ScreenFieldControlCP } from 'modules/home/screens/screen-home-field-control/ScreenFieldControlCP'
import { ScreenDataAnalisys } from 'modules/reports/screens/screen-data-analysis/ScreenDataAnalisys'
import { ScreenUsers } from 'modules/users/screens/screen-users/ScreenUsers'
import { ScreenLocations } from 'modules/locations/screens/screen-locations/ScreenLocations'
import { ScreenSamplings } from 'modules/samplings/screens/screen-samplings/ScreenSamplings'
import { ScreenFieldRegulation } from 'modules/fields/screens/screen-fields-regulation/ScreenFieldRegulation'
import { ScreenMap } from 'modules/map/screens/screen-map/ScreenMap'
import { ScreenDevTest } from 'app/screens/screen-dev/ScreenDevTest'
import { ScreenCharts } from 'modules/reports/screens/screen-charts/ScreenCharts'
import { ScreenRegulation } from 'modules/fields/screens/screen-regulation/ScreenRegulation'

PrivateRouter.ROOT = 'private'

PrivateRouter.HOME = `${PrivateRouter.ROOT}/home`
PrivateRouter.MAP = `${PrivateRouter.ROOT}/map`

PrivateRouter.DATA_ANALISYS = `${PrivateRouter.ROOT}/data-analisys`

PrivateRouter.REPORT = `${PrivateRouter.ROOT}/report`
PrivateRouter.RADA_REPORT = `${PrivateRouter.ROOT}/rada_report`

PrivateRouter.PROFILE = `${PrivateRouter.ROOT}/profile`
PrivateRouter.FIELD_VALUES = `${PrivateRouter.ROOT}/field-values`
PrivateRouter.FIELD_ANALISYS = `${PrivateRouter.ROOT}/field-analisys`
PrivateRouter.FIELD_CLASSIFICATION = `${PrivateRouter.FIELD_ANALISYS}/field-classification`
PrivateRouter.SAMPLING_MANAGEMENT = `${PrivateRouter.FIELD_ANALISYS}/field-management`
PrivateRouter.EXPORT = `${PrivateRouter.ROOT}/export`

PrivateRouter.USERS = `${PrivateRouter.ROOT}/users`
PrivateRouter.LOCATIONS = `${PrivateRouter.ROOT}/locations`
PrivateRouter.SAMPLINGS = `${PrivateRouter.ROOT}/samplings`
PrivateRouter.FIELD_CONFIG = `${PrivateRouter.ROOT}/field-config`
PrivateRouter.REGULATIONS = `${PrivateRouter.ROOT}/regulations`

PrivateRouter.CHARTS = `${PrivateRouter.ROOT}/charts`
PrivateRouter.CHART_HISTORIC_SERIES = `${PrivateRouter.CHARTS}/historic-series`
PrivateRouter.CHART_STATISTIC = `${PrivateRouter.CHARTS}/statistic`
PrivateRouter.CORRELATION_FIELDS = `${PrivateRouter.CHARTS}/correlation-fields`
PrivateRouter.CORRELATION_LOCATIONS = `${PrivateRouter.CHARTS}/correlation-locations`
PrivateRouter.CORRELATION_MATRIX = `${PrivateRouter.CHARTS}/correlation-matrix`
PrivateRouter.GEOGRAPHIC = `${PrivateRouter.CHARTS}/geographic`
PrivateRouter.WATER_CONTAMINATION = `${PrivateRouter.CHARTS}/water-contamination`
PrivateRouter.WATER_QUALITY = `${PrivateRouter.CHARTS}/water-quality`

PrivateRouter.DEV_TEST = `${PrivateRouter.ROOT}/dev-test`

/**
 * Define rotas que so podem ser acessadas apos realizar login.
 */
export function PrivateRouter(): JSX.Element {

    return (
        <RouterSwitchCP
            routes={[
                { path: PrivateRouter.HOME, component: ScreenHome },
                { path: PrivateRouter.USERS, component: ScreenUsers },
                { path: PrivateRouter.LOCATIONS, component: ScreenLocations },
                { path: PrivateRouter.SAMPLINGS, component: ScreenSamplings },
                { path: PrivateRouter.FIELD_CONFIG, component: ScreenFieldRegulation },
                { path: PrivateRouter.REGULATIONS, component: ScreenRegulation },

                { path: PrivateRouter.FIELD_ANALISYS, component: ScreenFieldControlCP, exact: true },
                { path: PrivateRouter.FIELD_CLASSIFICATION, component: ScreenFieldClassification },
                { path: PrivateRouter.SAMPLING_MANAGEMENT, component: ScreenSamplingManagement },
                { path: PrivateRouter.DATA_ANALISYS, component: ScreenDataAnalisys },

                { path: PrivateRouter.EXPORT, component: (): JSX.Element => { return <>Exportar dados</> } },

                { path: PrivateRouter.CHARTS, component: ScreenCharts },

                { path: PrivateRouter.MAP, component: ScreenMap },
                { path: PrivateRouter.REPORT, component: (): JSX.Element => { return <>Relatório</> } },
                { path: PrivateRouter.RADA_REPORT, component: (): JSX.Element => { return <>Relatório Rada</> } },
                { path: PrivateRouter.PROFILE, component: (): JSX.Element => { return <>Perfil</> } },

                { path: `${PrivateRouter.DEV_TEST}`, component: ScreenDevTest, },
                { component: () => <ScreenError404 redirectTo={PrivateRouter.HOME}/> },
            ]}
        />
    )
}
