import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class RegulationFormModel extends FormModel {

    @IsRequired()
    name: string

    @IsRequired()
    regClass: string

    @IsRequired()
    description: string

    constructor(initialData?: Partial<RegulationFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
