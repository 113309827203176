import React from 'react'
import styled from 'styled-components'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { FieldRegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/FieldRegulationRequests'
import { GetFieldClassificationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/GetFieldClassificationRequestDTO'

interface ICPProps {
    filters: GetFieldClassificationRequestDTO
    shouldLoadData: number
}

export function FieldClassificationTableCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TableFromApiCP<any>
                apiConfig={{
                    hasPagination: true,
                    pageSize: 20,
                    requestConfigTP: () => FieldRegulationRequests.getFieldClassification(props.filters)
                }}
                appearance={{
                    showReloadButton: true,
                    wrappedOnCard: true,
                    showTotalOnHeader: true
                }}
                shouldLoadData={props.shouldLoadData}
                columns={[
                    {
                        title: '#',
                        render: (text, record) => record.code
                    },
                    {
                        title: 'Ponto',
                        render: (text, record) => record.locationName
                    },
                    {
                        title: 'Data da classificação',
                        render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Classificação',
                        render: (text, record) => {
                            if (!!record.answers.seccondQuestion || !!record.answers.thirdQuestion)
                                return 'Resíduo perigoso classe I'
                            else if (!!record.answers.fourthQuestion)
                                return 'Resíduo não-Inerte classe II A'
                            else if (!record.answers.fourthQuestion)
                                return 'Resíduo inerte classe II B'
                        }
                    },
                    {
                        title: 'Conclusão',
                        render: (text, record) => record.conclusion
                    },
                    {
                        title: 'Ações'
                    }
                ]}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
