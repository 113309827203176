import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { UserLoginRequestDTO } from 'submodules/nerit-aquatec-sdk/services/auth/dtos/requests/UserLoginRequestDTO'
import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'

export class AuthRequests {

	public static CONTROLLER_ROOT = 'auth'
	public static LOGIN_EP = 'login'

	static login = (dto: UserLoginRequestDTO): RequestConfigTP => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.LOGIN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

}
