import React from 'react'
import styled from 'styled-components'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { THEME_LOCAL_STORAGE_KEY, ThemeTP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/inner/ThemeTP'

export function ButtonSwitchThemeCP(): JSX.Element {
	const currentTheme: ThemeTP = (localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as ThemeTP) ?? 'light'

	/**
	 */
	function handleColors(): void {
		const theme: ThemeTP = currentTheme === 'dark' ? 'light' : 'dark'
		localStorage.setItem(THEME_LOCAL_STORAGE_KEY, theme)
		window.location.reload()
	}

	return (
		<TooltipCP text={currentTheme === 'dark' ? 'Alterar para o tema claro' : 'Alterar para o tema escuro'} placement={'right'}>
			<ButtonSwitchThemeSCP onClick={handleColors}>
				<FontAwsomeIconCP type={currentTheme === 'dark' ? faSun : faMoon} />
			</ButtonSwitchThemeSCP>
		</TooltipCP>
	)
}

const ButtonSwitchThemeSCP = styled.div`
	color: ${ThemeProject.menuItemColor};
	cursor: pointer;
	display: flex;
	font-size: 14px;
	justify-content: center;
	margin-bottom: 15px;
`
