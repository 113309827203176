import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IScpProps {
	marginTop?: number
	marginRight?: number
	marginBottom?: number
	marginLeft?: number
	maxWidth?: string
	flex?: string
	onClick?: () => void
}

interface ICPProps extends IScpProps {
	title: string
	children?: JSX.Element | JSX.Element[]
	className?: string
	footer?: JSX.Element | string
	help?: string
	loading?: boolean
}

export function CardWidgetWrapperCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP
			className={props.className}
			marginTop={props.marginTop ?? 0}
			marginRight={props.marginRight ?? 10}
			marginBottom={props.marginBottom ?? 0}
			marginLeft={props.marginLeft ?? 10}
			maxWidth={props.maxWidth}
			flex={props.flex}
			onClick={props.onClick}
		>
			<FlexCP
				className={'card-dashboard-widget-title'}
				alignItems={'center'}
				margin={{ bottom: 20 }}
				justifyContent={!!props.help ? 'space-between' : undefined}
			>
				<TextCP text={props.title} />
				{props.help && <HelpCP showOnlyIcon={true} marginTop={0} marginBottom={0} text={props.help} type={'tooltip'} />}
			</FlexCP>

			{props.loading ? <LoadingCP show={true} /> : props.children}

			{!!props.footer && <FooterSCP className={'card-dashboard-widget-footer'}>{props.footer}</FooterSCP>}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<IScpProps>`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	border-radius: 5px;
	margin: ${(props) => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
	padding: 10px;
	max-width: ${(props) => props.maxWidth};
	flex: ${(props) => props.flex};

	cursor: ${(props) => (!!props.onClick ? 'pointer' : 'initial')};
`

const FooterSCP = styled.div`
	background: ${ThemeProject.secondary};
	margin: 20px -10px -10px -10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
`
