import * as React from 'react'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'

/**
 * UTILITARIOS do menu principal do sistema
 */
export const UserMenuUtils = {

    getMenuOptionsTop(): MenuItemTP[] {
        return [
            {
                route: PrivateRouter.HOME,
                title: 'Página Inicial',
                icon: <IconICP iconName={'home'} color={ThemeProject.menuItemColor}/>,
                enabled: true
            },
            {
                route: PrivateRouter.DATA_ANALISYS,
                title: 'Análise de dados',
                icon: <IconICP iconName={'database'} color={ThemeProject.menuItemColor}/>,
                enabled: true,
            },
            {
                route: PrivateRouter.EXPORT,
                title: 'Planilha de Exportação',
                icon: <IconICP iconName={'export'} color={ThemeProject.menuItemColor}/>,
                enabled: false
            },
            {
                route: PrivateRouter.CHARTS,
                title: 'Gráficos',
                icon: <IconICP iconName={'area-chart'} color={ThemeProject.menuItemColor}/>,
                enabled: true,
            },
            {
                route: PrivateRouter.MAP,
                title: 'Mapa',
                icon: <IconICP iconName={'pushpin'} color={ThemeProject.menuItemColor}/>,
                enabled: true
            },
            {
                route: PrivateRouter.REPORT,
                title: 'Relatório',
                icon: <IconICP iconName={'file-text'} color={ThemeProject.menuItemColor}/>,
                enabled: false
            },
            {
                route: PrivateRouter.RADA_REPORT,
                title: 'Relatório Rada',
                icon: <IconICP iconName={'file-text'} color={ThemeProject.menuItemColor}/>,
                enabled: false
            }
        ]
    },

    getMenuOptionsBottom(): MenuItemTP[] {

        return [
            {
                route: PrivateRouter.USERS,
                title: 'Administrativo',
                icon: <IconICP iconName={'setting'} color={ThemeProject.menuItemColor}/>,
                enabled: true,
                menuItemsChildren: [
                    {
                        title: 'Usuários',
                        route: PrivateRouter.USERS,
                        renderType: 'text',
                        enabled: true,
                    },
                    {
                        title: 'Pontos',
                        route: PrivateRouter.LOCATIONS,
                        renderType: 'text',
                        enabled: true,
                    },
                    {
                        title: 'Campanhas',
                        route: PrivateRouter.SAMPLINGS,
                        renderType: 'text',
                        enabled: true,
                    },
                    {
                        title: 'Biblioteca de Parâmetros',
                        route: PrivateRouter.FIELD_CONFIG,
                        renderType: 'text',
                        enabled: true,
                    },
                    {
                        title: 'Normas',
                        route: PrivateRouter.REGULATIONS,
                        renderType: 'text',
                        enabled: true,
                    },
                ]
            },
            {
                route: PrivateRouter.DEV_TEST,
                title: 'Dev',
                icon: <IconICP iconName={'code'} color={ThemeProject.menuItemColor}/>,
                enabled: NeritFrameworkProjectConfig.isDev(),
                menuItemsChildren: [
                    {
                        title: 'Links para Telas',
                        route: `${PrivateRouter.DEV_TEST}?screenLinks=true`,
                        renderType: 'text',
                    },
                ]
            }
        ]
    },

}
