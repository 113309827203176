/**
 * ENUM: Representacao numerica (0 a 11) de meses do ano.
 * NOTE: Preservar valores nessa forma para compatibilidade com moment.js
 */
export enum MonthEnum {
	JAN,
	FEB,
	MAR,
	APR,
	MAY,
	JUN,
	JUL,
	AUG,
	SEP,
	OCT,
	NOV,
	DEC,
}
