import React, { useState } from 'react'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { css, styled } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

const ICON_COLOR = ThemeAnt.layoutBodyBackground
const ICON_SIZE = 8

interface IGoogleErrorNotificationICPProps {
    show: boolean
}

/**
 * COMPONENTE
 * Barra inferior para notificar falha de carregamento da api do google maps.
 *
 * @see MapCP
 */
export function GoogleErrorNotificationICP(props: IGoogleErrorNotificationICPProps): JSX.Element {

    const [shouldCollapse, setShouldCollapse] = useState<boolean>(false)

    return (
        <WrapperSCP show={props.show} shouldCollapse={shouldCollapse}>
            <ButtonCP
                onClick={() => setShouldCollapse(!shouldCollapse)}
                size={'small'}
                type={'link'}
                style={{
                    border: '0 none',
                    padding: 0,
                    marginRight: 4,
                }}
            >
                {
                    shouldCollapse
                        ? <TextCP text={'!'} size={'small'} strong={true}/>
                        : <IconICP iconName={'close'} size={ICON_SIZE} color={ICON_COLOR}/>
                }
            </ButtonCP>

            {
                !shouldCollapse &&
                <TextCP
                    size={'small'}
                    text={'Falha ao tentar carregar utilitários do Google Maps'}
                    color={ThemeAnt.layoutBodyBackground}
                />
            }
        </WrapperSCP>
    )
}

const COLOR_MAIN = ThemeAnt.secondaryDisabledColor

const WrapperSCP = styled.div<IGoogleErrorNotificationICPProps & { shouldCollapse: boolean }>`
    
    background: linear-gradient(45deg, ${COLOR_MAIN}, ${COLOR_MAIN}, transparent);
    position: absolute;
    padding: 1px 7px;
    transition: 1s opacity, .5s left, .5s height, .3s bottom, .3s background, .3s width;
    display: flex;
    align-items: center;
    
    bottom: ${props => (props.show ? 0 : -25)}px;
    opacity: ${props => (props.show ? 1 : 0)};
    
    ${props => (props.shouldCollapse ? CollapsedCSS : ExpandedCSS)}
`

const ExpandedCSS = css`
    left: 0;
    width: 100%;
    height: unset;
`

const CollapsedCSS = css`
    
    width: 22px;
    background: ${COLOR_MAIN};
    height: 20px;
    justify-content: center;
    border-radius: 3px 3px 0 0;
    left: 15px;
    border: 1px solid #736e6e;
    color: ${ICON_COLOR};
    cursor: pointer;
    
    &:hover {
        background: ${ThemeAnt.secondaryColor};
        border-color: ${ThemeAnt.black};
        color: ${ThemeAnt.white};
    }
`
