import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SamplingRequests } from 'submodules/nerit-aquatec-sdk/services/samplings/SamplingRequests'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonModalSamplingCP } from 'modules/samplings/components/button-modal-sampling/ButtonModalSamplingCP'
import { SamplingResponseDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/responses/SamplingResponseDTO'

interface ICPProps {
    shouldLoadData: number
}

export function TableSamplingsCP(props: ICPProps): JSX.Element {

    const [reloadData, setReloadData] = useState<number>(props.shouldLoadData)
    useEffect(() => setReloadData(props.shouldLoadData), [props.shouldLoadData])

    return (
        <WrapperSCP>
            <TableFromApiCP<SamplingResponseDTO>
                apiConfig={{
                    hasPagination: true,
                    pageSize: 20,
                    requestConfigTP: () => SamplingRequests.getList()
                }}
                appearance={{
                    showReloadButton: true,
                    wrappedOnCard: true,
                    showTotalOnHeader: true
                }}
                shouldLoadData={reloadData}
                columns={[
                    {
                        title: '#',
                        render: (text, record) => record.code
                    },
                    {
                        title: 'Nome',
                        render: (text, record) => record.name
                    },
                    {
                        title: 'Data de início',
                        render: (text, record) => DateUtils.formatDate(record.beginDate, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Data de término',
                        render: (text, record) => DateUtils.formatDate(record.endDate, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        render: (text, record) => (
                            <ButtonGroupCP mode={'separeted'}>
                                <ButtonModalSamplingCP
                                    sampling={record}
                                    onSave={() => setReloadData(TableUtils.getReloadNumber())}
                                />

                                <ButtonDeleteRecordCP
                                    requestConfigTP={() => SamplingRequests.deleteSampling(record.code)}
                                    onDelete={() => setReloadData(TableUtils.getReloadNumber())}
                                />
                            </ButtonGroupCP>
                        )
                    }
                ]}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
