import React from 'react'
import { LocationRequests } from 'submodules/nerit-aquatec-sdk/services/location/LocationRequests'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
    isMany?: boolean
    locationCode?: number
    isRequired?: boolean
    onChange?: (locationCode: number) => void
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    maxTagCount?: number
}

export function SelectLocationCP(props: ICPProps): JSX.Element {

    return (
        <AutocompletePickerCP
            label={props.isMany ? 'Pontos' : 'Ponto'}
            requestConfigGetter={() => LocationRequests.getNameList()}
            showOptionsOnLoad={true}
            required={props.isRequired}
            isMultiple={props.isMany}
            value={props.locationCode}
            onChange={props.onChange}
            filterOption={true}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            maxTagCount={props.maxTagCount}
        />
    )
}
