import React from 'react'
import { animated, useSpring } from '@react-spring/web'

interface ICPProps {
    value: number
}

/**
 */
export function NumberAnimatedCP(props: ICPProps): JSX.Element {

    const animatedNumberParams = useSpring({
        from: { number: 0 },
        to: { number: props.value },
        config: { duration: 1000 },
    });

    return (
        <animated.div>
            {animatedNumberParams.number.to((n) => n.toFixed(0))}
        </animated.div>
    );
}
