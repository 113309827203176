import { ThemeProject } from 'config/theme/project/ThemeProject'
import React from 'react'
import styled from 'styled-components'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	message: string
	icon: JSX.Element
	extra?: JSX.Element
	isLoading?: boolean
}

/**
 * Exibe mensagem de 'boas vindas': Para quando NAO ha nenhum formluario a ser exibido na area de conteudo principal da tela de TEMPLATES.
 */
export function WelcomeContentCP(props: ICPProps): JSX.Element {
	return (
		<ContentCP>
			<WrapperSCP>
				{props.isLoading ? (
					<LoadingCP />
				) : (
					<>
						<IconSCP>{props.icon}</IconSCP>
						<TextSCP>{props.message}</TextSCP>
						<ExtraSCP>{props.extra}</ExtraSCP>
					</>
				)}
			</WrapperSCP>
		</ContentCP>
	)
}

const ExtraSCP = styled.div`
	margin-top: 30px;
`

const TextSCP = styled.div`
	font-size: 1.5rem;
	text-align: center;
`

const WrapperSCP = styled.div`
	color: ${ThemeProject.colorText};
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-direction: column;
`

const IconSCP = styled.div`
	margin: 0 0 30px 0;
	fill: ${ThemeFrameworkCommon.browserDefaultColorDark};
`
