import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { HistoricalChartFiltersFormModel } from 'modules/reports/components/filters/filter-historical-chart/inner/HistoricalChartFiltersFormModel'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'

interface ICPProps {
    onFilter: () => void
    formStateManager: IFormStateManager<HistoricalChartFiltersFormModel>
    loading?: boolean
}

export function FilterHistoricalChartCP(props: ICPProps): JSX.Element {

    const [reloadRegulation, setReloadRegulation] = useState<boolean>(true)
    useEffect(onChangeField, [props.formStateManager.getFieldValue('fieldCodes')])

    /**
     */
    function onChangeField(): void {

        if (props.formStateManager.getFieldValue('fieldCodes')?.length > 1) {
            setReloadRegulation(false)
            props.formStateManager.changeFieldValue('fieldRegulationCode', undefined)
            SystemUtils.sleep(100).then(() => setReloadRegulation(true))
        }

    }

    return (
        <ContentSCP>
            <FlexCP justifyContent={'space-between'} alignItems={'flex-end'}>
                <FlexOneICP marginRight={10}>
                    <RowCP>
                        <ColumnCP size={6}>
                            <SelectLocationCP
                                formStateManager={props.formStateManager}
                                fieldName={'locationCode'}
                                isRequired={true}
                            />
                        </ColumnCP>
                        <ColumnCP size={6}>
                            <SelectFieldCP
                                formStateManager={props.formStateManager}
                                fieldName={'fieldCodes'}
                                isRequired={true}
                                isMany={true}
                            />
                        </ColumnCP>
                        <ColumnCP size={6}>
                            {
                                reloadRegulation &&
                                <SelectFieldRegulationCP
                                    formStateManager={props.formStateManager}
                                    fieldName={'fieldRegulationCode'}
                                    disable={props.formStateManager.getFieldValue('fieldCodes')?.length > 1}
                                />
                            }
                        </ColumnCP>
                        <ColumnCP size={6}>
                            <DateRangePickerCP
                                showBorder={true}
                                marginTop={23}
                                fastFilter={'all'}
                                fieldName={'dateRange'}
                                onChange={(dateRange) => props.formStateManager.changeFieldValue('dateRange', dateRange)}
                                value={props.formStateManager.getFieldValue('dateRange')}
                            />
                        </ColumnCP>
                    </RowCP>
                </FlexOneICP>
                <ButtonCP
                    marginBottom={10}
                    icon={'search'}
                    type={'primary'}
                    onClick={props.onFilter}
                    loading={props.loading}
                />
            </FlexCP>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
  width: 100%;
  background: #fff;
  padding: 1rem 2rem;
`
