import { DatePicker } from 'antd'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { AddLocationCP } from 'modules/locations/components/AddLocationCP'
import { FieldRegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/FieldRegulationRequests'

interface IAddFieldClassificationModalCPProps {
    visible: boolean
    onCancel: () => void
    onSave: () => void
}

/**
 * Adicionar usuario em uma clinica.
 */
export function ModalAddFieldClassificationCP(props: IAddFieldClassificationModalCPProps): JSX.Element {

    const doLinkRequest = useRequest<void>('none')

    const [locationCode, setLocationCode] = useState<number>()
    const [collectionDate, setCollectionDate] = useState<Date>()//eslint-disable-line
    const [firstQuestion, setFirstQuestion] = useState<boolean>()//eslint-disable-line
    const [seccondQuestion, setSeccondQuestion] = useState<boolean>()//eslint-disable-line
    const [thirdQuestion, setThirdQuestion] = useState<boolean>()//eslint-disable-line
    const [fourthQuestion, setFourthQuestion] = useState<boolean>()//eslint-disable-line
    const [conclusion, setConclusion] = useState<string>()//eslint-disable-line
    const [shouldCreateLocation, setShouldCreateLocation] = useState<boolean>(false)
    const [name, setName] = useState<string>()
    const [latitude, setLatitude] = useState<string>()
    const [longitude, setLongitude] = useState<string>()
    const [altitude, setAltitude] = useState<string>()

    const postSamplingClassification = useRequest<any>()

    function init(): void {
        postSamplingClassification.runRequest(FieldRegulationRequests.postFieldClassification({
            locationCode,
            collectionDate,
            answers: {
                firstQuestion,
                seccondQuestion,
                thirdQuestion,
                fourthQuestion
            },
            conclusion,
            name,
            latitude,
            longitude,
            altitude
        }))

        setLocationCode(undefined)
        setCollectionDate(undefined)
        setFirstQuestion(undefined)
        setSeccondQuestion(undefined)
        setThirdQuestion(undefined)
        setFourthQuestion(undefined)
        setConclusion(undefined)
        setShouldCreateLocation(false)
        setName(undefined)
        setLatitude(undefined)
        setLongitude(undefined)
        setAltitude(undefined)

        props.onSave()
    }

    function toogleShouldCreateLocation(): void {
        setLocationCode(undefined)
        setShouldCreateLocation(!shouldCreateLocation)
    }

    function onAddLocationChange(key: string, value: any): void {

        switch (key) {
            case 'name':
                setName(value)
                break
            case 'altitude':
                setAltitude(value)
                break
            case 'longitude':
                setLongitude(value)
                break
            case 'latitude':
                setLatitude(value)
                break
            default:
        }
    }

    useEffect(() => console.log(collectionDate), [collectionDate])

    const questions = [
        { code: 1, question: 'O resíduo tem origem conhecida?' },
        { code: 2, question: 'Consta nos anexos A ou B?' },
        { code: 3, question: 'Tem características de: inflamabilidade, corrosividade, reatividade, toxicidade ou patogenicidade?' },
        { code: 4, question: '[Resíduo não perigoso classe II] Possui constituintes que são solubilizados em concentrações superiores ao anexo G?' },
    ]

    const conclusions = [
        'Resíduo perigoso classe I',
        'Resíduo não-Inerte classe II A',
        'Resíduo inerte classe II B',
    ]

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={doLinkRequest.isAwaiting}
            noFooter={true}
            onCancel={props.onCancel}
            title={'Nova classificação de amostra'}
            width={600}
        >

            <ButtonCP
                marginTop={25}
                icon={'act'}
                type={shouldCreateLocation ? 'dashed' : 'default'}
                disabled={false}
                onClick={() => toogleShouldCreateLocation()}
            >
                { !shouldCreateLocation ? 'Inserir novo ponto' : 'Selecionar ponto existente' }
            </ButtonCP>

            {
                shouldCreateLocation &&
                <AddLocationCP onSave={(key, value) => onAddLocationChange(key, value)}/>
            }

            {
                !shouldCreateLocation &&
                <SelectLocationCP
                    locationCode={locationCode}
                    isRequired={true}
                    onChange={(locCode) => {
                        setLocationCode(locCode)
                    }}
                />
            }

            <DatePicker
                format={DateFormatEnum.BR_WITHOUT_TIME}
                onChange={(date) => setCollectionDate(date?.toDate())}
                showToday={true}
            />

            <RadioGroupCP
                margin={{ top: 10 }}
                label={questions[0].question}
                type={'button'}
                options={[
                    { value: false, content: 'Não' },
                    { value: true, content: 'Sim' },
                ]}
                onChange={(value) => setFirstQuestion(value)}
                selected={firstQuestion}
            />

            {
                firstQuestion &&
                <RadioGroupCP
                    margin={{ top: 10 }}
                    label={questions[1].question}
                    type={'button'}
                    options={[
                        { value: false, content: 'Não' },
                        { value: true, content: 'Sim' },
                    ]}
                    onChange={(value) => setSeccondQuestion(value)}
                    selected={seccondQuestion}
                />
            }

            {
                (firstQuestion === false || seccondQuestion === false) &&
                <RadioGroupCP
                    margin={{ top: 10 }}
                    label={questions[2].question}
                    type={'button'}
                    options={[
                        { value: false, content: 'Não' },
                        { value: true, content: 'Sim' },
                    ]}
                    onChange={(value) => setThirdQuestion(value)}
                    selected={thirdQuestion}
                />
            }

            {
                thirdQuestion === false &&
                <RadioGroupCP
                    margin={{ top: 10 }}
                    label={questions[3].question}
                    type={'button'}
                    options={[
                        { value: false, content: 'Não' },
                        { value: true, content: 'Sim' },
                    ]}
                    onChange={(value) => setFourthQuestion(value)}
                    selected={fourthQuestion}
                />
            }

            {
                (seccondQuestion || thirdQuestion) &&
                <ConclusionCP className={'txt-destaque'}>
                    <p>Conclusão conforme NBR 10004:2004</p>
                    <TextCP
                        marginTop={10}
                        text={conclusions[0]}
                        key={'conclusion1'}
                    />
                </ConclusionCP>
            }

            {
                fourthQuestion === true &&
                <ConclusionCP className={'txt-destaque'}>
                    <p>Conclusão conforme NBR 10004:2004</p>
                    <TextCP
                        marginTop={10}
                        text={conclusions[1]}
                        key={'conclusion2'}
                    />
                </ConclusionCP>
            }

            {
                fourthQuestion === false &&
                <ConclusionCP className={'txt-destaque'}>
                    <p>Conclusão conforme NBR 10004:2004</p>
                    <TextCP
                        marginTop={10}
                        text={conclusions[2]}
                        key={'conclusion3'}
                    />
                </ConclusionCP>
            }

            {
                (!!seccondQuestion || !!thirdQuestion || fourthQuestion !== undefined) &&
                <TextAreaCP
                    value={conclusion}
                    onChange={(value) => setConclusion(value)}
                />
            }

            <ButtonCP
                marginTop={25}
                icon={'save'}
                type={'primary'}
                disabled={!conclusion || (!locationCode && !name) || !collectionDate}
                onClick={init}
            >
                Salvar Classificação
            </ButtonCP>

        </ModalCP>
    )
}

const ConclusionCP = styled.div`
    font-weight: bold;
    background: rgba(255,240,240,1);
    padding: 1em;
    margin: 1em 0;
    border: 1px solid red;

    p {
        font-size: 1.2em;
    }
`
