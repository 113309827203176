import React, { useEffect, useRef, useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ChartActionsCP, ChartActionsTP, ChartTypeTP } from 'modules/reports/components/chart-actions/ChartActionsCP'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require("highcharts/modules/exporting")(Highcharts);

interface ICPProps {
    chartOptions: Highcharts.Options
    chartType: ChartTypeTP
    buttonsToHide?: ChartActionsTP[]
    hideTitle?: boolean
    width?: string
    footer?: JSX.Element
}

/**
 */
export function CardHighChartCP(props: ICPProps): JSX.Element {

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

    const [chartType, setChartType] = useState<ChartTypeTP>(props.chartType)
    useEffect(onChangeChartType, [chartType])

    const [linearScale, setLinearScale] = useState<boolean>(true)
    useEffect(onChangeScale, [linearScale])

    const [fixedRatioSize, setFixedRatioSize] = useState<boolean>(false)
    useEffect(onChangeRatioSize, [fixedRatioSize])

    /**
     */
    function onChangeChartType(): void {
        chartComponentRef.current?.chart.update({
            chart: { type: chartType === 'bar' ? 'column' : chartType }
        })
    }

    /**
     */
    function onChangeRatioSize(): void {
        chartComponentRef.current?.chart.update({
            chart: {
                width: fixedRatioSize ? 854 : undefined,
                height: fixedRatioSize ? 480 : 350,
            }
        })
    }

    /**
     */
    function onChangeScale(): void {
        chartComponentRef.current?.chart.update({
            yAxis: {
                type: linearScale ? 'linear' : 'logarithmic',
            }
        })
    }

    return (
        <CardCP
            width={props.width}
            innerSpacing={'none'}
            titleRight={
                props.hideTitle
                    ? undefined
                    :
                    <ChartActionsCP
                        linearScale={{ selected: linearScale, onChange: setLinearScale }}
                        chartType={{ selected: chartType, onChange: setChartType }}
                        fixedRatioSize={{ selected: fixedRatioSize, onChange: setFixedRatioSize }}
                        buttonsToHide={props.buttonsToHide}
                    />
            }
        >
            <div style={{height: 'auto', width: 'auto', marginTop: 20 }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        credits: {
                            enabled: false,
                        },
                        title: {
                            text: '',
                        },
                        exporting: {
                            enabled: true,
                        },
                        ...props.chartOptions
                    }}
                    ref={chartComponentRef}
                />
            </div>

            {props.footer}
        </CardCP>
    )
}
