import { SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'

/**
 * UTILITARIOS auxiliares para componente de selecao.
 */
export const SelectCPUtils = {
	/** Retorna definicao de 01 opcao da lista de opcoes informada identificada pelo seu valor (se houver). */
	getOptionByValue(options: SelectOptionTP[], singleValue?: number): OrUndefinedTP<SelectOptionTP> {
		return options.find((option) => typeof option === 'object' && option.value === singleValue)
	},
}
