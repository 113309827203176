import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { GenericBooleanResponseDTO } from 'submodules/nerit-aquatec-sdk/common/dtos/responses/GenericBooleanResponseDTO'
import { UserRequestDTO } from 'submodules/nerit-aquatec-sdk/services/users/dtos/requests/UserResponseDTO'
import { UserResponseDTO } from 'submodules/nerit-aquatec-sdk/services/users/dtos/responses/UserResponseDTO'
import { ISearchResult } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchResult'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class UserRequests {

    public static CONTROLLER_ROOT = 'users'
    public static LIST_USERS = 'list-users'
    public static GET_USER = 'get-user'
    public static SAVE_USER = 'save-user'
    public static UPDATE_USER = 'update-user'
    public static REMOVE_USER = 'remove-user'
    public static RESET_PASSWORD = 'reset-password'

    static listUsers = (): RequestConfigTP<ISearchResult<UserResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.LIST_USERS}`,
        method: HttpMethodEnum.GET,
    })

    static getUser = (dto: { code: number }): RequestConfigTP<UserResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.GET_USER}?code=${dto.code}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static saveUser = (dto: UserRequestDTO): RequestConfigTP<GenericBooleanResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.SAVE_USER}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static updateUser = (dto: UserRequestDTO): RequestConfigTP<GenericBooleanResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.UPDATE_USER}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static removeUser = (dto: { code: number }): RequestConfigTP<GenericBooleanResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.REMOVE_USER}?code=${dto.code}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static resetPassword = (dto: { code: number }): RequestConfigTP<GenericBooleanResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${UserRequests.CONTROLLER_ROOT}/${UserRequests.RESET_PASSWORD}?code=${dto.code}`,
        method: HttpMethodEnum.POST,
        params: dto
    })
}
