import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AppConfig } from 'config/AppConfig'
import { AuthActions } from 'app/utils/AuthActions'
import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * Class especifica do projeto com informacoes necessarios para o projeto.
 */
export class NeritFrameworkProjectConfig {

    static APP_VERSION = '20230414.1'
    static PROJECT_NAME = 'Aquatec'

    private constructor() {}

    static isDev(): boolean {
        return AppConfig.getInstance().environment === EnvironmentEnum.DEV
    }

    static isHom(): boolean {
        return AppConfig.getInstance().environment === EnvironmentEnum.HOM
    }

    static async formatApiBaseUrl(config: RequestConfigTP): Promise<string> {

        if (!config.headers)
            config.headers = []

        let baseurl = config.url

        if (config.baseUrlType === AquatecSystemApiEnum.AQUATEC_API)
            baseurl = `${AppConfig.getInstance().apiBaseUrl}/${config.url}`

        return baseurl
    }

    static onUnauthorized(): void {
        AuthActions.onUnauthorizedRequestResponse()
    }

    static notifyApi(type: 'success' | 'error', title: string, msg: any): void {

        if (type === 'error')
            NotificationHelper.error(title, msg)
        else
            NotificationHelper.success(title, msg)
    }

}
