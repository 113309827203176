import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { FieldRegulationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/FieldRegulationSaveRequestDTO'
import { GetFieldClassificationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/GetFieldClassificationRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FieldRegulationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/responses/FieldRegulationResponseDTO'
import { FieldRegulationSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/regulation/dtos/requests/FieldRegulationSearchRequestDTO'

export class FieldRegulationRequests {

    public static CONTROLLER_ROOT = 'field-regulations'
	public static SEARCH_EP = 'search'
	public static SAVE_EP = 'save'
	public static UPDATE_EP = 'update/:code'
    public static DELETE_EP = 'delete/:code'



    public static GET_FIELD_CLASSIFICATION_EP = 'get-field-classifications'
    public static POST_FIELD_CLASSIFICATION_EP = 'post-field-classifications'
    public static GET_FIELD_LIST_EP = 'get-field-list'



	static search = (dto: FieldRegulationSearchRequestDTO): RequestConfigTP<FieldRegulationResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.SEARCH_EP}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

	static save = (dto: FieldRegulationSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.SAVE_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

	static update = (code: number, dto: FieldRegulationSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.DELETE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.DELETE,
    })













    static getFieldClassification = (dto: GetFieldClassificationRequestDTO): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.GET_FIELD_CLASSIFICATION_EP}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

    static postFieldClassification = (dto: any): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.POST_FIELD_CLASSIFICATION_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

    static getFieldList = (): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRegulationRequests.CONTROLLER_ROOT}/${FieldRegulationRequests.GET_FIELD_LIST_EP}`,
        method: HttpMethodEnum.GET,
    })

}
