export enum InputMaskTypeEnum {
	DATE = 'date',
	DATE_MM_YY = 'date-mm-yy',
	CPF = 'cpf',
	CNPJ = 'cnpj',
	PHONE = 'phone',
	CEP = 'cep',
	HOUR = 'hour',
	NUMERIC = 'numeric',
	MONEY = 'money',
	CPFCNPJ = 'cpfcnpj',
	NUMBER = 'number',
	STATE = 'state',
}
