import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

export type ChartTypeTP = 'scatter' | 'bar' | 'line' | 'column' | 'pie'
export type ChartActionsTP = 'linearScale' | 'chartType' | 'fixedRatioSize'

interface ICPProps {
    buttonsToHide?: ChartActionsTP[]
    linearScale: {
        selected: boolean,
        onChange: (selected: boolean) => void
    },
    chartType: {
        selected: ChartTypeTP,
        onChange: (selected: ChartTypeTP) => void
    },
    fixedRatioSize: {
        selected: boolean,
        onChange: (selected: boolean) => void
    },
}

/**
 */
export function ChartActionsCP(props: ICPProps): JSX.Element {

    return (
        <FlexCP>
            {
                !props.buttonsToHide?.includes('linearScale') &&
                <RadioGroupCP<boolean>
                    type={'button'}
                    margin={{left: 20}}
                    options={[
                        {content: <TextCP icon={'line'} text={'Linear'}/>, value: true,},
                        {content: <TextCP icon={'rise'} text={'Logaritimica'}/>, value: false},
                    ]}
                    selected={props.linearScale.selected}
                    onChange={props.linearScale.onChange}
                />
            }
            {
                !props.buttonsToHide?.includes('chartType') &&
                <RadioGroupCP<ChartTypeTP>
                    type={'button'}
                    margin={{left: 20}}
                    options={[
                        {content: <TextCP icon={'line-chart'} text={'Linhas'}/>, value: 'line',},
                        {content: <TextCP icon={'bar-chart'} text={'Colunas'}/>, value: 'bar',},
                        {content: <TextCP icon={'dot-chart'} text={'Pontos'}/>, value: 'scatter',},
                    ]}
                    selected={props.chartType.selected}
                    onChange={props.chartType.onChange}
                />
            }
            <RadioGroupCP<boolean>
                margin={{left: 20}}
                type={'button'}
                options={[
                    {content: <TextCP icon={'fullscreen'} text={'Full'}/>, value: false,},
                    {content: <TextCP icon={'fullscreen-exit'} text={'16:9'}/>, value: true},
                ]}
                selected={props.fixedRatioSize.selected}
                onChange={props.fixedRatioSize.onChange}
            />
        </FlexCP>
    )
}
