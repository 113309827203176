import React from 'react'
import styled from 'styled-components'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	filters: JSX.Element | JSX.Element[]
	filterButton: JSX.Element
	appearance?: {
		marginBottom?: string
	}
}

/**
 */
export function WrapperFiltersCP(props: ICPProps): JSX.Element {
	return (
		<ContentSCP marginBottom={props.appearance?.marginBottom}>
			<FlexCP alignItems={'center'}>
				<FlexOneICP>{props.filters}</FlexOneICP>

				<ButtonWrapperSCP>{props.filterButton}</ButtonWrapperSCP>
			</FlexCP>
		</ContentSCP>
	)
}

const ContentSCP = styled.div<{ marginBottom?: string }>`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
	margin-bottom: ${(props) => (!!props.marginBottom ? props.marginBottom : '40px')};
`

const ButtonWrapperSCP = styled.div`
	margin-left: 10px;
	margin-top: 25px;
`
