import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

interface IHeaderTitleICPProps {
	title: string | JSX.Element
	titleSize?: FontSizeTP
	noBackground?: boolean
	color?: string
}

/**
 * Titulo para cabecalho.
 */
export function HeaderTitleICP(props: IHeaderTitleICPProps): JSX.Element {
	if (typeof props.title !== 'string') return props.title

	return (
		<TitleCP textSize={props.titleSize} color={props.color} marginBottom={2}>
			{props.title}
		</TitleCP>
	)
}
