import { UserMainMenuCP } from 'app/components/user-main-menu/UserMainMenuCP'
import React, { useState } from 'react'
import { LayoutUserMainCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-user-main/LayoutUserMainCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'

/**
 * Componente principal que inclui a definicao de todas as telas privadas na VISAO DE USUARIO (medico / funcionario de clinica).
 */
export function ScreenPrivateUser(): JSX.Element {

    const [refreshSiderCollapsed, setRefreshSiderCollapsed] = useState<number>(0)

    return (
        <LayoutUserMainCP
            refreshMenuCollapsed={refreshSiderCollapsed}
            menu={
                <UserMainMenuCP
                    onSelectMenuItem={() => setRefreshSiderCollapsed(TableUtils.getReloadNumber())}
                />
            }
            privateRouter={<PrivateRouter/>}
        />
    )
}
