import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'

/**
 * UTILITARIOS: para state managers.
 */
export const FormStateManagerUtils = {
	/**
	 * Ao submeter formulario validar campos obrigatorios.
	 */
	async validateRequiredFields(formStateManager: IFormStateManager<any>): Promise<boolean> {
		formStateManager.setConsiderAllErrors(true)
		if (!(await formStateManager.validate())) {
			formStateManager.debugErrors()
			NotificationHelper.error('Ops!', 'Preencha todos os campos para prosseguir')
			return false
		}

		const formValues = formStateManager.getFormValues()
		if (!formValues) return false

		return true
	},

	/**
	 */
	validateRequiredDateRange(dateRange?: DateRangeSearchRequestDTO): boolean {
		if (!!dateRange && !!dateRange.beginDate && !!dateRange.endDate) return true

		NotificationHelper.error('Ops', 'Preencha o intervalo de datas')
		return false
	},
}
