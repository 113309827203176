import FileSaver from 'file-saver'
import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

interface ICPProps {
	requestConfig: () => RequestConfigTP<'blob'>
    fileName: string
}

/**
 */
export function ButtonExportFromApiCP(props: ICPProps): JSX.Element {

	const exportRequest = useRequest<Blob>('none')
	useEffect(onExportRequestChange, [exportRequest.isAwaiting])

	/**
	 */
	function onExportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(exportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		FileSaver.saveAs(exportRequest.responseData!, `aquatec_${props.fileName}_${new Date().getTime()}.xlsx`)
	}

	return (
		<ButtonCP
            onClick={() => exportRequest.runRequest(props.requestConfig())}
            icon={'file-excel'}
            loading={exportRequest.isAwaiting}
        >
			Exportar
		</ButtonCP>
	)
}
