import React from 'react'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface ICPProps {
    children: React.ReactElement
    showWelcome: boolean
}

/**
 */
export function WelcomeContentWithFilterWrapperCP(props: ICPProps): React.ReactElement {

    if (!props.showWelcome)
        return props.children

    return (
        <WelcomeContentCP
            message={'Selecione os filtros para ver o resultado'}
            icon={<IconICP size={60} iconName={'filter'}/>}
        />
    )
}
